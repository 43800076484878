import {faFileContract, faFileSignature, faUserCog} from "@fortawesome/free-solid-svg-icons";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

export const REGISTERED_USER_AUTHORITIES: readonly string[] = ['MAIN_AGENT', 'AGENT', 'COMPANY', 'PERSONAL', 'EMPLOYEE'];
export const OFFICER_AUTHORITIES: readonly string[] = ['ADMIN', 'OFFICER'];
export const OFFICER_FINANCE_ACCOUNTING_AUTHORITIES: readonly string[] = ['ADMIN', 'FINANCE_ACCOUNTING'];
export const ADMIN_AUTHORITIES: readonly string[] = ['ADMIN'];
export const USER_MANAGEMENT_AUTHORITIES: readonly string[] = ['ADMIN', 'SCG_ADMIN', 'QCG_ADMIN'];
export const DIRECTOR_REPORT_AUTHORITIES: readonly string[] = ['ADMIN', 'DIRECTOR'];
export const REQUEST_NEW_AUTHORITIES: readonly string[] = ['REQUEST_NEW'];
export const GMP_OFFICER: readonly  string[] = ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF'];
export const GMP_DIRECTOR: readonly string[] = ['DIRECTOR'];
export const PORTAL_LICENSING_AUTHORITIES: readonly string[] = [
  'ADMIN',
  'QCG_ADMIN',
  'DIRECTOR',
  'QCG_ACADEMIC_GOV',
  'QCG_OFFICER',
  'QCG_ACADEMIC',
  'QCG_CHIEF',
  'PROVINCE_ACADEMIC_GOV',
  'PROVINCE_OFFICER',
  'PROVINCE_ACADEMIC',
  'PROVINCE_CHIEF',
  'PROVINCE_DIRECTOR'
];
export const PORTAL_VOUCHER_AUTHORITIES: readonly string[] = [
  'ADMIN',
  'SCG_ADMIN',
  'DIRECTOR',
  'VOUCHER_1',
  'VOUCHER_2',
  'VOUCHER_3'
];
export const PORTAL_CERTIFICATE_AUTHORITIES: readonly string[] = [
  'ADMIN',
  'SCG_ADMIN',
  'DIRECTOR',
  'CERTIFICATE'
];
export const PORTAL_NS4_AUTHORITIES: readonly string[] = [
  'ADMIN',
  'SCG_ADMIN',
  'DIRECTOR',
  'NS4',
  'CHECKPOINT_OFFICER',
  'CHECKPOINT_CHIEF'
];
export const PORTAL_GMPHACCP_AUTHORITIES: readonly string[] = [
  'ADMIN',
  'QCG_ADMIN',
  'DIRECTOR',
  'QCG_ACADEMIC_GOV',
  'QCG_OFFICER',
  'QCG_ACADEMIC',
  'QCG_CHIEF'
];
export const PORTAL_AUDIT_AUTHORITIES: readonly string[] = [
  'ADMIN',
  'DIRECTOR',
  'AUDIT'
];
export const PORTAL_REQUEST_INSPECTION_AUTHORITIES: readonly string[] = [
  'ADMIN',
  'QCG_ADMIN',
  'DIRECTOR',
  'QCG_ACADEMIC_GOV',
  'QCG_OFFICER',
  'QCG_ACADEMIC',
  'QCG_CHIEF'
];
export const PORTAL_SEARCHING_AUTHORITIES: readonly string[] = [
  'ADMIN',
  'SCG_ADMIN',
  'QCG_ADMIN',
  'DIRECTOR',
  'ACTING_DIRECTOR_KPO',
  'SCG_BASIC_CONSIDERATION',
  'SCG_ACADEMIC',
  'SCG_CHIEF',
  'VOUCHER_1',
  'VOUCHER_2',
  'VOUCHER_3',
  'NS4',
  'CERTIFICATE',
  'AUDIT',
  'QCG_ACADEMIC_GOV',
  'QCG_OFFICER',
  'QCG_ACADEMIC',
  'QCG_CHIEF'
];
export const PORTAL_PAYMENT_AUTHORITIES: readonly string[] = [
  'ADMIN',
  'FINANCE_ACCOUNTING',
  'PROVINCE_FINANCE_ACCOUNTING'
];
export const PORTAL_USER_MANAGEMENT_AUTHORITIES: readonly string[] = ['ADMIN', 'SCG_ADMIN', 'QCG_ADMIN'];

export const PORTAL_DIRECTOR_REPORT_AUTHORITIES: readonly string[] = ['ADMIN', 'DIRECTOR', 'SCG_ADMIN', 'QCG_ADMIN'];

export const PROVINCE_OFFICER: readonly string[] = [
  'PROVINCE_ACADEMIC_GOV',
  'PROVINCE_OFFICER',
  'PROVINCE_ACADEMIC',
  'PROVINCE_CHIEF',
  'PROVINCE_DIRECTOR',
  'PROVINCE_FINANCE_ACCOUNTING'
];

export const USER_SYSTEMS: { readonly [id: string]: string } = {
  'profile': 'ข้อมูลส่วนตัว',
  'licensing': 'ขอใบอนุญาตผลิต/นำเข้า/ขาย อาหารสัตว์ควบคุมเฉพาะ',
  'voucher': 'ขอใบสำคัญการขึ้นทะเบียนอาหาร สัตว์ควบคุมเฉพาะ',
  'certificate': 'ขอหนังสือรับรองฯ Certificate of Free Sale/Health',
  'request-inspection': 'ขอหนังสือแจ้งผลการตรวจสอบ โรงงานผลิตอาหารสัตว์เพื่อการส่งออก',
  'ns4': 'ขอเอกสารแจ้งการนำเข้าอาหารสัตว์น้ำ (น.อ.1)',
  'audit': 'สอบถามให้ตรวจสอบอาหารสัตว์ น้ำว่า เข้าข่ายหรือไม่เข้าข่ายฯ ตามพรบ.',
  'gmp-haccp': 'ขอใบรับรองระบบประกันคุณภาพ GMP/HACCP'
};
export const OFFICER_SYSTEMS: { readonly [id: string]: string } = {
  'officer-profile': 'ข้อมูลส่วนตัว',
  'officer-licensing': 'ระบบออกใบอนุญาตผลิต/นำเข้า/ขาย อาหารสัตว์ควบคุมเฉพาะ',
  'officer-voucher': 'ระบบออกใบสำคัญการขึ้นทะเบียนอาหาร สัตว์ควบคุมเฉพาะ',
  'officer-certificate': 'ระบบออกหนังสือรับรองฯ CERTIFICATE OF FREE SALE/HEALTH',
  'officer-request-inspection': 'ระบบออกหนังสือแจ้งผลการตรวจสอบ โรงงานผลิตอาหารสัตว์เพื่อการส่งออก',
  'officer-user-management': 'ระบบบริหารจัดการสิทธิ์การเข้าใช้งาน',
  'officer-ns4': 'ระบบออกเอกสารแจ้งการนำเข้าอาหารสัตว์ (น.อ.2)',
  'officer-audit': 'ระบบออกหนังสือแจ้งผลการพิจารณาเข้าข่ายเป็นอาหารสัตว์ควบคุมเฉพาะ ตาม พรบ.ควบคุมคุณภาพอาหารสัตว์ พ.ศ.2558',
  'officer-gmp-haccp': 'ระบบออกใบรับรองระบบการจัดการฯ GMP/HACCP',
  'officer-payment': 'ระบบชำระค่าธรรมเนียมอิเล็กทรอนิกส์',
};
export const ICONS: { readonly [id: string]: any } = {
  'profile': faUserCog,
  'licensing': faFileContract,
  'voucher': faFileSignature,
};
export const LICENSE_TYPE: { readonly [id: string]: any } = {
  'P': 'ใบอนุญาตผลิตอาหารสัตว์ควบคุมเฉพาะ',
  'I': 'ใบอนุญาตนำเข้าอาหารสัตว์ควบคุมเฉพาะ',
  'S': 'ใบอนุญาตขายอาหารสัตว์ควบคุมเฉพาะ',
};
export const LICENSE_TYPE_ABBR = {
  'P': 'ผลิต',
  'I': 'นำเข้า',
  'S': 'ขาย',
};

export const LICENSE_TYPE_FULL_NAME = {
  'P': 'product',
  'I': 'import',
  'S': 'sell',
};

export const LICENSE_TYPE_LOCATION = {
  'P': 'ผลิต',
  'I': 'เก็บ',
  'S': 'เก็บ',
};

export const LICENSE_TYPE_WAREHOUSE = {
  'P': 'โรงงานผลิต',
  'I': 'เก็บ',
  'S': 'เก็บ',
};

export const CERTIFICATE_FORMS_DESC: { readonly [id: string]: any } = {
  'CertificateForm1': `แบบคำขอหนังสือรับรองการขายภายในประเทศ (Certificate of Free Sale) (แบบ รข.1)`,
  'CertificateForm2': `แบบคำขอแก้ไขหนังสือรับรองการขายภายในประเทศ (Certificate of Free Sale)`,
  'CertificateForm3': `แบบคำขอหนังสือรับรองสุขภาพ (Certificate of Health) (แบบ รส.1)`,
  'CertificateForm4': `แบบคำขอแก้ไขหนังสือรับรองสุขภาพ (Certificate of Health)`
};
export const LICENSING_FORMS_DESC: { readonly [id: string]: any } = {
  'LicensingForm1': `<span class="badge badge-primary mr-1">ฟอร์ม 1</span>แบบแจ้งความประสงค์การขอ/ต่ออายุใบอนุญาตผลิต นำเข้า ขาย อาหารสัตว์ควบคุมเฉพาะ`,
  'LicensingForm2': `<span class="badge badge-danger mr-1">ฟอร์ม 2</span>แบบคำขอใบอนุญาตผลิตอาหารสัตว์ควบคุมเฉพาะ<span class="text-danger">(มีการแจ้งความประสงค์การขอใบอนุญาต)</span>`,
  'LicensingForm3': `<span class="badge badge-warning mr-1">ฟอร์ม 3</span>แบบคำขอใบอนุญาตผลิตอาหารสัตว์ควบคุมเฉพาะ<span class="aqua-form-warning-color">(ไม่มีการแจ้งความประสงค์การขอใบอนุญาต)</span>`,
  'LicensingForm4': `<span class="badge badge-danger mr-1">ฟอร์ม 4</span>แบบคำขอต่ออายุใบอนุญาตผลิตอาหารสัตว์ควบคุมเฉพาะ<span class="text-danger">(มีการแจ้งความประสงค์การต่ออายุใบอนุญาต)</span>`,
  'LicensingForm5': `<span class="badge badge-warning mr-1">ฟอร์ม 5</span>แบบคำขอต่ออายุใบอนุญาตผลิตอาหารสัตว์ควบคุมเฉพาะ<span class="aqua-form-warning-color">(ไม่มีการแจ้งความประสงค์การขอใบอนุญาต)</span>`,
  'LicensingForm6': `<span class="badge badge-secondary mr-1">ฟอร์ม 6</span>แบบคำขอแก้ไขใบอนุญาตผลิตอาหารสัตว์ควบคุมเฉพาะ`,
  'LicensingForm7': `<span class="badge badge-secondary mr-1">ฟอร์ม 7</span>แบบคำขอใบแทนใบอนุญาตผลิตอาหารสัตว์ควบคุมเฉพาะ`,
  'LicensingForm8': `<span class="badge badge-danger mr-1">ฟอร์ม 8</span>แบบคำขอใบอนุญาตนำเข้าอาหารสัตว์ควบคุมเฉพาะ<span class="text-danger">(มีการแจ้งความประสงค์การขอใบอนุญาต)</span>`,
  'LicensingForm9': `<span class="badge badge-warning mr-1">ฟอร์ม 9</span>แบบคำขอใบอนุญาตนำเข้าอาหารสัตว์ควบคุมเฉพาะ<span class="aqua-form-warning-color">(ไม่มีการแจ้งความประสงค์การขอใบอนุญาต)</span>`,
  'LicensingForm10': `<span class="badge badge-danger mr-1">ฟอร์ม 10</span>แบบคำขอต่ออายุใบอนุญาตนำเข้าอาหารสัตว์ควบคุมเฉพาะ<span class="text-danger">(มีการแจ้งความประสงค์การขอใบอนุญาต)</span>`,
  'LicensingForm11': `<span class="badge badge-warning mr-1">ฟอร์ม 11</span>แบบคำขอต่ออายุใบอนุญาตนำเข้าอาหารสัตว์ควบคุมเฉพาะ<span class="aqua-form-warning-color">(ไม่มีการแจ้งความประสงค์การขอใบอนุญาต)</span>`,
  'LicensingForm12': `<span class="badge badge-secondary mr-1">ฟอร์ม 12</span>แบบคำขอแก้ไขใบอนุญาตนำเข้าอาหารสัตว์ควบคุมเฉพาะ`,
  'LicensingForm13': `<span class="badge badge-secondary mr-1">ฟอร์ม 13</span>แบบคำขอใบแทนใบอนุญาตนำเข้าอาหารสัตว์ควบคุมเฉพาะ`,
  'LicensingForm14': `<span class="badge badge-danger mr-1">ฟอร์ม 14</span>แบบคำขอใบอนุญาตขายอาหารสัตว์ควบคุมเฉพาะ<span class="text-danger">(มีการแจ้งความประสงค์การขอใบอนุญาต)</span>`,
  'LicensingForm15': `<span class="badge badge-warning mr-1">ฟอร์ม 15</span>แบบคำขอใบอนุญาตขายอาหารสัตว์ควบคุมเฉพาะ<span class="aqua-form-warning-color">(ไม่มีการแจ้งความประสงค์การขอใบอนุญาต)</span></span>`,
  'LicensingForm16': `<span class="badge badge-danger mr-1">ฟอร์ม 16</span>แบบคำขอต่ออายุใบอนุญาตขายอาหารสัตว์ควบคุมเฉพาะ<span class="text-danger">(มีการแจ้งความประสงค์การขอใบอนุญาต)</span></span>`,
  'LicensingForm17': `<span class="badge badge-warning mr-1">ฟอร์ม 17</span>แบบคำขอต่ออายุใบอนุญาตขายอาหารสัตว์ควบคุมเฉพาะ<span class="aqua-form-warning-color">(ไม่มีการแจ้งความประสงค์การขอใบอนุญาต)</span></span>`,
  'LicensingForm18': `<span class="badge badge-secondary mr-1">ฟอร์ม 18</span>แบบคำขอแก้ไขใบอนุญาตขายอาหารสัตว์ควบคุมเฉพาะ`,
  'LicensingForm19': `<span class="badge badge-secondary mr-1">ฟอร์ม 19</span>แบบคำขอใบแทนใบอนุญาตขายอาหารสัตว์ควบคุมเฉพาะ`,
};

export const GMPHACCP_FORMS_DESC: { readonly [id: string]: any } = {
  'GmpHaccpForm1': `<span class="badge badge-primary mr-1">ฟอร์ม 1</span>แบบคำขอรับรองระบบการจัดการด้านสุขลักษณะที่ดีในสถานประกอบการ (GMP)`,
  'GmpHaccpForm2': `<span class="badge badge-secondary mr-1">ฟอร์ม 2</span>แบบคำขอต่ออายุใบรับรองระบบการจัดการด้านสุขลักษณะที่ดีในสถานประกอบการ (GMP)`,
  'GmpHaccpForm3': `<span class="badge badge-secondary mr-1">ฟอร์ม 3</span>แบบคำขอแก้ไขใบรับรองระบบการจัดการด้านสุขลักษณะที่ดีในสถานประกอบการ (GMP)`,
  'GmpHaccpForm4': `<span class="badge badge-secondary mr-1">ฟอร์ม 4</span>แบบคำขอใบแทนใบรับรองระบบการจัดการด้านสุขลักษณะที่ดีในสถานประกอบการ (GMP)`,
  'GmpHaccpForm5': `<span class="badge badge-primary mr-1">ฟอร์ม 5</span>แบบคำขอรับรองระบบการจัดการด้านการวิเคราะห์อันตรายและจุดวิกฤตที่ต้องควบคุม (HACCP)`,
  'GmpHaccpForm6': `<span class="badge badge-secondary mr-1">ฟอร์ม 6</span>แบบคำขอต่ออายุใบรับรองระบบการจัดการด้านการวิเคราะห์อันตรายและจุดวิกฤตที่ต้องควบคุม (HACCP)`,
  'GmpHaccpForm7': `<span class="badge badge-secondary mr-1">ฟอร์ม 7</span>แบบคำขอแก้ไขใบรับรองระบบการจัดการด้านการวิเคราะห์อันตรายและจุดวิกฤตที่ต้องควบคุม (HACCP)`,
  'GmpHaccpForm8': `<span class="badge badge-secondary mr-1">ฟอร์ม 8</span>แบบคำขอใบแทนใบรับรองระบบการจัดการด้านการวิเคราะห์อันตรายและจุดวิกฤตที่ต้องควบคุม (HACCP)`
};

export const VOUCHER_FORMS_DESC: { readonly [id: string]: any } = {
  'VoucherForm1': `แบบคำขอขึ้นทะเบียน อาหารสัตว์ผสมสำเร็จรูปหรือหัวอาหารสัตว์`,
  'VoucherForm2': `แบบคำขอแก้ไขทะเบียน อาหารสัตว์ผสมสำเร็จรูปหรือหัวอาหารสัตว์`,
  'VoucherForm3': `แบบคำขอขึ้นทะเบียน สารผสมล่วงหน้า (Premix)`,
  'VoucherForm4': `แบบคำขอแก้ไขทะเบียน สารผสมล่วงหน้า (Premix)`,
  'VoucherForm5': `แบบคำขอขึ้นทะเบียน อาหารเสริมสำหรับสัตว์`,
  'VoucherForm6': `แบบคำขอแก้ไขทะเบียน อาหารเสริมสำหรับสัตว์`,
  'VoucherForm7': `แบบคำขอใบแทนใบสำคัญการขึ้นทะเบียนอาหารสัตว์ควบคุมเฉพาะ`,
};
export const AUDIT_FORMS_DESC: { readonly [id: string]: any } = {
  'AuditForm1': `แบบคำขอหนังสือแจ้งผลการพิจารณาเข้าข่ายเป็นอาหารสัตว์ควบคุมเฉพาะ ตาม พรบ.ควบคุมคุณภาพอาหารสัตว์ พ.ศ.2558`,
};

export const LICENSING_FORM_STATUS_DESC: { readonly [id: string]: any } = {
  'NEW': 'คำขอใหม่',
  'PRIM_CORRECTION': 'แก้ไขข้อมูลคำขอเบื้องต้น',
  'CANCELATION': 'ยกเลิกคำขอเนื่องจากไม่มีการดำเนินการตามกำหนด',
  'CANCEL_BY_APPLICANT': 'ยกเลิกคำร้อง/คำขอ',
  'PRIM_CORRECTED': 'รอตรวจสอบการแก้ไขข้อมูลคำขอเบื้องต้น',
  'WAIT_VERIFY': 'รอเอกสารฉบับจริง',
  'WAIT_APPOINT_LOCATION': 'รอนัดหมายตรวจสอบสถานที่',
  'WAIT_ACCEPT_LOCATION': 'รอตอบรับนัดหมายตรวจสอบสถานที่',
  'APPOINT_DENIED': 'ปฏิเสธนัดหมาย',
  'WAIT_INSPECT_LOCATION': 'รอตรวจสอบสถานที่',
  'LOCATION_DENIED': 'ไม่ผ่านเนื่องจากสถานที่ไม่เหมาะสม',
  'LOCATION_CORRECTION_REPORT': 'ให้ส่งรายงานแก้ไขสถานที่',
  'CORRECTION_SENT': 'รอตรวจสอบรายงานแก้ไขสถานที่',
  'WAIT_DIGI_SIG': 'รอลงลายมือชื่ออิเล็กทรอนิกส์รับทราบผลการตรวจสอบ',
  'WAIT_APPLICATION': 'รอการยื่นคำขอใบอนุญาตฯ',
  'APPLIED': 'ยื่นคำขอใบอนุญาตแล้ว',
  'WAIT_CERT': 'รอจัดทำใบอนุญาต',
  'REPORT_CORRECT': 'แก้ไขรายงานฯ',
  'WAIT_SIGN_PROPOSAL': 'รอเสนอลงนาม',
  'WAIT_ORDER': 'รอพิจารณาออกคำสั่ง',
  'DENIED': 'ไม่อนุญาต',
  'WAIT_PAYMENT': 'รอชำระค่าธรรมเนียม',
  'WAIT_VERIFY_PAYMENT': 'รอยืนยันการชำระค่าธรรมเนียม',
  'WAIT_RECEIPT': 'รอจัดทำใบเสร็จรับเงิน',
  'UPLOAD_SLIP': 'รอตรวจสอบหลักฐานการชำระค่าธรรมเนียม',
  'PAID': 'ชำระค่าธรรมเนียมแล้ว',
  'GRANT': 'อนุญาต',
}

export const LICENSING_FORM_1_STATUS_DESC: { readonly [id: string]: any } = {
  'NEW': 'รอตรวจสอบแบบแจ้ง',
  'PRIM_CORRECTION': 'แก้ไขข้อมูลแบบแจ้งเบื้องต้น',
  'CANCELATION': 'ยกเลิกคำขอเนื่องจากไม่มีการดำเนินการตามกำหนด',
  'CANCEL_BY_APPLICANT': 'ยกเลิกคำร้อง/คำขอ',
  'PRIM_CORRECTED': 'รอตรวจสอบการแก้ไขข้อมูลแบบแจ้งเบื้องต้น',
  'WAIT_VERIFY': 'รอเอกสารฉบับจริง',
  'WAIT_APPOINT_LOCATION': 'รอนัดหมายตรวจสอบสถานที่',
  'WAIT_ACCEPT_LOCATION': 'รอตอบรับนัดหมายตรวจสอบสถานที่',
  'APPOINT_DENIED': 'ปฏิเสธนัดหมาย',
  'WAIT_INSPECT_LOCATION': 'รอตรวจสอบสถานที่',
  'LOCATION_DENIED': 'ไม่ผ่านเนื่องจากสถานที่ไม่เหมาะสม',
  'LOCATION_CORRECTION_REPORT': 'ให้ส่งรายงานแก้ไขสถานที่',
  'CORRECTION_SENT': 'รอตรวจสอบรายงานแก้ไขสถานที่',
  'WAIT_DIGI_SIG': 'รอลงลายมือชื่ออิเล็กทรอนิกส์รับทราบผลการตรวจสอบ',
  'WAIT_APPLICATION': 'รอการยื่นคำขอใบอนุญาตฯ',
  'APPLIED': 'ยื่นคำขอใบอนุญาตแล้ว',
  'WAIT_CERT': 'รอจัดทำใบอนุญาต',
  'REPORT_CORRECT': 'แก้ไขรายงานฯ',
  'WAIT_SIGN_PROPOSAL': 'รอเสนอลงนาม',
  'WAIT_ORDER': 'รอพิจารณาออกคำสั่ง',
  'DENIED': 'ไม่อนุญาต',
  'WAIT_PAYMENT': 'รอชำระค่าธรรมเนียม',
  'WAIT_VERIFY_PAYMENT': 'รอยืนยันการชำระค่าธรรมเนียม',
  'WAIT_RECEIPT': 'รอจัดทำใบเสร็จรับเงิน',
  'PAID': 'ชำระค่าธรรมเนียมแล้ว',
  'UPLOAD_SLIP': 'รอตรวจสอบหลักฐานการชำระค่าธรรมเนียม',
  'GRANT': 'อนุญาต',
}

export const FEE_RATE_IDS: { readonly [id: string]: any } = {
  'N': {
    'P': {'LESS10': '9.1', 'GREATER10': '9.2'},
    'I': '13',
    'S': '15.1',
  },
  'R': {
    'P': {'LESS10': '10.1', 'GREATER10': '10.2'},
    'I': '14',
    'S': '15.2',
  },
  'S': {
    'P': '1',
    'I': '1',
    'S': '1',
  }
};
export const REQUEST_INSPECTION_FORMS_DESC: { readonly [id: string]: any } = {
  'RequestInspectionForm1': `แบบคำขอรับการตรวจสอบโรงงานผลิตอาหารสัตว์น้ำเพื่อการส่งออก`,
};
export const NS4_FORMS_DESC: { readonly [id: string]: any } = {
  'Ns4Form1': `แบบคำขอแจ้งการนำเข้าอาหารสัตว์น้ำ (น.อ.1)`,
  'Ns4Form2': `แบบคำขอแก้ไขแบบคำขอแจ้งการนำเข้าอาหารสัตว์น้ำ (น.อ.1))`,
};
export const APPOINTMENT_STATUS_DESC: { readonly [id: string]: any } = {
  'ACCEPTED': 'ยืนยันนัดหมาย',
  'DENIED': 'ปฏิเสธนัดหมาย',
}

export const getCurrentDateStruct = () => {

  let currentDate = new Date();
  let currentDateStruct: NgbDateStruct = {
    day: currentDate.getDate(),
    month: currentDate.getMonth() + 1,
    year: currentDate.getFullYear()
  };
  return currentDateStruct;
}

export const AUDIT_FORM_STATUS_DESC: { readonly [id: string]: any } = {
  NEW: "คำขอใหม่",
  PRIM_CORRECT: 'แก้ไขข้อมูลเบื้องต้น',
  WAIT_VERIFY_TECH: 'รอตรวจสอบทางวิชาการ',
  WAIT_PRIM_CORRECT: 'รอแก้ไขข้อมูลเบื้องต้น',
  WAIT_VERIFY_CORRECT_PRIM: 'รอตรวจสอบการแก้ไขข้อมูลเบื้องต้น',
  WAIT_PERMIT_TECH: 'รออนุมัติทางวิชาการ',
  TECH_CORRECT: 'รอแก้ไขข้อมููลทางวิชาการ',
  WAIT_VERIFY_CORRECT_TECH: 'รอตรวจสอบการแก้ไขข้อมูลทางวิชาการ',
  WAIT_VERIFY_CORRECT_CHIEF: 'รอตรวจสอบการแก้ไขข้อมูลตามคำสั่งหัวหน้ากลุ่ม กวค.',
  WAIT_VERIFY_CORRECT_DIRECTOR: 'รอตรวจสอบการแก้ไขข้อมูลตามคำสั่งผอ. กพอ.',
  WAIT_SIGN: 'รอลงนาม',
  WAIT_ORDER: 'รอพิจารณาออกคำสั่ง',
  CORRECT_CHIEF: 'รอแก้ไขข้อมูลตามคำสั่งหัวหน้ากลุ่ม กวค.',
  SPECIFIED: 'จัดเป็นอาหารสัตว์ควบคุมเฉพาะ',
  FEED_NOT_SPECIFIED: 'จัดเป็นอาหารสัตว์ แต่ไม่จัดเป็นอาหารสัตว์ควบคุมเฉพาะ',
  NOT_FEED_NOT_SPECIFIED: 'ไม่จัดเป็นอาหารสัตว์และอาหารสัตว์ควบคุมเฉพาะ ',
  FAILED: 'ไม่ผ่าน',
  CORRECT_DIRECTOR: 'รอแก้ไขข้อมูลตามคำสั่งผอ. กพอ.',
  WAIT_NOTIFICATION_BOOK: "รอจัดทำหนังสือแจ้งผล",
  APPROVE: "อนุญาติ",
  NOT_APPROVE: "ไม่อนุญาติ",
  CONDITION_APPROVE: "อนุญาติแบบมีเงื่อนไข",
}

export const SITE_INSPECTION_PASS_RESULT_DESC: { readonly [id: string]: any } = {
  NOT_INSPECTION_AGAIN: "ไม่ต้องตรวจซ้ำ",
  SENT_REPORT_LOCATION: 'ให้ส่งรายงานแก้ไขสถานที่',
  NOT_OPERATED_PRODUCTION_INSPECTION_AGAIN: 'บริษัทยังไม่ได้ดำเนินการผลิต จะดำเนินการตรวจสอบอีกครั้ง',
  NOT_KEPT_STOCK_INSPECTION_AGAIN: 'บริษัทยังไม่มีการเก็บสต็อกวัตถุดิบ/อาหารสัตว์ควบคุมเฉพาะ จะดำเนินการตรวจสอบอีกครั้ง',
}

export const RegisterStatus: { readonly [id: string]: any } = {
  NEW: 'รอการบันทึกข้อมูลให้ครบถ้วน',
  PENDING: 'รออนุมัติการเข้าใช้ระบบ',
  APPROVED: 'อนุมัติการเข้าใช้ระบบแล้ว',
  CORRECTION: 'แก้ไขข้อมูลตามหมายเหตุเจ้าหน้าที่',
  WAIT_CORRECTION_CONSIDER: 'รอตรวจสอบแก้ไขข้อมูลตามหมายเหตุเจ้าหน้าที่',
  DENIED: 'ยกเลิกคำขอลงทะเบียนเข้าใช้ระบบ/เปลี่ยนแปลงข้อมูลทะเบียน'
}
export const RequestRegisterStatus: { readonly [id: string]: any } = {
  NEW: 'รอการบันทึกข้อมูลให้ครบถ้วน',
  PENDING: 'รออนุมัติการเข้าใช้ระบบ',
  APPROVED: 'อนุมัติการเข้าใช้ระบบแล้ว',
  CORRECTION: 'แก้ไขข้อมูลตามหมายเหตุเจ้าหน้าที่',
  WAIT_CORRECTION_CONSIDER: 'รอตรวจสอบแก้ไขข้อมูลตามหมายเหตุเจ้าหน้าที่',
  DENIED: 'ยกเลิกคำขอลงทะเบียนเข้าใช้ระบบ'
}
export const RequestCorrectionStatus: { readonly [id: string]: any } = {
  NEW: "รอการบันทึกข้อมูลให้ครบถ้วน",
  PENDING: "รอการอนุมัติขอเปลี่ยนแปลงข้อมูลทะเบียน",
  APPROVED: "อนุมัติการขอเปลี่ยนแปลงข้อมูลทะเบียนแล้ว",
  CORRECTION: "แก้ไขข้อมูลตามหมายเหตุเจ้าหน้าที่",
  WAIT_CORRECTION_CONSIDER: "รอตรวจสอบแก้ไขข้อมูลตามหมายเหตุเจ้าหน้าที่",
  DENIED: "ยกเลิกคำขอเปลี่ยนแปลงข้อมูลทะเบียนผู้เข้าใช้ระบบ"
}

export const RequestRenewalStatus: { readonly [id: string]: any } = {
  NEW: "รอการบันทึกข้อมูลให้ครบถ้วน",
  PENDING: "รอการอนุมัติขอต่ออายุทะเบียนข้อมูลทะเบียน",
  APPROVED: "อนุมัติการขอต่ออายุทะเบียนข้อมูลทะเบียนแล้ว",
  CORRECTION: "แก้ไขข้อมูลตามหมายเหตุเจ้าหน้าที่",
  WAIT_CORRECTION_CONSIDER: "รอตรวจสอบแก้ไขข้อมูลตามหมายเหตุเจ้าหน้าที่",
  DENIED: "ยกเลิกคำขอต่ออายุทะเบียนข้อมูลทะเบียนผู้เข้าใช้ระบบ"
}

export const NS4_FORM_STATUS_DESC: { readonly [id: string]: any } = {
  NEW: "คำขอใหม่",
  CORRECTION: "คำขอแก้ไข",
  SUBSTITUTE: "คำขอใบแทน",
  CANCELLATION: "ละทิ้งคำขอ",
  WAIT_PAYMENT: "รอชำระค่าธรรมเนียม",
  PAID: "ชำระค่าธรรมเนียมแล้ว",
  WAIT_VERIFY_TECH: "รอตรวจสอบทางวิชาการ",
  WAIT_PRIM_CORRECT: "รอแก้ไขข้อมูลเบื้องต้น",
  WAIT_VERIFY_CORRECTION_PRIM: "รอตรวจสอบการแก้ไขข้อมูลเบื้องต้น",
  WAIT_PERMIT_TECH: "รออนุมัติทางวิชาการ",
  WAIT_TECH_CORRECT: "รอแก้ไขข้อมููลทางวิชาการ",
  WAIT_SCHEDULE: "รอกำหนดการเข้าประชุม",
  WAIT_CERT: "รอจัดทำใบสำคัญ",
  WAIT_VERIFY_CORRECT_TECH: "รอตรวจสอบการแก้ไขข้อมูลทางวิชาการ",
  WAIT_VERIFY_CORRECT_CONCLUDE: "รอตรวจสอบการแก้ไข/ชี้แจงข้อมูลตามมติที่ประชุม",
  WAIT_VERIFY_CORRECT_CHIEF: "รอตรวจสอบการแก้ไขข้อมูลตามคำสั่งหัวหน้ากลุ่ม กวค.",
  WAIT_VERIFY_CORRECT_DIRECTOR: "รอตรวจสอบการแก้ไขข้อมูลตามคำสั่งผอ. กพอ.",
  WAIT_CONFERRED: "รอประชุม",
  DENIED_CONFERRED: "ไม่ผ่านมติที่ประชุม",
  CORRECT_CONCLUDE: "รอแก้ไข/ชี้แจงข้อมูลตามมติที่ประชุม",
  WAIT_SIGN: "รอลงนาม",
  WAIT_ORDER: "รอพิจารณาออกคำสั่ง",
  CORRECT_CHIEF: "รอแก้ไขข้อมูลตามคำสั่งหัวหน้ากลุ่ม กวค.",
  APPROVE: "อนุมัติ",
  APPROVE_CONDITION: "อนุุมัติแบบมีเงื่อนไข",
  DISAPPROVE: "ไม่อนุมัติ",
  CORRECT_DIRECTOR: "รอแก้ไขข้อมูลตามคำสั่งผอ. กพอ.",
  DENIED: "ละทิ้งคำขอ"
}

export const isSafari = navigator.userAgent.indexOf('Safari') > -1;
