import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AquaService} from "./shareds/service/aqua.service";
import {filter, map} from "rxjs/operators";
import {untilDestroyed} from 'ngx-take-until-destroy';
import {LoadingService} from "./shareds/loading.service";
import * as CryptoJS from "crypto-js"

@Component({
  // tslint:disable-next-line
  selector: 'body',
  styleUrls: ['app.component.scss'],
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(private router: Router,
              private _aquaService: AquaService,
              private _loadingService: LoadingService) {

    function pad(number) {
      if (number < 10) {
        return '0' + number;
      }
      return number;
    }

    Date.prototype.toISOString = function () {
      return this.getFullYear() +
        '-' + pad(this.getMonth() + 1) +
        '-' + pad(this.getDate()) +
        'T' + pad(this.getHours()) +
        ':' + pad(this.getMinutes()) +
        ':' + pad(this.getSeconds());
    }
    Date.prototype.toString = Date.prototype.toISOString;

  }

  @HostBinding('class')
  currentSystem: string = undefined;

  ngOnInit() {

    this._aquaService
      .currentSystemActive
      .pipe(
        filter(e => !!e),
        map(e => (e || '').replace('officer-', '')),
        untilDestroyed(this)
      )
      .subscribe(system => this.currentSystem = system);
  }

  ngOnDestroy(): void {

  }
}
