import {ChangeDetectorRef, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GtNgDatepickerComponent} from "./components/gt-ng-datepicker/gt-ng-datepicker.component";
import {GtNgDatepickerMaskDirective} from "./components/gt-ng-datepicker/gt-ng-datepicker-mask.directive";
import {
  NgbDropdown,
  NgbDropdownAnchor,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbModule,
  NgbNavModule,
  NgbPopover,
  NgbPopoverModule
} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {GtAmphurComponent} from './components/gt-amphur/gt-amphur.component';
import {ConfirmDialogComponent} from './components/dialog/confirm-dialog/confirm-dialog.component';
import {GtErrorComponent} from "./components/gt-error/gt-error.component";
import {ThaiDateTimePipe} from "./thai-datetime.pipe";
import {HasAnyAuthorityDirective} from "./components/has-any-authority/has-any-authority.directive";
import {AuthorityPipe} from "./authority.pipe";
import {PasswordStrengthBarComponent} from "./components/password-strength-bar/password-strength-bar.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {GtAppHeader} from "./components/form-header/gt-app-header.component";
import {FormFooterComponent} from '../views/components/form-footer/form-footer.component';
import {DraftLoaderComponent} from './components/draft-loader/draft-loader.component';
import {MasterService} from "./service/master.service";
import {FileUploadModule} from "ng2-file-upload";
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {DummyComponentComponent} from './components/dummy-component/dummy-component.component';
import {LoaderComponent} from "./components/loader/loader.component";
import {AppService} from "./service/app.service";
import {GtTambonComponent} from "./components/gt-tambon/gt-tambon.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {GtTambonAutocompleteComponent} from './components/gt-tambon-autocomplete/gt-tambon-autocomplete.component';
import {NgOptionHighlightModule} from "@ng-select/ng-option-highlight";
import {GtAmphurAutocompleteComponent} from "./components/gt-amphur-autocomplete/gt-amphur-autocomplete.component";
import {GtDiffModule} from "./directives/gt-diff/gt-diff.module";
import {AppTocComponent} from "./components/app-toc/app-toc.component";
import {AlertDialogComponent} from "./components/dialog/alert-dialog/alert-dialog.component";
import {GtCountryComponent} from './components/gt-country/gt-country.component';
import {PasswordP12DialogComponent, SaveFormDialogComponent} from "./components/dialog/save-form-dialog/save-form-dialog.component";
import {KeyValueStartswithPipe} from "./key-value-startswith.pipe";
import {GtCountryAutocompleteComponent} from "./components/gt-country-autocomplete/gt-country-autocomplete.component";
import {CheckListDirective} from "./directives/check-list.directive";
import {GtUnitAutocompleteComponent} from './components/gt-unit-autocomplete/gt-unit-autocomplete.component';
import {GtPackageUnitAutocompleteComponent} from "./components/gt-package-unit-autocomplete/gt-package-unit-autocomplete.component";
import {NetworkUnstableComponent} from "./components/network-unstable/network-unstable.component";
import {GtNgDatepickerRangeComponent} from './components/gt-ng-datepicker-range/gt-ng-datepicker-range.component';
import {GtTypeHowtoComponent} from './components/gt-type-howto/gt-type-howto.component';
import {PdfPreviewWithSignComponent} from './components/dialog/pdf-preview-with-sign/pdf-preview-with-sign.component';
import {NgbdSortableHeader} from "./directives/sortable.directive";
import {GtDofOrgAutocompleteComponent} from './components/gt-dof-org-autocomplete/gt-dof-org-autocomplete.component';
import {GtCustomOrgAutocompleteComponent} from './components/gt-custom-org-autocomplete/gt-custom-org-autocomplete.component';
import {SelectableTitleDirective} from './directives/selectable-header/selectable-title.directive';
import {SelectableTitleCommentComponent} from './directives/selectable-header/selectable-title-comment/selectable-title-comment.component';
import {GtDofPortAutocompleteComponent} from './components/gt-dof-port-autocomplete/gt-dof-port-autocomplete.component';
import {PdfPreviewDialogComponent} from "./components/dialog/pdf-preview-dialog/pdf-preview-dialog.component";
import {AppFormCorrectionDirective} from "../app-form-correction.directive";
import {AppFormCorrectionContainerDirective} from "../app-form-correction-container.directive";
import {GtUserInGroupAutocompleteComponent} from "./components/gt-user-in-group-autocomplete/gt-user-in-group-autocomplete.component";
import {DocumentViewerDialogComponent} from "./components/dialog/document-viewer-dialog/document-viewer-dialog.component";
import {DownloadTemplateService} from "./service/download-template.service";
import {RegisterExpiredDialogComponent} from "./components/dialog/register-expired-dialog/register-expired-dialog.component";
import {GtConsignmentCountryAutocompleteComponent} from "./components/gt-consignment-country-autocomplete/gt-consignment-country-autocomplete.component";
import {GtPortAutocompleteComponent} from "./components/gt-port-autocomplete/gt-port-autocomplete.component";
import {EquipmentListFileUploadComponent} from "./components/equipment-list-file-upload/equipment-list-file-upload.component";
import {ChartsModule} from "ng2-charts";
import {ApplicantDetailsModalComponent} from "../views/components/applicant/applicant-details-modal/applicant-details-modal.component";
import {ThaiTimePipe} from "./thai-time.pipe";
import {GtCurrencyAutocompleteComponent} from './components/gt-currency-autocomplete/gt-currency-autocomplete.component';
import {SignatureFileUploadComponent} from "./components/signature-file-upload/signature-file-upload.component";
import {LoginAttemptsDialogComponent} from "./components/dialog/login-attempts-dialog/login-attempts-dialog.component";
import {GtOfficerAutocompleteComponent} from "./components/gt-officer-autocomplete/gt-officer-autocomplete.component";
import {GtPositionAutocompleteComponent} from './components/gt-position-autocomplete/gt-position-autocomplete.component';
import {BankTransferFileUploadComponent} from "./components/banktransfer-file-upload/bank-transfer-file-upload.component";
import {QuillModule} from "ngx-quill";
import {InputEditorModule} from "./components/input-editor/input-editor.module";
import {GtProvincePortAutocompleteComponent} from "./components/gt-province-port-autocomplete/gt-province-port-autocomplete.component"
import {UploadSlipDialogComponent} from "./components/dialog/upload-slip-dialog/upload-slip-dialog.component";
import {NgxCurrencyModule} from "ngx-currency";
import {GtDivisionAutocompleteComponent} from "./components/gt-division-autocomplete/gt-division-autocomplete.component";

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    NgbPopoverModule,
    NgbNavModule,
    FormsModule,
    FontAwesomeModule,
    FileUploadModule,
    NgSelectModule,
    NgOptionHighlightModule,
    ChartsModule,
    GtDiffModule,
    QuillModule,
    InputEditorModule,
    NgxCurrencyModule
  ],
  declarations: [
    GtNgDatepickerComponent,
    GtNgDatepickerMaskDirective,
    GtTambonComponent,
    GtAmphurComponent,
    GtErrorComponent,
    GtAppHeader,
    GtTambonAutocompleteComponent,
    GtAmphurAutocompleteComponent,
    GtCountryComponent,
    GtCountryAutocompleteComponent,
    GtConsignmentCountryAutocompleteComponent,
    GtPortAutocompleteComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    ThaiDateTimePipe,
    ThaiTimePipe,
    HasAnyAuthorityDirective,
    PasswordStrengthBarComponent,
    AuthorityPipe,
    FormFooterComponent,
    DraftLoaderComponent,
    FileUploadComponent,
    EquipmentListFileUploadComponent,
    SignatureFileUploadComponent,
    BankTransferFileUploadComponent,
    DummyComponentComponent,
    LoaderComponent,
    AppTocComponent,
    SaveFormDialogComponent,
    UploadSlipDialogComponent,
    DocumentViewerDialogComponent,
    PdfPreviewDialogComponent,
    PasswordP12DialogComponent,
    KeyValueStartswithPipe,
    CheckListDirective,
    NgbdSortableHeader,
    GtUnitAutocompleteComponent,
    GtPackageUnitAutocompleteComponent,
    NetworkUnstableComponent,
    GtNgDatepickerRangeComponent,
    GtTypeHowtoComponent,
    PdfPreviewWithSignComponent,
    GtDofOrgAutocompleteComponent,
    GtCustomOrgAutocompleteComponent,
    SelectableTitleDirective,
    SelectableTitleCommentComponent,
    GtDofPortAutocompleteComponent,
    AppFormCorrectionDirective,
    AppFormCorrectionContainerDirective,
    GtUserInGroupAutocompleteComponent,
    RegisterExpiredDialogComponent,
    ApplicantDetailsModalComponent,
    GtCurrencyAutocompleteComponent,
    LoginAttemptsDialogComponent,
    GtOfficerAutocompleteComponent,
    GtPositionAutocompleteComponent,
    GtProvincePortAutocompleteComponent,
    GtDivisionAutocompleteComponent
  ],
  exports: [
    FormsModule,
    NgbModule,
    NgbPopoverModule,
    NgbNavModule,
    FileUploadModule,
    FontAwesomeModule,
    QuillModule,
    InputEditorModule,
    ThaiDateTimePipe,
    ThaiTimePipe,
    HasAnyAuthorityDirective,
    PasswordStrengthBarComponent,
    AuthorityPipe,
    GtNgDatepickerComponent,
    GtNgDatepickerMaskDirective,
    GtTambonComponent,
    GtAmphurComponent,
    GtErrorComponent,
    GtAppHeader,
    GtTambonAutocompleteComponent,
    GtAmphurAutocompleteComponent,
    GtDiffModule,
    GtCountryComponent,
    GtConsignmentCountryAutocompleteComponent,
    GtPortAutocompleteComponent,
    GtCountryAutocompleteComponent,
    FormFooterComponent,
    DraftLoaderComponent,
    FileUploadComponent,
    EquipmentListFileUploadComponent,
    SignatureFileUploadComponent,
    BankTransferFileUploadComponent,
    DummyComponentComponent,
    LoaderComponent,
    NgSelectModule,
    NgOptionHighlightModule,
    AppTocComponent,
    GtCountryComponent,
    KeyValueStartswithPipe,
    CheckListDirective,
    NgbdSortableHeader,
    GtUnitAutocompleteComponent,
    GtPackageUnitAutocompleteComponent,
    NetworkUnstableComponent,
    GtNgDatepickerRangeComponent,
    GtTypeHowtoComponent,
    GtCustomOrgAutocompleteComponent,
    GtDofOrgAutocompleteComponent,
    SelectableTitleDirective,
    GtDofPortAutocompleteComponent,
    AppFormCorrectionDirective,
    AppFormCorrectionContainerDirective,
    GtUserInGroupAutocompleteComponent,
    ChartsModule,
    GtCurrencyAutocompleteComponent,
    GtOfficerAutocompleteComponent,
    GtPositionAutocompleteComponent,
    GtProvincePortAutocompleteComponent,
    NgxCurrencyModule,
    GtDivisionAutocompleteComponent
  ],
  providers: [
    {
      provide: NgbDropdown,
      deps: [ChangeDetectorRef],
      useValue: undefined
    },
    NgbDropdownAnchor,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgbPopover,
    HasAnyAuthorityDirective,
    AppService,
    MasterService,
    DownloadTemplateService
  ],
  entryComponents: [
    ConfirmDialogComponent,
    AlertDialogComponent,
    SaveFormDialogComponent,
    UploadSlipDialogComponent,
    DocumentViewerDialogComponent,
    PasswordP12DialogComponent,
    PdfPreviewDialogComponent,
    RegisterExpiredDialogComponent,
    ApplicantDetailsModalComponent,
    LoginAttemptsDialogComponent
  ],
})
export class SharedsModule {
}
