import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subscription} from "rxjs";
import {GtNgDatepickerComponent} from "../gt-ng-datepicker/gt-ng-datepicker.component";
import {HttpValidationService} from "../../../services/http-validation.service";
import {NgSelectComponent} from "@ng-select/ng-select";
import {GtTambonComponent} from "../gt-tambon/gt-tambon.component";
import {GtAmphurComponent} from "../gt-amphur/gt-amphur.component";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'gt-error',
  templateUrl: './gt-error.component.html',
  styleUrls: ['./gt-error.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GtErrorComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  field: string = '';
  @Input()
  code: string;
  @Input()
  codeInclude?: boolean = false;
  @Input()
  forComponent: ElementRef | HTMLElement | NgSelectComponent;
  @Input()
  showMessage?: boolean = true;
  @Input()
  forId: string
  forHtmlElement: HTMLElement
  message: string = null;

  private subscription: Subscription;

  constructor(private renderer: Renderer2,
              private validateService: HttpValidationService,
              private cdr: ChangeDetectorRef, @Inject(DOCUMENT) private document) {
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {

    this.subscription = this.validateService.error.subscribe(err => {

      // debugger;
      if (err && err.error && err.error.errors) {
        this.changeMessage(err.error.errors);
        this.cdr.detectChanges();
      } else {
        this.message = '';
        this.removeErrorClass(this.forComponent);
        this.cdr.detectChanges();
      }
    });
  }

  private changeMessage(violations: any[]) {

    if (this.code) {
      violations = violations.filter(e => e.codes.includes(this.code) || e.codes.some(c => c.includes(this.code)));
    }else if(this.codeInclude){
      violations = violations.filter(e => e.codes.some(c => c.includes(this.code)));
    }
    if (this.field) {
      violations = violations.filter(e => e.field === this.field);
    }
    this.message = violations.map(e => e['defaultMessage']).reduce((prev, curr) => curr, "");
    if (this.message.length) {
      this.addErrorClass(this.forComponent);
    } else {
      this.removeErrorClass(this.forComponent);
      this.message = null
    }
  }

  private addErrorClass(forComponent: ElementRef | HTMLElement | NgSelectComponent) {

    if (forComponent) {
      if (forComponent instanceof GtNgDatepickerComponent || forComponent instanceof GtTambonComponent || forComponent instanceof GtAmphurComponent) {
        forComponent.error();
      } else if (forComponent instanceof NgSelectComponent) {
        if (!!forComponent.element.children && forComponent.element.children.length) {
          this.renderer.setStyle(forComponent.element.children[0], 'border', '1px solid #f86c6b');
          this.renderer.setStyle(forComponent.element.children[0], 'border', '1px solid #f86c6b');
        }
      } else {
        try {
          this.renderer.addClass(forComponent, 'is-invalid');
          this.renderer.addClass(forComponent, 'has-feedback');
        } catch (e) {

        }
      }
    } else if (this.forId) {
      //cache this.forHtmlElement for future use to reduce time to lookup element
      this.forHtmlElement = this.forHtmlElement || this.document.getElementById(this.forId);
      if (this.forHtmlElement) {
        this.renderer.addClass(this.forHtmlElement, 'is-invalid');
        this.renderer.addClass(this.forHtmlElement, 'has-feedback');
      }
    }
  }

  private removeErrorClass(forComponent: ElementRef | HTMLElement | NgSelectComponent) {

    if (forComponent) {
      if (forComponent instanceof GtNgDatepickerComponent || forComponent instanceof GtTambonComponent || forComponent instanceof GtAmphurComponent) {
        forComponent.clearError();
      } else if (forComponent instanceof NgSelectComponent) {
        if (!!forComponent.element.children && forComponent.element.children.length) {
          this.renderer.removeStyle(forComponent.element.children[0], 'border');
          this.renderer.removeStyle(forComponent.element.children[0], 'border');
        }
      } else {
        try {
          this.renderer.removeClass(forComponent, 'is-invalid');
          this.renderer.removeClass(forComponent, 'has-feedback');
        } catch (e) {

        }
      }
    } else if (this.forHtmlElement) {
      this.forHtmlElement.classList.remove('is-invalid', 'has-feedback')
    }
  }

  ngOnDestroy(): void {
    this.cdr.detach();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
