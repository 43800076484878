import {Injectable} from '@angular/core';
import {NgbTimeAdapter, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {StringUtils} from "./utils/string-utils";

export const ISOTIME_FORMAT = new RegExp('[0-9]{2}:[0-9]{2}:[0-9]{2}');

@Injectable()
export class CustomNgbTimeAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string): NgbTimeStruct {
    if (!!value) {
      if (ISOTIME_FORMAT.test(value)) {
        const hour: number = Number(value.substring(0, 2));
        const minute: number = Number(value.substring(3, 5));
        const second: number = Number(value.substring(6, 8));
        return {hour: hour, minute: minute, second: second};
      }
      throw Error("Invalid date ");
    } else {
      return undefined;
    }
  }

  toModel(time: NgbTimeStruct): string {
    if (!!time) {
      return `${StringUtils.paddingZeroLeft(time.hour, 2)}:${StringUtils.paddingZeroLeft(time.minute, 2)}:${StringUtils.paddingZeroLeft(time.second, 2)}`;
    }
    return undefined;
  }
}
