import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-form-footer',
  templateUrl: './form-footer.component.html',
  styleUrls: ['./form-footer.component.css']
})
export class FormFooterComponent implements OnInit {

  @Input()
  public submitBtnText?: string = 'ตรวจสอบข้อมูล';

  @Output()
  public onSubmit: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  submit() {

    this.onSubmit.emit();
  }
}
