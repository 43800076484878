import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DomSanitizer} from "@angular/platform-browser";
import {from, Observable, of} from "rxjs";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {ToastrService} from "ngx-toastr";
import {LoadingService} from "../../../loading.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {FileUpload} from "../../../../../generated-model/model";
import {AuthenService} from "../../../../authen.service";
import {IgnoreNullHttpParams} from "../../../Ignore-null-http-params";

export type UploadSlipDialogOption = {
  label: string,
  styles?: {
    buttonClasses?: string[],
    iconClasses?: string[]
  }
  callback?: (p12password?: string) => Observable<any>,
  redirectUrl?: string[],
  showCondition?: () => Observable<boolean>,
  confirmDialogMessage?: string,
  successAlertMessage?: string,
  authorities?: string[]
};

@Component({
  selector: 'app-upload-slip-dialog',
  templateUrl: './upload-slip-dialog.component.html',
  styleUrls: ['./upload-slip-dialog.component.css']
})
export class UploadSlipDialogComponent implements OnInit, OnDestroy {

  private _options: UploadSlipDialogOption[] = [];

  private _file: FileUpload;

  @Input()
  title: string = 'ตรวจสอบข้อมูล';

  innerHTML: any;
  objectURL: string;

  constructor(public activeModal: NgbActiveModal,
              public modalService: NgbModal,
              public loadingService: LoadingService,
              private _domSanitizer: DomSanitizer,
              private _toastrService: ToastrService,
              private router: Router,
              private activateRoute: ActivatedRoute,
              private _http: HttpClient,
              public authenService: AuthenService) {
  }

  ngOnInit() {
  }

  private loadFile(file: FileUpload) {

    this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(
      `<div style="min-height: 750px; width: 100%;">
          <div style="vertical-align: middle; margin-top: auto; margin-bottom: auto;">
            <span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true" style="vertical-align: middle;"></span>
            <span>กำลังโหลดเอกสาร...</span>
          </div>
        </div>`
    );

    let params = new IgnoreNullHttpParams()
      .set("filePath", file.filePath)
      .set("fileName", file.fileName)
      .set("contentType", file.contentType);

    this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(
      `<iframe src="public/api/file-upload/preview?${params.toHttpParam()}"
                class='embed-responsive-item'
                style="min-height: 750px; width: 100%;">
            เกิดข้อผิดพลาดในการโหลดไฟล์
            </iframe>`
    );
  }

  save(option: UploadSlipDialogOption): void {

    const modalRef = this.modalService.open(ConfirmDialogComponent, {centered: true});
    modalRef.componentInstance.content = option.confirmDialogMessage || `ยืนยันการแนบหลักฐานการชำระค่าธรรมเนียม?`;
    from(modalRef.result)
      .subscribe(e => {
        if (!!e) {
          option.callback()
            .subscribe(value => {
              this._toastrService.success(option.successAlertMessage || "แนบหลักฐานการชำระค่าธรรมเนียมเรียบร้อยแล้ว");
              this.activeModal.close(true);
              console.log('option.redirectUrl = ', option.redirectUrl);
              if (option.redirectUrl) {
                this.router.navigate(option.redirectUrl);
              }
            });
        } else {
          console.log('denied.');
        }
      }, error => error);
  }

  get options(): UploadSlipDialogOption[] {
    return this._options;
  }

  @Input()
  set options(value: UploadSlipDialogOption[]) {

    this._options = value;
    this._options.forEach(option => {
        option.showCondition ??= () => of(true);
        option['onClick'] = () => this.save(option);
      }
    );
  }

  close() {

    this.clearObjectURL();
    this.activeModal.close(false);
  }

  clearObjectURL() {

    console.log('revoking object URL...');
    if (this.objectURL) {
      URL.revokeObjectURL(this.objectURL);
    }
  }

  get file(): FileUpload {
    return this._file;
  }

  @Input()
  set file(value: FileUpload) {
    this._file = value;
    if(value) {
      this.loadFile(value);
    }
  }

  ngOnDestroy(): void {

    this.clearObjectURL();
  }
}
