import {Component, OnInit} from '@angular/core';
import {AuthenService} from "../../authen.service";
import {AppInfoService, BuildWithDate} from "../../services/app-info.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  appInfo: BuildWithDate
  username: string;
  password: string;
  showPassword: boolean = false;

  constructor(public authenService: AuthenService,
              private _appInfoService: AppInfoService,) {

    this._appInfoService.appInfo.subscribe(e => this.appInfo = e)
  }

  ngOnInit(): void {

  }

  login() {
    this.authenService.login(this.username, this.password);
  }

  clickShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
