import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {AlertDialogComponent} from "./components/dialog/alert-dialog/alert-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class Http404Interceptor implements HttpInterceptor {

  constructor(private _modalService: NgbModal) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
      .pipe(
        tap(event => {

        }, (err: HttpErrorResponse) => {
          if (err.status == 404) {
            const modalRef = this._modalService.open(AlertDialogComponent, {centered: true});
            modalRef.componentInstance.content = err?.error?.message ? err?.error?.message :`ไม่พบข้อมูล (Resource Not Found 404)`;
          }
        })
      );
  }


}
