import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {untilDestroyed} from "ngx-take-until-destroy";
import {Nation, RequestEmployee, RequestUser, Title} from "../../../../../../../../generated-model/model";
import {LoadingService} from "../../../../../../../shareds/loading.service";
import {MasterService} from "../../../../../../../shareds/service/master.service";
import {share} from "rxjs/operators";
import {compareWithId} from '../../../../../../../comparable';
import {getCurrentDateStruct} from "../../../../../../../_app_constant";
import {RequestEmployeeService} from "./services/request-employee.service";

@Component({
  selector: 'app-request-employee-details-modal',
  templateUrl: './request-employee-details-modal.component.html',
  styleUrls: ['./request-employee-details-modal.component.css'],
  providers: [RequestEmployeeService]
})
export class RequestEmployeeDetailsModalComponent implements OnInit, OnDestroy {

  titles$: Observable<Title[]>;
  nations$: Observable<Nation[]>;
  compareWithId = compareWithId;
  currentDateStruct = getCurrentDateStruct();

  @Input()
  state: 'DETAILS' | 'UPDATE' = 'DETAILS';

  @Input()
  employee: Partial<RequestEmployee> = {
    identityCard: {cardType: "ID_CARD"},
    files: {
      'ID_CARD_COPY': {fileType: 'ID_CARD_COPY'},
      'PASSPORT_COPY': {fileType: 'PASSPORT_COPY'},
      'ATTORNEY_COPY': {fileType: 'ATTORNEY_COPY'}
    },
    contactAddress: {}
  };
  onSave: EventEmitter<RequestEmployee> = new EventEmitter<RequestEmployee>();

  @Input()
  requestUser: RequestUser;

  constructor(private _masterService: MasterService,
              private _service: RequestEmployeeService,
              public loadingService: LoadingService,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {

    this.titles$ = this._masterService.titleNames().pipe(untilDestroyed(this), share());
    this.nations$ = this._masterService.nations().pipe(untilDestroyed(this), share());
    if (!this.requestUser) {
      throw new Error("requestUser cannot be null.");
    }

    this.employee.files.ATTORNEY_COPY ??= {fileType: 'ATTORNEY_COPY'};
  }

  save() {

    this._service.save(this.requestUser, this.employee)
      .subscribe(value => {
        this.onSave.emit(value);
        this.activeModal.dismiss();
      });
  }

  copyCardAddressToContactAddress() {

    if (!this.employee.contactAddress) {
      this.employee.contactAddress = {};
    }
    this.employee.contactAddress.contactAddress = this.employee.identityCard?.cardAddress;
    this.employee.contactAddress.contactMoo = this.employee.identityCard?.cardMoo;
    this.employee.contactAddress.contactSoi = this.employee.identityCard?.cardSoi;
    this.employee.contactAddress.contactStreet = this.employee.identityCard?.cardStreet;
    this.employee.contactAddress.contactTambonId = this.employee.identityCard?.cardTambon?.id;
    this.employee.contactAddress.contactTambon = this.employee.identityCard?.cardTambon;
    this.employee.contactAddress.contactPostalCode = this.employee.identityCard?.cardPostalCode;
    this.employee.contactAddress.contactTel = this.employee.identityCard?.cardTel;
  }

  ngOnDestroy(): void {
  }

}
