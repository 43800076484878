import {Component, HostListener, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AppTocService} from "./app-toc.service";
import {untilDestroyed} from "ngx-take-until-destroy";

@Component({
  selector: 'app-toc',
  templateUrl: './app-toc.component.html',
  styleUrls: ['./app-toc.component.css']
})
export class AppTocComponent implements OnInit, OnDestroy {

  public headings: any[];

  constructor(private _appTocService: AppTocService,
              private _renderer: Renderer2) {
  }

  ngOnInit(): void {

    this._appTocService.headings
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.headings = value;
      });
  }

  routeIntoView(elementId: string) {

    this.clearActive(elementId);
    window.scrollTo({top: this._appTocService.getOffsetTop(elementId)});
  }

  private clearActive(elementId: string) {

    this.headings.map(e => e.href)
      .filter(id => id != elementId)
      .forEach(id => {
        try {
          this._renderer.removeClass(document.querySelector(`.delegate-${id}`), 'active');
        } catch (e) {
          //do nothing
          console.warn(e);
        }
      });
    this._renderer.addClass(document.querySelector(`.delegate-${elementId}`), 'active');
  }

  @HostListener('window:scroll')
  public windowOnScroll() {

    this.headings.forEach(value => {
      try {
        if (this._appTocService.isActive(value.offsetTop(), value.offsetBottom())) {
          // console.log('active ')
          this._renderer.addClass(document.querySelector(`.delegate-${value.href}`), 'active');
        } else {
          this._renderer.removeClass(document.querySelector(`.delegate-${value.href}`), 'active');
        }
      } catch (e) {
        //do nothing
        console.warn(e);
      }
    });
  }

  ngOnDestroy(): void {
  }

}
