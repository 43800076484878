import {NgbDateParserFormatter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {
  ACCEPT_DATE_FORMAT,
  INPUT_MASK_DATE_FORMAT,
  ISODATE_FORMAT
} from "./components/gt-ng-datepicker/gt-ng-datepicker.component";
import { Injectable } from "@angular/core";


@Injectable()
export class NgbDateParser implements NgbDateParserFormatter {

  dateFormat: string;

  constructor() {
  }

  /**
   * Parses the entered date.
   * @param value the entered date.
   */
  parse(value: string): NgbDateStruct {

    let result;
    if(!!value) {
      if(INPUT_MASK_DATE_FORMAT.test(value)) {
        if(ACCEPT_DATE_FORMAT.test(value)) {
          let maskvalue: string[] = value.split('/');
          result = {year: Number(maskvalue[2]) - 543, month: maskvalue[1], day: maskvalue[0]};
        }
      } else {
        if (ISODATE_FORMAT.test(value)) {
          try {
            let date = new Date(value);
            date = new Date(date.getDate(),date.getMonth(),date.getFullYear() -543);
            result = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
          } catch (e) {
            console.warn(e);
          }
        }
      }
    }
    return result;
  }

  /**
   * Formats a date into a language specific string.
   * @param date the data from the model.
   */
  format(date: NgbDateStruct): string {
    let formattedMomentDate = '';
    let value: Date;
    if (date) {
      value = new Date(date.year, date.month - 1, date.day);
      var options = {year: 'numeric', month: '2-digit', day: '2-digit'};
      return value.toLocaleString('th-TH', options);
    }

    return formattedMomentDate;
  }
}
