import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  AllEnumClass,
  Animal,
  AnimalAge,
  AnimalFeedGroup,
  AnimalFeedGroupId,
  AnimalFeedType,
  AnimalFeedTypeId,
  AnimalHowTo,
  AnimalSubType,
  AnimalType,
  Audit,
  AuditFormPurpose,
  AuditOtherFactor,
  CompanyBranch,
  CompanyType,
  ConcreteFeedType,
  Country,
  Division,
  DofOrg,
  DofPort,
  FeedHowTo,
  FeedProperties,
  FeedWarning,
  FeeRate,
  FormType,
  GmpHaccpFormStatus,
  Ingredient,
  LicenseType,
  MaterialGroup,
  MaterialType,
  MixIngredient,
  MixIngredientSubType,
  MixIngredientSubTypeName,
  MixIngredientType,
  Nation,
  OfficerComment,
  PackageType,
  PackageUnit,
  Page,
  PersonType,
  Position,
  ProductType,
  Province,
  RequestRegisterStatus,
  RequestRegisterType,
  ScopeOfCertification,
  ScopeOfCertificationSearchRequest,
  Title,
  TransportType,
  Unit,
  UserStatus,
  Voucher,
  VoucherProjection,
  VoucherSearchRequest
} from "../../../generated-model/model";
import {first, map, shareReplay} from "rxjs/operators";
import {IgnoreNullHttpParams} from "../Ignore-null-http-params";

export type EnumUnion =
  Animal
  | PersonType
  | UserStatus
  | RequestRegisterStatus
  | LicenseType
  | RequestRegisterType
  | GmpHaccpFormStatus;
export type EnumDesc = { name: EnumUnion, value: string };

@Injectable()
export class MasterService {

  auditFormPurposes: Observable<AuditFormPurpose[]>;
  private title$: Observable<Title[]>;
  private titleEn$: Observable<Title[]>;

  private _enums: { [key in AllEnumClass]?: EnumDesc[] } = {};

  constructor(private _http: HttpClient) {

    this._http.get<{ [key in AllEnumClass]: EnumDesc[] }>('public/api/enum/th.co.geniustree.dof.eaquafeed.web.domain.enums')
      .subscribe(enums => {
        this._enums = enums;
      });
  }

  companyTypes(): Observable<CompanyType[]> {
    return this._http.get<CompanyType[]>('public/api/company-type');
  }

  titleNames(): Observable<Title[]> {

    this.title$ = this.title$ || this._http.get<Title[]>('public/api/title').pipe(shareReplay(1), first());
    return this.title$
  }

  titleNamesEn(): Observable<Title[]> {

    this.titleEn$ = this.titleEn$ || this._http.get<Title[]>('public/api/title/en').pipe(shareReplay(1), first());
    return this.titleEn$
  }

  nations(): Observable<Nation[]> {
    return this._http.get<Nation[]>('public/api/nation');
  }

  countries(): Observable<Country[]> {
    return this._http.get<Country[]>('public/api/country');
  }

  offices(): Observable<DofOrg[]> {
    return this._http.get<DofOrg[]>('public/api/doforg/office');
  }

  ports(): Observable<DofOrg[]> {
    return this._http.get<DofOrg[]>('public/api/doforg/port');
  }

  dofPortAll(): Observable<DofPort[]> {
    return this._http.get<DofPort[]>('public/api/dofport/all');
  }

  provinces(): Observable<Province[]> {
    return this._http.get<Province[]>('public/api/province');
  }

  positions(): Observable<Position[]> {
    return this._http.get<Position[]>('public/api/position');
  }

  divisions(): Observable<Division[]> {
    return this._http.get<Division[]>('public/api/division');
  }

  companyBranches(): Observable<CompanyBranch[]> {
    return this._http.get<CompanyBranch[]>(`public/api/company-branch`);
  }

  findNotExistsCompanyBranchesByByCompanyCorporateNumber(corporateNumber: string): Observable<CompanyBranch[]> {
    return this._http.get<CompanyBranch[]>(`public/api/company-branch/${corporateNumber}/not-exists`);
  }

  animalFeedGroups(): Observable<AnimalFeedGroup[]> {
    return this._http.get<AnimalFeedGroup[]>(`api/animal-feed-group`);
  }

  animalFeedGroupsBySuperGroup(superGroup: "ANIMAL_FEED" | "OTHERS"): Observable<AnimalFeedGroup[]> {
    return this._http.get<AnimalFeedGroup[]>(`api/animal-feed-group/super-group/${superGroup}`);
  }

  animalFeedTypes(params = IgnoreNullHttpParams.fromObject({}).set('sort', 'id,asc')): Observable<AnimalFeedType[]> {
    return this._http.get<AnimalFeedType[]>(`api/animal-feed-type`, {params: params.toHttpParam()})
  }

  animalFeedTypesByGroup(group: AnimalFeedGroupId): Observable<AnimalFeedType[]> {
    return this._http.get<AnimalFeedType[]>(`api/animal-feed-type/group/${group}`);
  }

  voucherAnimalFeedTypes(): Observable<AnimalFeedType[]> {
    return this._http.get<AnimalFeedType[]>(`api/animal-feed-type/voucher`);
  }

  voucherAnimalFeedTypesByGroup(group: AnimalFeedGroupId): Observable<AnimalFeedType[]> {
    return this._http.get<AnimalFeedType[]>(`api/animal-feed-type/voucher/group/${group}`);
  }

  auditAnimalFeedTypes() {
    return this._http.get<AnimalFeedType[]>(`api/animal-feed-type/Audit`);
  }

  auditAnimalFeedTypesByGroup(group: AnimalFeedGroupId): Observable<AnimalFeedType[]> {
    return this._http.get<AnimalFeedType[]>(`api/animal-feed-type/Audit/group/${group}`);
  }

  auditOtherFactor() {
    return this._http.get<AuditOtherFactor[]>(`api/audit-other-factor`);
  }

  auditConcreteFeedTypesByAnimalFeedTypeId(animalFeedTypeId: AnimalFeedTypeId) {
    return this._http.get<ConcreteFeedType[]>(`api/concrete-feed-type/Audit/${animalFeedTypeId}`);

  }

  animalTypes(): Observable<AnimalType[]> {
    return this._http.get<AnimalType[]>(`api/animal-type`);
  }

  animalTypesVoucher(): Observable<AnimalType[]> {
    return this._http.get<AnimalType[]>(`api/animal-type/Voucher`);
  }

  animalSubTypesByTypeId(id: string): Observable<AnimalSubType[]> {
    return this._http.get<AnimalSubType[]>(`api/animal-sub-type/animal-type/${id}`);
  }


  animalSubTypes(): Observable<AnimalSubType[]> {
    return this._http.get<AnimalSubType[]>(`api/animal-sub-type`);
  }

  animalAges(): Observable<AnimalAge[]> {
    return this._http.get<AnimalAge[]>(`api/animal-age`);
  }

  animalHowTos(): Observable<AnimalHowTo[]> {
    return this._http.get<AnimalHowTo[]>(`api/animal-howto`);
  }

  concreteFeedTypes(): Observable<ConcreteFeedType[]> {
    return this._http.get<ConcreteFeedType[]>(`api/concrete-feed-type`);
  }

  animalAgesByTypeId(id: string): Observable<AnimalAge[]> {
    return this._http.get<AnimalAge[]>(`api/animal-age/animal-type/${id}`);
  }

  animalAgesBySubTypeId(id: string): Observable<AnimalAge[]> {
    return this._http.get<AnimalAge[]>(`api/animal-age/animal-sub-type/${id}`);
  }

  mixIngredientTypes(): Observable<MixIngredientType[]> {
    return this._http.get<MixIngredientType[]>(`api/mix-ingredient-type`);
  }

  mixIngredientSubTypesByTypeId(id: string): Observable<MixIngredientSubType[]> {
    return this._http.get<MixIngredientSubType[]>(`api/mix-ingredient-sub-type/mix-ingredient-type/${id}`);
  }

  mixIngredientSubTypesNameByTypeId(id: string): Observable<MixIngredientSubTypeName[]> {
    return this._http.get<MixIngredientSubType[]>(`api/mix-ingredient-sub-type-name/${id}`);
  }

  mixIngredients(): Observable<MixIngredient[]> {
    return this._http.get<MixIngredient[]>(`api/mix-ingredient`);
  }

  ingredients(): Observable<Ingredient[]> {
    return this._http.get<Ingredient[]>(`api/ingredient`, {params: IgnoreNullHttpParams.fromObject({}).set('sort', 'id,asc').toHttpParam()});
  }

  packageTypes(): Observable<PackageType[]> {
    return this._http.get<PackageType[]>(`api/package-type`);
  }

  packageUnits(): Observable<PackageUnit[]> {
    return this._http.get<PackageUnit[]>(`api/package-unit`);
  }

  packageUnitsConfig(): Observable<Unit[]> {
    return this._http.get<Unit[]>(`public/api/unit/package-unit`);
  }

  IngredientUnitsConfig(): Observable<Unit[]> {
    return this._http.get<Unit[]>(`public/api/unit/ingredient`);
  }

  unitsCertiNs4Config(): Observable<Unit[]> {
    return this._http.get<Unit[]>(`public/api/unit/certi-ns4`);
  }

  packageUnitsCertiNs4Config(): Observable<PackageUnit[]> {
    return this._http.get<PackageUnit[]>(`api/package-unit/certi-ns4`);
  }

  packageUnitsProductConfig(): Observable<Unit[]> {
    return this._http.get<Unit[]>(`public/api/unit/product-package-unit`);
  }

  materialGroups(): Observable<MaterialGroup[]> {
    return this._http.get<MaterialGroup[]>(`api/material-group`);
  }

  materialTypes(): Observable<MaterialType[]> {
    return this._http.get<MaterialType[]>(`api/material-type`);
  }

  materialTypesByGroupId(id: string): Observable<MaterialType[]> {
    return this._http.get<MaterialType[]>(`api/material-type/group/${id}`);
  }

  yearBuddhistGenerator(): Observable<number[]> {
    return this._http.get<number[]>(`api/year/buddhist-generate`);
  }

  licensingAnimalTypes(): Observable<AnimalType[]> {
    return this._http.get<AnimalType[]>(`api/animal-type/Licensing`);
  }

  licensingReportBackwardAnimalTypes(): Observable<AnimalType[]> {
    return this._http.get<AnimalType[]>(`api/animal-type/report-backward/Licensing`);
  }

  licensingAnimalFeedGroups(): Observable<AnimalFeedGroup[]> {
    return this._http.get<AnimalFeedGroup[]>(`api/animal-feed-group/Licensing`);
  }

  licensingAnimalFeedTypes(): Observable<AnimalFeedType[]> {
    return this._http.get<AnimalFeedType[]>(`api/animal-feed-type/Licensing`);
  }

  gmpHaccpAnimalFeedGroups(): Observable<AnimalFeedGroup[]> {
    return this._http.get<AnimalFeedGroup[]>(`api/animal-feed-group/GmpHaccp`);
  }

  gmpHaccpAnimalFeedTypes(): Observable<AnimalFeedType[]> {
    return this._http.get<AnimalFeedType[]>(`api/animal-feed-type/GmpHaccp`);
  }

  certificateAnimalFeedGTypes(): Observable<AnimalFeedType[]> {
    return this._http.get<AnimalFeedType[]>(`api/animal-feed-type/Certificate`);
  }

  enums(key: AllEnumClass): Observable<EnumDesc[]> {
    return this._http.get<{ [key in AllEnumClass]: EnumDesc[] }>('public/api/enum/th.co.geniustree.dof.eaquafeed.web.domain.enums')
      .pipe(map(enums => enums[key] as EnumDesc[]));
  }

  getEnum(key: AllEnumClass): EnumDesc[] {

    return this._enums[key] || [];
  }

  getEnumDescription(key1: AllEnumClass, key2: EnumUnion): string {
    return this.getEnum(key1)
      .filter(e => e.name == key2)
      .map(e => e.value)[0];
  }

  getAllVoucher(): Observable<Voucher[]> {
    return this._http.get<Voucher[]>('api/voucher');
  }

  getAllVoucherPagination(condition: VoucherSearchRequest, page): Observable<Page<Voucher>> {
    let params = new IgnoreNullHttpParams()
      .set("page", page)
      .set("size", 10)
      .set("brand", condition.brand)
      .set("voucherNo", condition.voucherNo)
      .set("department", condition.department)
      .set("licensingIssue", condition.voucherLicensingIssue)
      .set("voucherLicensingIssue", condition.voucherLicensingIssue);
    return this._http.get<Page<Voucher>>('api/voucher', {params: params.toHttpParam()});
  }

  getAllVoucherPaginationByDepartmentIsDOF(condition: VoucherSearchRequest, page): Observable<Page<VoucherProjection>> {
    let params = IgnoreNullHttpParams.fromObject(condition)
      .append("page", page - 1)
      .append("size", 10)
    return this._http.get<Page<VoucherProjection>>('api/voucher/search/DOF', {params: params.toHttpParam()});
  }

  getAllVoucherForCertificatePage(condition: VoucherSearchRequest, page): Observable<Page<Voucher>> {
    let params = IgnoreNullHttpParams.fromObject(condition)
      .append("page", page - 1)
      .append("size", 10)
    return this._http.get<Page<Voucher>>('api/voucher/certificate/search', {params: params.toHttpParam()});
  }

  checkStatusIsRenew(condition: VoucherSearchRequest): Observable<boolean> {
    let params = IgnoreNullHttpParams.fromObject(condition)
    return this._http.get<boolean>('api/voucher/check-status-is-renew', {params: params.toHttpParam()});
  }

  getAllAuditPaginationByOwner(page): Observable<Page<Audit>> {
    let params = new IgnoreNullHttpParams()
      .append("page", page)
      .append("size", 10)
    return this._http.get<Page<Audit>>('api/audit/owner', {params: params.toHttpParam()});
  }

  getAllAuditAnimalFeedActGroupOther(page, auditRef): Observable<Page<Audit>> {
    let params = new IgnoreNullHttpParams()
      .append("auditRef", auditRef)
      .append("page", page - 1)
      .append("size", 10)
    return this._http.get<Page<Audit>>('api/audit/act-group-other/', {params: params.toHttpParam()});
  }

  getAllAuditAnimalFeedActGroupOtherManual(page, ownerGroup, auditRef): Observable<Page<Audit>> {
    let params = new IgnoreNullHttpParams()
      .append("auditRef", auditRef)
      .append("ownerGroup", ownerGroup)
      .append("page", page - 1)
      .append("size", 10)
    return this._http.get<Page<Audit>>('api/audit/act-group-other/manual', {params: params.toHttpParam()});
  }

  //Audit  สำเนาหรือรูปถ่ายฉลาก
  getAuditFormPurpose(): Observable<AuditFormPurpose[]> {
    this.auditFormPurposes = this.auditFormPurposes || this._http.get<AuditFormPurpose[]>('api/AuditForm1/get-purposes')
      .pipe(shareReplay(1), first());
    return this.auditFormPurposes;
  }

  feeRates(): Observable<FeeRate[]> {
    return this._http.get<FeeRate[]>('api/fee-rate');
  }

  getAllProductType(): Observable<ProductType[]> {
    return this._http.get<ProductType[]>('public/api/product-type');
  }

  getAllTransportTypes(): Observable<TransportType[]> {
    return this._http.get<TransportType[]>('public/api/transport-type');
  }

  feedProperties(): Observable<FeedProperties[]> {
    return this._http.get<FeedProperties[]>('api/feed-properties');
  }

  feedWarnings(): Observable<FeedWarning[]> {
    return this._http.get<FeedWarning[]>('api/feed-warning');
  }

  feedHowtoList(): Observable<FeedHowTo[]> {
    return this._http.get<FeedHowTo[]>('api/feed-how-to');
  }

  getAllScopeOfCertPagination(request: ScopeOfCertificationSearchRequest,
                              pageNumber: number, pageSize: number = 10): Observable<Page<ScopeOfCertification>> {
    let params = IgnoreNullHttpParams.fromObject(request)
      .set("page", pageNumber)
      .set("size", pageSize);
    return this._http.get<Page<ScopeOfCertification>>('api/scope-certification', {params: params.toHttpParam()});
  }

  findOfficerCommentByLicensingFormId(id: number): Observable<string> {
    return this._http.get(`api/officer-comment/licensing/${id}`, {responseType: 'text'});
  }

  findOfficerComment(formType: FormType, noAppointment: boolean): Observable<string> {
    return this._http.get<OfficerComment>(`api/officer-comment/${formType}/no-appointment/${noAppointment}`).pipe(map(e => e.comment));
  }


  requestInspectionAnimalFeedTypes() {
    return this._http.get<AnimalFeedType[]>(`api/animal-feed-type/RequestInspection`);
  }

  findAllProvincePort() {
    return this._http.get<Province[]>(`public/api/province/port`);
  }
}

