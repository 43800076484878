import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {HttpValidationService} from "../services/http-validation.service";

@Injectable()
export class Http400Interceptor implements HttpInterceptor {

  constructor(private _validatorService: HttpValidationService,
              private _toastService: ToastrService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
      .pipe(tap
        (event => {
          this._validatorService.clear()
        }, (err: HttpErrorResponse) => {
          if (err.status == 400) {
            this._toastService.warning("โปรดตรวจสอบความถูกต้อง");
            if (err.error instanceof Blob) {
              const reader = new FileReader();
              reader.onload = e => {
                const jsonError = {...err, error: JSON.parse((<any>e.target).result)};
                this._validatorService.error.next(jsonError)
              };
              reader.readAsText(err.error, 'utf-8');
            } else {
              this._validatorService.error.next(err)
            }
          }
        })
      );
  }


}
