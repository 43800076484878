import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {CompanyBranch, CompanyType, Nation, RequestShipping, RequestUser, Shipping, Title} from "../../../../../../../../generated-model/model";
import {Observable} from "rxjs";
import {share} from "rxjs/operators";
import {untilDestroyed} from "ngx-take-until-destroy";
import {ToastrService} from "ngx-toastr";
import {MasterService} from "../../../../../../../shareds/service/master.service";
import {LoadingService} from "../../../../../../../shareds/loading.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {RequestShippingService} from "./services/request-shipping.service";
import {compareWithId} from '../../../../../../../comparable';
import {getCurrentDateStruct} from "../../../../../../../_app_constant";

@Component({
  selector: 'app-add-request-shipping-modal',
  templateUrl: './add-request-shipping-modal.component.html',
  styleUrls: ['./add-request-shipping-modal.component.css'],
  providers: [RequestShippingService]
})
export class AddRequestShippingModalComponent implements OnInit, OnDestroy {

  titles: Title[];
  nations: Nation[];
  companyBranches$: Observable<CompanyBranch[]>;
  titles$: Observable<Title[]>;
  nations$: Observable<Nation[]>;
  companyTypes$: Observable<CompanyType[]>;
  onSave: EventEmitter<Shipping> = new EventEmitter<Shipping>();
  compareWithId = compareWithId;
  currentDateStruct = getCurrentDateStruct();

  @Input()
  requestShipping: Partial<RequestShipping> = {
    personType: 'PERSONAL',
    identityCard: {
      cardType: 'ID_CARD'
    },
    contactAddress: {},
    requestShippingCompany: {}
  };

  @Input()
  state: 'ADD' | 'DETAILS' | 'UPDATE' = 'ADD';

  @Input()
  readonly: boolean = false;

  @Input()
  requestUser: RequestUser;

  constructor(private _toastr: ToastrService,
              private _masterService: MasterService,
              private _service: RequestShippingService,
              public loadingService: LoadingService,
              public activeModal: NgbActiveModal) {

    this.titles$ = this._masterService.titleNames().pipe(untilDestroyed(this), share());
    this.nations$ = this._masterService.nations().pipe(untilDestroyed(this), share());
    this.companyTypes$ = this._masterService.companyTypes().pipe(untilDestroyed(this), share());
    this.companyBranches$ = this._masterService.companyBranches().pipe(untilDestroyed(this), share());
  }

  ngOnInit(): void {

    if (!this.requestUser) {
      throw new Error("requestUser cannot be null.");
    }
  }

  personTypeChange() {

    if (this.requestShipping.personType === "COMPANY") {
      this.requestShipping.requestShippingCompany = {};
    } else {
      this.requestShipping.requestShippingCompany = undefined;
    }
  }

  save() {

    this._service.save(this.requestShipping, this.requestUser)
      .subscribe(shipping => {
        console.log('shipping = ', shipping);
        this.onSave.emit(shipping);
        this.activeModal.dismiss();
      });
  }

  identityCard_cardTambonChange() {

    if (this.requestShipping.identityCard.cardTambon) {
      this.requestShipping.identityCard.cardPostalCode = this.requestShipping.identityCard.cardTambon.postalCode;
    } else {
      this.requestShipping.identityCard.cardPostalCode = undefined;
    }
  }

  nationChanges(nation: Nation) {

    //nation_id (1) == ไทย
    if (!!nation && !(nation.id == 1)) {
      this.requestShipping.identityCard.cardType = "PASSPORT";
    } else {
      this.requestShipping.identityCard.cardType = "ID_CARD";
    }
  }

  identityCardExpireChange() {

    if (this.requestShipping.identityCard.noneExpire === true) {
      this.requestShipping.identityCard.expireDate = undefined;
    }
  }

  identityCardTypeChange() {

    this.requestShipping.identityCard.noneExpire = undefined;
    this.requestShipping.identityCard.issueDate = undefined;
    this.requestShipping.identityCard.cardAddress = undefined;
    this.requestShipping.identityCard.cardMoo = undefined;
    this.requestShipping.identityCard.cardSoi = undefined;
    this.requestShipping.identityCard.cardStreet = undefined;
    this.requestShipping.identityCard.cardTambon = undefined;
  }

  contactAddress_contactTambonChange() {

    if (this.requestShipping.contactAddress.contactTambon) {
      this.requestShipping.contactAddress.contactPostalCode = this.requestShipping.contactAddress.contactTambon.postalCode;
    } else {
      this.requestShipping.contactAddress.contactPostalCode = undefined;
    }
  }

  companyTambonChange() {

    if (this.requestShipping.requestShippingCompany.tambon) {
      this.requestShipping.requestShippingCompany.postalCode = this.requestShipping.requestShippingCompany.tambon.postalCode;
    } else {
      this.requestShipping.requestShippingCompany.postalCode = undefined;
    }
  }

  onCompanyBranchChange(companyBranch: CompanyBranch) {

    this.requestShipping.requestShippingCompany.branch = companyBranch?.branch;
    this.requestShipping.requestShippingCompany.branchDescription = companyBranch?.branchName;
  }

  copyCardAddressToContactAddress() {

    if (!this.requestShipping.contactAddress) {
      this.requestShipping.contactAddress = {};
    }
    this.requestShipping.contactAddress.contactAddress = this.requestShipping.identityCard?.cardAddress;
    this.requestShipping.contactAddress.contactMoo = this.requestShipping.identityCard?.cardMoo;
    this.requestShipping.contactAddress.contactSoi = this.requestShipping.identityCard?.cardSoi;
    this.requestShipping.contactAddress.contactStreet = this.requestShipping.identityCard?.cardStreet;
    this.requestShipping.contactAddress.contactTambonId = this.requestShipping.identityCard?.cardTambon?.id;
    this.requestShipping.contactAddress.contactTambon = this.requestShipping.identityCard?.cardTambon;
    this.requestShipping.contactAddress.contactPostalCode = this.requestShipping.identityCard?.cardPostalCode;
    this.requestShipping.contactAddress.contactTel = this.requestShipping.identityCard?.cardTel;
  }

  ngOnDestroy(): void {
  }
}
