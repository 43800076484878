import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GtDiffComponent} from "./gt-diff.component";
import {GtDiffDirective} from "./gt-diff.directive";
import {PopoverModule} from "ngx-bootstrap/popover";


@NgModule({
  declarations: [
    GtDiffComponent,
    GtDiffDirective
  ],
  imports: [
    CommonModule,
    PopoverModule.forRoot()
  ],
  exports: [
    GtDiffComponent,
    GtDiffDirective
  ],
  entryComponents: [
    GtDiffComponent
  ]
})
export class GtDiffModule { }

