import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {NgSelectComponent} from "@ng-select/ng-select";
import {HttpClient} from "@angular/common/http";
import {concat, Observable, of, Subject} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from "rxjs/operators";
import {untilDestroyed} from "ngx-take-until-destroy";
import {Country, Port} from "../../../../generated-model/model";
import {IgnoreNullHttpParams} from "../../Ignore-null-http-params";

@Component({
    selector: 'app-gt-port-autocomplete',
    templateUrl: './gt-port-autocomplete.component.html',
    styleUrls: ['./gt-port-autocomplete.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: GtPortAutocompleteComponent,
            multi: true
        }
    ],
})
export class GtPortAutocompleteComponent implements ControlValueAccessor, OnInit {

    @Input()
    id: string;

    @Input()
    name: string;

    @Input()
    country: Country;

    @Input()
    validateField: string;

    @Input()
    readonly: boolean = false;

    @Input()
    isThai: boolean = true;

    @ViewChild("portSelect", {static: false, read: NgSelectComponent})
    portSelect: NgSelectComponent;

    _value = [];

    portList$: Observable<Port[]>;
    term$ = new Subject<string>();
    typeaheadLoading = false;

    onChangeCallBack: (_: any) => void = () => {
    };
    onTouchCallBack: () => void = () => {
    };

    constructor(private _http: HttpClient) {
    }


    ngOnInit(): void {

        this.portList$ = concat(
            of([]), // default items
            this.term$.pipe(
                debounceTime(300),
                // distinctUntilChanged(),
                tap(() => this.typeaheadLoading = true),
                switchMap(term => {
                    return this.search(term)
                        .pipe(
                            catchError(() => of([])), // empty list on error
                            tap(() => this.typeaheadLoading = false),
                            untilDestroyed(this)
                        );
                })
            )
        );
    }

    registerOnChange(fn: any): void {
        this.onChangeCallBack = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchCallBack = fn;
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    onModelChange(item: string) {
        this.value = item;
    }

    public clear() {

        if (!!this.portSelect) {
            this.portSelect.handleClearClick();
            this.portSelect.blur();
            this.term$.next('');
        }
    }

    onSelectOpen() {

        this.term$.next('');
    }

    set value(val: any) {
        this._value = val;
        this.onChangeCallBack(val);
        this.onTouchCallBack();
    }

    get value(): any {
        return this._value;
    }

    ngOnDestroy(): void {

    }

    search(term: string): Observable<Port[]> {
        return this._http.get<Port[]>(`api/port/en/search`,
            {params: IgnoreNullHttpParams.fromObject({term: term, countryCode: this.country?.commonName}).toHttpParam()}
        );
    }
}
