import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoadingService} from "../../../loading.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {AuthenService} from "../../../../authen.service";
import {from, Observable, of} from "rxjs";
import {PasswordP12DialogComponent} from "../save-form-dialog/save-form-dialog.component";
import {IgnoreNullHttpParams} from "../../../Ignore-null-http-params";
import {ReportCondition} from "../../../../../generated-model/model";
import {isSafari} from "../../../../_app_constant";

@Component({
  selector: 'app-pdf-preview-with-sign',
  templateUrl: './pdf-preview-with-sign.component.html',
  styleUrls: ['./pdf-preview-with-sign.component.css']
})
export class PdfPreviewWithSignComponent<T> implements OnInit {
  innerHTML: SafeHtml;
  @Input()
  ref: string
  @Input()
  saveAndSign?: (password: string) => Observable<T> = () => of(undefined);
  @Input()
  navigateUrlAfterSave: string[];
  @Input()
  title: string = 'Sign เอกสาร';
  @Input()
  saveButtonText: string = 'Sign เอกสาร';
  @Input()
  signable = true
  @Input()
  watermarkCopy = false
  @Input()
  params: ReportCondition = {};
  @Input()
  readonly: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              public modalService: NgbModal,
              public loadingService: LoadingService,
              private _domSanitizer: DomSanitizer,
              private _toastrService: ToastrService,
              private router: Router,
              private activateRoute: ActivatedRoute,
              private _http: HttpClient,
              public authenService: AuthenService) {

  }

  ngOnInit(): void {
    this.srcUrl();
  }

  private srcUrl() {
    console.log('isSafari? ', isSafari);

    this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(
      `<div style="min-height: 750px; width: 100%;">
          <div style="vertical-align: middle; margin-top: auto; margin-bottom: auto;">
            <span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true" style="vertical-align: middle;"></span>
            <span>กำลังสร้างเอกสารประกอบคำขอ...</span>
          </div>
        </div>`
    );
    let blobObservable
    let ignoreNullHttpParams = IgnoreNullHttpParams.fromObject({});
    if (this.watermarkCopy) {
      ignoreNullHttpParams.append('watermarkCopy', 'true').toHttpParam()
      blobObservable = this._http.get(`public/api/file-upload/${this.ref}/preview`, {responseType: "blob", params: ignoreNullHttpParams.toHttpParam()});
    } else {

      //TODO #1786 #1821
      if (this.ref) {
        ignoreNullHttpParams.append('watermarkCopy', 'false').toHttpParam()
        blobObservable = this._http.get(`public/api/file-upload/${this.ref}/preview`, {responseType: "blob", params: ignoreNullHttpParams.toHttpParam()});
      } else {
        blobObservable = this._http.post(`api/report/java-bean`, this.params, {responseType: "blob"})
      }
    }
    blobObservable.subscribe(e => {
      let reader = new FileReader();
      reader.readAsDataURL(e);
      reader.onloadend = () => {
        let base64data = reader.result;
        this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(
          `<iframe src="${base64data}"
              class='embed-responsive-item'
              style="min-height: 750px; width: 100%;">
            </iframe>`
        );

        reader.onerror = (error) => {

          console.log('reader error = ', error);
          this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(
            `<div style="min-height: 750px; width: 100%;">
              <div style="vertical-align: middle; margin-top: auto; margin-bottom: auto;">
                <strong>เกิดข้อผิดพลาดในการสร้างเอกสาร</strong>
              </div>
            </div>`);
        }
      }
    }, error => {
      console.log('server error = ', error)
      this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(`<div style="min-height: 750px; width: 100%;">
          <div style="vertical-align: middle; margin-top: auto; margin-bottom: auto;">
            <strong>เกิดข้อผิดพลาดในการสร้างเอกสาร</strong>
          </div>
        </div>`);
    });
  }

  forwardSaveAndSign() {

    const modalRef = this.modalService.open(PasswordP12DialogComponent, {
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });
    from(modalRef.result)
      .subscribe(e => {
        if (e) {
          this.saveAndSign(e)
            .subscribe(value => {
              this._toastrService.success("ส่งเรื่องเรียบร้อยแล้ว");
              this.activeModal.close(true);
              if (this.navigateUrlAfterSave) {
                this.router.navigate(this.navigateUrlAfterSave, {relativeTo: this.activateRoute});
              }
            });
        } else {
          console.log('denied.');
        }
      }, error => error);
  }

}
