import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenService} from "./authen.service";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {OFFICER_AUTHORITIES, REGISTERED_USER_AUTHORITIES} from "./_app_constant";

@Injectable({
  providedIn: 'root'
})
export class ApplicantGuard implements CanActivate {

  constructor(private _authenService: AuthenService,
              private _http: HttpClient,
              private _router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    console.log('do user guard');
    return this._authenService.auth$
      .pipe(
        map(e => {
          console.log('user = ', e);
          let permit = !!this._authenService.hasAnyAuthority(e.user, [...REGISTERED_USER_AUTHORITIES]);
          if (permit) {
            return true;
          } else {
            this._router.navigate(['403']);
            return false;
          }
        })
      );
  }

}
