import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {RequestRegisterForm} from "../../../../../../generated-model/model";
import {Observable} from "rxjs";
import {RequestRegisterFormService} from "./request-register-form.service";

@Injectable()
export class RequestDetailsResolverService implements Resolve<RequestRegisterForm> {

  constructor(private _service: RequestRegisterFormService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RequestRegisterForm> | Promise<RequestRegisterForm> | RequestRegisterForm {

    let id = Number(route.paramMap.get('requestId'));
    return this._service.findRequestUserDetailsById(id);
  }
}
