import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {untilDestroyed} from "ngx-take-until-destroy";
import {Nation, RequestEntrepreneur, RequestUser, Title} from "../../../../../../../../generated-model/model";
import {LoadingService} from "../../../../../../../shareds/loading.service";
import {MasterService} from "../../../../../../../shareds/service/master.service";
import {RequestEntrepreneurService} from "./services/request-entrepreneur.service";
import {share} from "rxjs/operators";
import {compareWithId} from '../../../../../../../comparable';
import {getCurrentDateStruct} from "../../../../../../../_app_constant";

@Component({
  selector: 'app-add-request-entrepreneur-modal',
  templateUrl: './add-request-entrepreneur-modal.component.html',
  styleUrls: ['./add-request-entrepreneur-modal.component.css'],
  providers: [RequestEntrepreneurService]
})
export class AddRequestEntrepreneurModalComponent implements OnInit, OnDestroy {

  titles$: Observable<Title[]>;
  nations$: Observable<Nation[]>;
  compareWithId = compareWithId;
  currentDateStruct = getCurrentDateStruct();

  @Input()
  state: 'ADD' | 'DETAILS' | 'UPDATE' = 'ADD';

  @Input()
  entrepreneur: Partial<RequestEntrepreneur> = {
    identityCard: {cardType: "ID_CARD"},
    files: {
      'ID_CARD_COPY': {fileType: 'ID_CARD_COPY'},
      'PASSPORT_COPY': {fileType: 'PASSPORT_COPY'},
      'ENTREPRENEUR_ASSIGNMENT': {fileType: 'ENTREPRENEUR_ASSIGNMENT'}
    },
    contactAddress: {}
  };
  onSave: EventEmitter<RequestEntrepreneur> = new EventEmitter<RequestEntrepreneur>();

  @Input()
  requestUser: RequestUser;

  constructor(private _service: RequestEntrepreneurService,
              private _masterService: MasterService,
              public loadingService: LoadingService,
              public activeModal: NgbActiveModal) {

  }

  ngOnInit(): void {

    this.titles$ = this._masterService.titleNames().pipe(untilDestroyed(this), share());
    this.nations$ = this._masterService.nations().pipe(untilDestroyed(this), share());
    if (!this.requestUser) {
      throw new Error("requestUser cannot be null.");
    }
  }

  save() {

    this._service.save(this.requestUser, this.entrepreneur)
      .subscribe(value => {
        this.onSave.emit(value);
        this.activeModal.dismiss();
      });
  }

  update() {

    this._service.update(this.requestUser, this.entrepreneur)
      .subscribe(value => {
        this.onSave.emit(value);
        this.activeModal.dismiss();
      });
  }

  nationChanges(nation: Nation) {

    //nation_id (1) == ไทย
    if (nation?.id != 1) {
      this.entrepreneur.identityCard.cardType = "PASSPORT";
    } else {
      this.entrepreneur.identityCard.cardType = 'ID_CARD';
    }
  }

  identityCardTypeChange() {

    this.entrepreneur.identityCard.noneExpire = undefined;
    this.entrepreneur.identityCard.issueDate = undefined;
    this.entrepreneur.identityCard.cardAddress = undefined;
    this.entrepreneur.identityCard.cardMoo = undefined;
    this.entrepreneur.identityCard.cardSoi = undefined;
    this.entrepreneur.identityCard.cardStreet = undefined;
    this.entrepreneur.identityCard.cardTambon = undefined;
  }

  identityCardExpireChange() {

    if (this.entrepreneur.identityCard.noneExpire === true) {
      this.entrepreneur.identityCard.expireDate = undefined;
    }
  }

  identityCard_cardTambonChange() {

    if (this.entrepreneur.identityCard.cardTambon) {
      this.entrepreneur.identityCard.cardPostalCode = this.entrepreneur.identityCard.cardTambon.postalCode;
    } else {
      this.entrepreneur.identityCard.cardPostalCode = undefined;
    }
  }

  contactAddress_contactTambonChange() {

    if (this.entrepreneur.contactAddress.contactTambon) {
      this.entrepreneur.contactAddress.contactPostalCode = this.entrepreneur.contactAddress.contactTambon.postalCode;
    } else {
      this.entrepreneur.contactAddress.contactPostalCode = undefined;
    }
  }

  copyCardAddressToContactAddress() {

    if (!this.entrepreneur.contactAddress) {
      this.entrepreneur.contactAddress = {};
    }
    this.entrepreneur.contactAddress.contactAddress = this.entrepreneur.identityCard?.cardAddress;
    this.entrepreneur.contactAddress.contactMoo = this.entrepreneur.identityCard?.cardMoo;
    this.entrepreneur.contactAddress.contactSoi = this.entrepreneur.identityCard?.cardSoi;
    this.entrepreneur.contactAddress.contactStreet = this.entrepreneur.identityCard?.cardStreet;
    this.entrepreneur.contactAddress.contactTambonId = this.entrepreneur.identityCard?.cardTambon?.id;
    this.entrepreneur.contactAddress.contactTambon = this.entrepreneur.identityCard?.cardTambon;
    this.entrepreneur.contactAddress.contactPostalCode = this.entrepreneur.identityCard?.cardPostalCode;
    this.entrepreneur.contactAddress.contactTel = this.entrepreneur.identityCard?.cardTel;
  }

  ngOnDestroy(): void {
  }
}
