import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {LoadingService} from "../../loading.service";
import {untilDestroyed} from "ngx-take-until-destroy";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  constructor(public loadingService: LoadingService,
              private _renderer: Renderer2) { }

  ngOnInit() {

    this.loadingService
      .loadingState
      .pipe(
        finalize(() => this.complete()),
        untilDestroyed(this)
      )
      .subscribe(state => {
        if(!!state) {
          this.loading();
        } else {
          this.complete();
        }
      });
  }

  loading() {
    this._renderer.addClass(document.body, 'app-loading');
  }

  complete() {
    this._renderer.removeClass(document.body, 'app-loading');
  }

  ngOnDestroy(): void {
  }

}
