import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {AuthenService} from "./authen.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class AuthenGuard implements CanActivate {

  constructor(private authenService: AuthenService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.authenService.auth$
      .pipe(
        map(auth => {
          if (!!auth) {
            return true;
          } else {
            this.router.navigate(['403']);
            return false;
          }
        })
      );
  }
}
