import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-login-attempts-dialog',
  templateUrl: './login-attempts-dialog.component.html',
  styleUrls: ['./login-attempts-dialog.component.css']
})
export class LoginAttemptsDialogComponent implements OnInit, OnDestroy {

  constructor(public activeModal: NgbActiveModal,
              public modalService: NgbModal) {
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
  }
}
