import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Import Containers
import {DefaultLayoutComponent} from './containers';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {LoginComponent} from './views/login/login.component';
import {AuthenGuard} from "./authen.guard";
import {OfficerGuard} from "./officer-guard.service";
import {UserGuard} from "./user-guard";
import {P403Component} from "./views/error/403.component";
import {LoginGuard} from "./login-guard";
import {HoverPreloadStrategy} from "ngx-hover-preload";
import {ApplicantGuard} from "./applicant-guard";
import {FileUploadPatcherComponent} from "./views/file-upload-patcher/file-upload-patcher.component";

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '403',
    component: P403Component,
    data: {
      title: 'Page 403'
    }
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
    data: {
      title: 'Login Page'
    },
    runGuardsAndResolvers: "always"
  },
  {
    path: 'portal',
    canActivate: [ApplicantGuard],
    loadChildren: () => import('./views/portal/portal.module').then(m => m.PortalModule),
  },
  {
    path: 'officer/portal',
    canActivate: [OfficerGuard],
    loadChildren: () => import('./views/officer/portal/portal.module').then(m => m.PortalModule),
  },
  {
    path: 'public',
    loadChildren: () => import('./views/public/public.module').then(m => m.PublicModule),
  },
  {
    path: 'file-upload-patcher',
    component: FileUploadPatcherComponent
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'officer',
        canActivate: [OfficerGuard],
        loadChildren: () => import('./views/officer/officer.module').then(m => m.OfficerModule)
      },
      {
        path: 'licensing',
        canActivate: [UserGuard],
        loadChildren: () => import('./views/licensing/licensing.module').then(m => m.LicensingModule),
      },
      {
        path: 'voucher',
        canActivate: [UserGuard],
        loadChildren: () => import('./views/voucher/voucher.module').then(m => m.VoucherModule),
      },
      {
        path: 'certificate',
        canActivate: [UserGuard],
        loadChildren: () => import('./views/certificate/certificate.module').then(m => m.CertificateModule),
      },
      {
        path: 'ns4',
        canActivate: [UserGuard],
        loadChildren: () => import('./views/ns4/ns4.module').then(m => m.Ns4Module),
      },
      {
        path: 'gmp-haccp',
        canActivate: [UserGuard],
        loadChildren: () => import('./views/gmp-haccp/gmp-haccp.module').then(m => m.GmpHaccpModule),
      },
      {
        path: 'audit',
        canActivate: [UserGuard],
        loadChildren: () => import('./views/audit/audit.module').then(m => m.AuditModule),
      },
      {
        path: 'request-inspection',
        canActivate: [UserGuard],
        loadChildren: () => import('./views/request-inspection/request-inspection.module').then(m => m.RequestInspectionModule),
      },
    ],
    canActivate: [AuthenGuard],
    runGuardsAndResolvers: "always"
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy',
    preloadingStrategy: HoverPreloadStrategy
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
