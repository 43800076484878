import {Injectable} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {ReplaySubject} from "rxjs";

@Injectable({providedIn: "root"})
export class HttpValidationService {
  error = new ReplaySubject<HttpErrorResponse>(1);

  constructor() {
  }

  clear() {
    this.error.next(null)
  }
}
