import {AfterContentInit, AfterViewInit, Directive, DoCheck, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[appAppFormCorrectionContainer]',
  exportAs: 'correctionContainer'
})
export class AppFormCorrectionContainerDirective implements OnInit, DoCheck, AfterViewInit, AfterContentInit {

  private valueBeforeChanges: any[] = [];
  private _condition: boolean;

  @Output("onResetCorrect")
  output: EventEmitter<any[]> = new EventEmitter<any[]>();

  constructor() {
  }

  ngOnInit(): void {

  }

  ngDoCheck(): void {
  }

  get condition(): boolean {
    return this._condition;
  }

  @Input("appAppFormCorrectionContainer")
  set condition(value: boolean) {
    if (!value && this.valueBeforeChanges?.length) {
      this.output.emit(this.valueBeforeChanges);
    }
    this._condition = value;
  }

  get state(): any[] {
    return this.valueBeforeChanges;
  }

  @Input("correctionState")
  set state(value: any[]) {

    if (!this.valueBeforeChanges?.length) {
      this.valueBeforeChanges = JSON.parse(JSON.stringify(value));
    }
  }

  ngAfterViewInit(): void {

  }

  ngAfterContentInit(): void {

  }
}
