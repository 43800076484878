import {Component} from '@angular/core';

@Component({
  templateUrl: '500.component.html'
})
export class P500Component {

  constructor() {
  }

  redirect() {

  }
}
