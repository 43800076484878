import {Injectable} from '@angular/core';
import {FileUpload} from "../../../../generated-model/model";
import {Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";

export const UPLOAD_URL = 'api/banktransfer/upload-text-file';

@Injectable()
export class BankTransferFileUpload {

  constructor(private _http: HttpClient) {
  }

  delete(file: FileUpload | any): Observable<void> {

    if (!!file) {
      return this._http.post<void>(`${UPLOAD_URL}/delete`, file);
    }
    return of();
  }
}
