import {Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, mergeMap} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {IgnoreNullHttpParams} from "../../Ignore-null-http-params";
import {Page, TambonWithFullName} from "../../../../generated-model/model";

@Component({
  selector: 'gt-amphur',
  templateUrl: './gt-amphur.component.html',
  styleUrls: ['./gt-amphur.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GtAmphurComponent),
      multi: true
    }
  ]

})
export class GtAmphurComponent implements OnInit, ControlValueAccessor {

  @Input()
  disabled: boolean;
  @Input()
  placeholder: string = "พิมพ์ชื่อ อำเภอ/เขต จังหวัด";


  isSelect: boolean;
  propagateChangeFn = (_: any) => {
  };
  data: Page<TambonWithFullName>;
  text: string;
  value: TambonWithFullName;
  term$ = new Subject<string>();

  @Output()
  private selected = new EventEmitter<any>();
  @Output("clear")
  private clearEvent = new EventEmitter<any>();

  flag: boolean;

  hasError = false;

  constructor(private http: HttpClient, private eRef: ElementRef) {
  }

  ngOnInit(): void {
    this.term$.pipe(debounceTime(300), distinctUntilChanged(), mergeMap(term => this.search(term)))
      .subscribe(e => this.data = e)


  }

  search(term: string) {
    let params = new IgnoreNullHttpParams({excludeValues: [undefined, null, '']})
      .set('term', term);
    return this.http.get<Page<TambonWithFullName>>(`public/api/amphur/search-full`, {params: params.toHttpParam()})
  }


  registerOnChange(fn: any): void {
    this.propagateChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(value: TambonWithFullName): void {
    if (!!value) {
      if (!value.fullName) {
        value.fullName = value.name + " " + value['province']['name'];
      }
      this.setValue(value);
    }
  }

  clear() {
    this.value = null;
    this.data = null;
    this.text = null;
    this.clearEvent.emit(this.value);
    this.isSelect = false;
    this.propagateChangeFn(this.value);
  }


  select(value: TambonWithFullName) {
    this.setValue(value);
    this.selected.emit(value);
    this.isSelect = true;
    this.propagateChangeFn(this.value);

  }

  setValue(value: TambonWithFullName) {
    this.value = value;
    this.data = null;
    this.text = value.fullName;
  }


  error() {
    this.hasError = true
  }

  clearError() {
    this.hasError = false
  }
}
