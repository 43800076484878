import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RequestBasicInfoComponent} from "./components/basic-info/request-basic-info.component";
import {ManageRequestUsersComponent} from "./components/manage-users/manage-request-users.component";
import {RequestEmployeeDetailsModalComponent} from "./components/manage-users/employee-details-modal/request-employee-details-modal.component";
import {AddRequestAgentModalComponent} from "./components/manage-users/add-agent-modal/add-request-agent-modal.component";
import {AddRequestShippingModalComponent} from "./components/shipping/add-shipping-modal/add-request-shipping-modal.component";
import {SharedsModule} from "../../../../shareds/shareds.module";
import {FormsModule} from "@angular/forms";
import {DigitOnlyModule} from "@uiowa/digit-only";
import {RequestRegisterFormService} from "./services/request-register-form.service";
import {RequestDetailsResolverService} from "./services/request-details-resolver.service";
import {ManageRequestUsersResolverService} from "./services/manage-request-users-resolver.service";
import {RequestShippingResolverService} from "./services/request-shipping-resolver.service";
import {ManageRequestUsersService} from "./components/manage-users/services/manage-request-users.service";
import {AuthorizedResolverService} from "./services/authorized-resolver.service";
import {AddRequestAttorneyModalComponent} from "./components/manage-users/add-attorney-modal/add-request-attorney-modal.component";
import {AddRequestEntrepreneurModalComponent} from "./components/manage-users/add-entrepreneur-modal/add-request-entrepreneur-modal.component";
import {RequestAgentDetailsModalComponent} from "./components/manage-users/agent-details-modal/request-agent-details-modal.component";
import {ManageRequestShippingComponent} from "./components/shipping/manage-request-shipping.component";


@NgModule({
  declarations: [
    RequestBasicInfoComponent,
    ManageRequestUsersComponent,
    ManageRequestShippingComponent,
    RequestEmployeeDetailsModalComponent,
    RequestAgentDetailsModalComponent,
    AddRequestAgentModalComponent,
    AddRequestShippingModalComponent,
    AddRequestAttorneyModalComponent,
    AddRequestEntrepreneurModalComponent
  ],
  exports: [
    RequestBasicInfoComponent,
    ManageRequestUsersComponent,
    ManageRequestShippingComponent,
    RequestEmployeeDetailsModalComponent,
    RequestAgentDetailsModalComponent,
    AddRequestAgentModalComponent,
    AddRequestShippingModalComponent,
    AddRequestAttorneyModalComponent,
    AddRequestEntrepreneurModalComponent
  ],
  imports: [
    CommonModule,
    SharedsModule,
    FormsModule,
    DigitOnlyModule
  ],
  entryComponents: [
    RequestEmployeeDetailsModalComponent,
    RequestAgentDetailsModalComponent,
    AddRequestAgentModalComponent,
    AddRequestShippingModalComponent,
    AddRequestAttorneyModalComponent
  ],
  providers: [
    RequestRegisterFormService,
    RequestDetailsResolverService,
    ManageRequestUsersResolverService,
    RequestShippingResolverService,
    ManageRequestUsersService,
    AuthorizedResolverService
  ]
})
export class RequestSharedModule { }
