import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CompanyBranch, CompanyType, createRequestUserFileType, Nation, PerformType, RequestUser, Title} from "../../../../../../../generated-model/model";
import {merge, Observable, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, mergeMap, share, tap} from "rxjs/operators";
import {untilDestroyed} from "ngx-take-until-destroy";
import {MasterService} from "../../../../../../shareds/service/master.service";
import {compareWithId} from '../../../../../../comparable';
import {getCurrentDateStruct} from "../../../../../../_app_constant";

@Component({
  selector: 'app-register-basic-info',
  templateUrl: './request-basic-info.component.html',
  styleUrls: ['./request-basic-info.component.css']
})
export class RequestBasicInfoComponent implements OnInit, OnDestroy {

  titles$: Observable<Title[]>;
  nations$: Observable<Nation[]>;
  companyTypes$: Observable<CompanyType[]>;
  compareWithId = compareWithId;
  companyCorporateNumber = new Subject();
  companyBranches$;
  currentDateStruct = getCurrentDateStruct();

  @Input()
  requestUser: RequestUser = {
    personType: 'PERSONAL',
    identityCard: {cardType: 'ID_CARD'},
    contactAddress: {}
  };

  @Input()
  readonly: boolean = false;

  @Output()
  requestUserChange: EventEmitter<RequestUser> = new EventEmitter<RequestUser>();

  constructor(public masterService: MasterService) {

    this.titles$ = this.masterService.titleNames().pipe(untilDestroyed(this), share());
    this.nations$ = this.masterService.nations().pipe(untilDestroyed(this), share());
    this.companyTypes$ = this.masterService.companyTypes().pipe(untilDestroyed(this), share());
    this.mapFiles();

    this.companyBranches$ = merge(
      this.companyCorporateNumber.asObservable()
        .pipe(
          filter(e => !!e),
          debounceTime(300),
          distinctUntilChanged(),
          mergeMap((corporateNumber: string) => {
            return this.masterService.findNotExistsCompanyBranchesByByCompanyCorporateNumber(corporateNumber);
          }),
          tap(branches => console.log('company branches = ', branches)),
          untilDestroyed(this),
        ),
      this.masterService.companyBranches()
    );
  }

  ngOnInit(): void {

    if (this.requestUser.personType == "COMPANY") {
      console.log('this.requestUser.company.corporateNumber = ', this.requestUser.company.corporateNumber);
      this.companyCorporateNumber.next(this.requestUser.company.corporateNumber);
    }
  }

  mapFiles() {

    this.requestUser.files = Object.assign(createRequestUserFileType(), JSON.parse(JSON.stringify(this.requestUser.files || {})));
  }

  corporateNumberChange($event: any) {

    this.requestUser.company.branch = undefined;
    this.companyCorporateNumber.next($event);
  }

  nationChanges(nation: Nation) {

    //nation_id (1) == ไทย
    if (!!nation && !(nation.id == 1)) {
      this.requestUser.identityCard.cardType = "PASSPORT";
    } else {
      this.requestUser.identityCard.cardType = "ID_CARD";
    }
  }

  identityCardTypeChange() {

    this.requestUser.identityCard.noneExpire = undefined;
    this.requestUser.identityCard.issueDate = undefined;
    this.requestUser.identityCard.cardAddress = undefined;
    this.requestUser.identityCard.cardMoo = undefined;
    this.requestUser.identityCard.cardSoi = undefined;
    this.requestUser.identityCard.cardStreet = undefined;
    this.requestUser.identityCard.cardTambon = undefined;
  }

  identityCardExpireChange() {

    if (this.requestUser.identityCard.noneExpire === true) {
      this.requestUser.identityCard.expireDate = undefined;
    }
  }

  identityCard_cardTambonChange() {

    if (this.requestUser.identityCard.cardTambon) {
      this.requestUser.identityCard.cardPostalCode = this.requestUser.identityCard.cardTambon.postalCode;
      this.requestUser.addressEn.tambon = this.requestUser.identityCard.cardTambon;
      this.requestUser.addressEn.postalCode = this.requestUser.addressEn.tambon.postalCode;
    } else {
      this.requestUser.identityCard.cardPostalCode = undefined;
      this.requestUser.addressEn.tambon = undefined;
      this.requestUser.addressEn.postalCode = undefined;
    }
  }

  contactAddress_contactTambonChange() {

    if (this.requestUser.contactAddress.contactTambon) {
      this.requestUser.contactAddress.contactPostalCode = this.requestUser.contactAddress.contactTambon.postalCode;
    } else {
      this.requestUser.contactAddress.contactPostalCode = undefined;
    }
  }

  companyTambonChange() {

    if (this.requestUser.company.tambon) {
      this.requestUser.company.postalCode = this.requestUser.company.tambon.postalCode;
      this.requestUser.company.addressEn.tambon = this.requestUser.company.tambon;
      this.requestUser.company.addressEn.postalCode = this.requestUser.company.addressEn.tambon.postalCode;
    } else {
      this.requestUser.company.postalCode = undefined;
      this.requestUser.company.addressEn.tambon = undefined;
      this.requestUser.company.addressEn.postalCode = undefined;
    }
  }

  submit() {

  }

  addressTambonChange() {

    if (this.requestUser.addressEn.tambon) {
      this.requestUser.addressEn.postalCode = this.requestUser.addressEn.tambon.postalCode;
    } else {
      this.requestUser.addressEn.postalCode = undefined;
    }
  }

  performTypeChange(performType: PerformType) {

    this.requestUser.files = {
      'ID_CARD_COPY': {fileType: 'ID_CARD_COPY'},
      'PASSPORT_COPY': {fileType: 'PASSPORT_COPY'},
      'ATTORNEY_PERSON_ACCOUNT': {fileType: 'ATTORNEY_PERSON_ACCOUNT'},
      'ATTORNEY_COPY': {fileType: 'ATTORNEY_COPY'},
      'COMPANY_REGISTRATION_COPY': {fileType: 'COMPANY_REGISTRATION_COPY'}
    };
  }

  onCompanyBranchChange(companyBranch: CompanyBranch) {

    this.requestUser.company.branch = companyBranch?.branch;
    this.requestUser.company.branchDescription = companyBranch?.branchName;
  }

  copyCardAddressToContactAddress() {

    if (!this.requestUser.contactAddress) {
      this.requestUser.contactAddress = {};
    }
    this.requestUser.contactAddress.contactAddress = this.requestUser.identityCard?.cardAddress;
    this.requestUser.contactAddress.contactMoo = this.requestUser.identityCard?.cardMoo;
    this.requestUser.contactAddress.contactSoi = this.requestUser.identityCard?.cardSoi;
    this.requestUser.contactAddress.contactStreet = this.requestUser.identityCard?.cardStreet;
    this.requestUser.contactAddress.contactTambonId = this.requestUser.identityCard?.cardTambon?.id;
    this.requestUser.contactAddress.contactTambon = this.requestUser.identityCard?.cardTambon;
    this.requestUser.contactAddress.contactPostalCode = this.requestUser.identityCard?.cardPostalCode;
  }

  ngOnDestroy(): void {
  }
}
