import {Injectable} from '@angular/core';
import {ReplaySubject} from "rxjs";

@Injectable({providedIn: "root"})
export class AppTocService {

  toggler = new ReplaySubject<boolean>(1);
  headings = new ReplaySubject<any>(1);

  constructor() {

  }

  getHeadings(elemRef: Element) {

    let elements = elemRef.getElementsByClassName('app-toc-heading');
    let headings: any[] = [];
    if (elements.length) {
      for (let key in elements) {
        if (typeof elements[key] == 'object') {
          headings.push({
            title: elements[key]['innerText'],
            href: elements[key].id,
            offsetTop: () => this.getOffsetTop(elements[key].id)
          });
        }
      }
      headings.forEach((value, index, array) => {
        if (array[index + 1]) {
          value['offsetBottom'] = () => array[index + 1].offsetTop();
        } else {
          value['offsetBottom'] = () => 9999;
        }
      });
      this.toggler.next(true);
      this.headings.next(headings);
    }
  }

  getOffsetTop(elementId: string): number {

    let targetElement = document.getElementById(elementId);
    return targetElement.getBoundingClientRect().top + window.pageYOffset - 100
  }

  getOffsetBottom(elementId: string): number {

    let targetElement = document.getElementById(elementId);
    return targetElement.getBoundingClientRect().bottom + window.pageYOffset - 100;
  }

  isActive(offsetTop, offsetBottom): boolean {

    return window.pageYOffset >= offsetTop && window.pageYOffset < offsetBottom;
  }

  detach() {
    this.toggler.next(false);
  }
}
