import {Component, Input, OnChanges, SimpleChange} from '@angular/core';

@Component({
  selector: 'app-password-strength-bar',
  templateUrl: './password-strength-bar.component.html',
  styleUrls: ['./password-strength-bar.component.css']
})
export class PasswordStrengthBarComponent implements OnChanges {

  @Input() passwordToCheck: string;
  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  bar4: string;

  idx: number;

  startLength: number = 6;

  private colors = ['#BD3B1B', '#D8A800', '#B9D870', '#B6C61A', '#006344'];

  private static measureStrength(pass: string) {
    let score = 0;
    // award every unique letter until 5 repetitions
    let letters = {};
    for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }
    // bonus points for mixing it up
    let variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    let variationCount = 0;
    for (let check in variations) {
      variationCount += (variations[check]) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    return Math.trunc(score);
  }

  private getColor(score: number) {
    this.idx = 0;
    if (score >= 80) {
      this.idx = 4;
    } else if (score > 70) {
      this.idx = 3;
    } else if (score >= 50) {
      this.idx = 2;
    } else if (score > 30) {
      this.idx = 1;
    }
    console.log(score);
    return {
      idx: this.idx + 1,
      col: this.colors[this.idx]
    };
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    let password = changes['passwordToCheck'].currentValue;
    this.setBarColors(5, '#DDD');
    if (password && this.passwordToCheck.length >= this.startLength) {
      let c = this.getColor(PasswordStrengthBarComponent.measureStrength(password));
      this.setBarColors(c.idx, c.col);
    }
  }

  private setBarColors(count, col) {
    for (let _n = 0; _n < count; _n++) {
      this['bar' + _n] = col;
    }
  }

  getDescription(): string {
    if (this.idx === 0) {
      return 'รหัสผ่านคาดเดาง่ายเกินไป'
    } else if (this.idx === 1) {
      return 'รหัสผ่านยังไม่ค่อยดี';
    } else if (this.idx === 2) {
      return 'รหัสผ่านค่อนข้างดี';
    } else if (this.idx === 3) {
      return 'รหัสผ่านปลอดภัย';
    } else if (this.idx === 4) {
      return 'รหัสผ่านปลอดภัยมาก ทำให้คาดเดาได้ยาก';
    }
    return '';
  }

}
