import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {untilDestroyed} from "ngx-take-until-destroy";
import {Nation, RequestUser, Title} from "../../../../../../../../generated-model/model";
import {LoadingService} from "../../../../../../../shareds/loading.service";
import {MasterService} from "../../../../../../../shareds/service/master.service";
import {share} from "rxjs/operators";
import {compareWithId} from '../../../../../../../comparable';
import {getCurrentDateStruct} from "../../../../../../../_app_constant";

@Component({
  selector: 'app-request-agent-details-modal',
  templateUrl: './request-agent-details-modal.component.html',
  styleUrls: ['./request-agent-details-modal.component.css']
})
export class RequestAgentDetailsModalComponent implements OnInit, OnDestroy {

  titles$: Observable<Title[]>;
  nations$: Observable<Nation[]>;
  compareWithId = compareWithId;
  currentDateStruct = getCurrentDateStruct();

  @Input()
  state: 'DETAILS' = 'DETAILS';

  @Input()
  requestUser: RequestUser;

  constructor(private _masterService: MasterService,
              public loadingService: LoadingService,
              public activeModal: NgbActiveModal) {

  }

  ngOnInit(): void {

    this.titles$ = this._masterService.titleNames().pipe(untilDestroyed(this), share());
    this.nations$ = this._masterService.nations().pipe(untilDestroyed(this), share());
    if (!this.requestUser) {
      throw new Error("requestUser cannot be null.");
    }
  }

  ngOnDestroy(): void {
  }
}
