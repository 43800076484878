import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {IgnoreNullHttpParams} from "../../../../../shareds/Ignore-null-http-params";
import {Observable} from "rxjs";
import {Page, RequestRegisterForm, RequestUser} from "../../../../../../generated-model/model";

@Injectable()
export class RequestRegisterFormService {

    constructor(private _http: HttpClient) {
    }

    public search(params: IgnoreNullHttpParams): Observable<Page<RequestRegisterForm>> {

        return this._http.get<Page<RequestRegisterForm>>('api/request-register-form/search', {params: params.toHttpParam()});
    }

    public findFirstRequestForm(): Observable<RequestRegisterForm> {

        return this._http.get('api/request-register-form/request-authorized');
    }

    public findRequestUserDetailsById(id: number): Observable<RequestRegisterForm> {

        return this._http.get<RequestRegisterForm>(`api/request-register-form/request-user/${id}/details`);
    }

    public updateRequestUserBasicInfo(requestUser: RequestUser): Observable<RequestUser> {

        return this._http.post<RequestUser>(`api/request-register-form/request-user/${requestUser.id}/update`, requestUser);
    }

    public manageUsers(requestUser: RequestUser) {

        return this._http.get(`api/request-register-form/request-user/${requestUser.id}/manage/users`);
    }

    public validateRequestUserBasicInfo(requestUser: RequestUser): Observable<RequestUser> {

        return this._http.post<RequestUser>(`api/request-register-form/request-user/${requestUser.id}/validate-form`, requestUser);
    }

    public validateRequestUserManageUsers(requestUser: RequestUser): Observable<RequestUser> {

        return this._http.post<RequestUser>(`api/request-register-form/request-user/${requestUser.id}/validate-manage-users`, requestUser);
    }

    public validateRequestUserShipping(requestUser: RequestUser): Observable<RequestUser> {

        return this._http.post<RequestUser>(`api/request-register-form/request-user/${requestUser.id}/validate-shipping`, requestUser);
    }

    public sendConsider(requestRegisterForm: RequestRegisterForm) {

        return this._http.post<RequestRegisterForm>(`api/request-register-form/send-consider`, requestRegisterForm);
    }

    public authorized(): Observable<string> {

        return this._http.get('api/request-register-form/authorized', {responseType: "text"});
    }

    public canactivate(): Observable<string> {

        return this._http.get('api/request-register-form/canactivate', {responseType: "text"});
    }

    public deny(form: RequestRegisterForm): Observable<RequestRegisterForm> {

        return this._http.post(`api/request-register-form/${form.id}/deny`, {});
    }
}
