import {Component, OnInit} from '@angular/core';
import {AppBreadcrumbService} from "../../../services/app-breadcrumb.service";

@Component({
  selector: 'gt-app-header',
  templateUrl: './gt-app-header.component.html',
  styleUrls: ['./gt-app-header.component.css']
})
export class GtAppHeader implements OnInit {

  public breadcrumbs;
  constructor(private _appBreadcrumbService: AppBreadcrumbService) { }

  ngOnInit(): void {

    this.breadcrumbs = this._appBreadcrumbService.breadcrumbs;
  }
}
