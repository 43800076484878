import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {CorrectionItem} from "../../../../generated-model/model";

@Injectable()
export class SelectableTitleService {

  public selectTitles: CorrectionItem[] = [];

  public selectTitlesEmit = new Subject<CorrectionItem[]>();

}
