import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {NgSelectComponent} from "@ng-select/ng-select";
import {HttpClient} from "@angular/common/http";
import {concat, Observable, of, Subject} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from "rxjs/operators";
import {untilDestroyed} from "ngx-take-until-destroy";
import {Country, User} from "../../../../generated-model/model";
import {IgnoreNullHttpParams} from "../../Ignore-null-http-params";

@Component({
  selector: 'gt-user-in-group-autocomplete',
  templateUrl: './gt-user-in-group-autocomplete.component.html',
  styleUrls: ['./gt-user-in-group-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GtUserInGroupAutocompleteComponent,
      multi: true
    }
  ],
})
export class GtUserInGroupAutocompleteComponent implements ControlValueAccessor, OnInit {
  @Input()
  id: string;
  @Input()
  name: string;
  @Input()
  validateField: string;
  @Input()
  readonly: boolean = false;
  @Input()
  formState: string;
  @Input()
  isThai: boolean = true;
  @ViewChild("userSelect", {static: true})
  _value = [];
  countryList$: Observable<User[]>;
  term$ = new Subject<string>();
  countrySelect: NgSelectComponent;
  typeaheadLoading = false;

  @Output('clear')
  clearInput = new EventEmitter<any>()

  @Output('change-user')
  changeUser = new EventEmitter<any>()

  onChangeCallBack: (_: any) => void = () => {
  };
  onTouchCallBack: () => void = () => {
  };

  constructor(private _http: HttpClient) {
  }


  ngOnInit(): void {
    this.countryList$ = concat(
      of([]), // default items
      this.term$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.typeaheadLoading = true),
        switchMap(term => {
          return this.search(term)
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => this.typeaheadLoading = false),
              untilDestroyed(this)
            );
        })
      )
    );
  }

  registerOnChange(fn: any): void {
    this.onChangeCallBack = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchCallBack = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onModelChange(item: string) {
    this.value = item;
  }

  public clear() {
    this.clearInput.emit()
    this.countrySelect.handleClearClick();
    this.countrySelect.blur();
  }

  onSelectOpen() {

    this.term$.next('');
  }

  set value(val: any) {
    this._value = val;
    this.onChangeCallBack(val);
    this.onTouchCallBack();
  }

  get value(): any {
    return this._value;
  }

  ngOnDestroy(): void {

  }

  search(term: string): Observable<User[]> {
    return this._http.get<User[]>(`api/officer-assign/same-group/search`,
      {params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()}
    );
  }

  changeEvent($event: any) {
    this.changeUser.emit($event)
  }
}
