// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyBdY8KltPQ73yfPSd7FwQ0qycLYUsh8J0w",
        authDomain: "aquafeed-a2ef2.firebaseapp.com",
        databaseURL: "https://aquafeed-a2ef2.firebaseio.com",
        projectId: "aquafeed-a2ef2",
        storageBucket: "aquafeed-a2ef2.appspot.com",
        messagingSenderId: "614107029152",
        appId: "1:614107029152:web:1503a39bdb71b1452973c5",
        measurementId: "G-FD977Q297C"
    },
    firestoreSchema: 'public',
    firestoreProfile: 'stg',
};
