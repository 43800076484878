import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DomSanitizer} from "@angular/platform-browser";
import {from, Observable, of} from "rxjs";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {ToastrService} from "ngx-toastr";
import {LoadingService} from "../../../loading.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ReportCondition} from "../../../../../generated-model/model";
import {AuthenService} from "../../../../authen.service";

export type SaveFormDialogOption = {
  label: string,
  signDocumentRequire?: boolean,
  styles?: {
    buttonClasses?: string[],
    iconClasses?: string[]
  }
  callback?: (p12password?: string) => Observable<any>,
  redirectUrl?: string[],
  showCondition?: () => Observable<boolean>,
  confirmDialogMessage?: string,
  successAlertMessage?: string,
  authorities?: string[],
  hidden?: boolean
};

@Component({
  selector: 'app-save-form-dialog',
  templateUrl: './save-form-dialog.component.html',
  styleUrls: ['./save-form-dialog.component.css']
})
export class SaveFormDialogComponent<T> implements OnInit, OnDestroy {

  private _options: SaveFormDialogOption[] = [];

  @Input()
  params: ReportCondition = {};

  @Input()
  title: string = 'Sign เอกสาร และบันทึกข้อมูล';

  innerHTML: any;
  objectURL: string;
  certificateInvalidMessage: string;

  constructor(public activeModal: NgbActiveModal,
              public modalService: NgbModal,
              public loadingService: LoadingService,
              private _domSanitizer: DomSanitizer,
              private _toastrService: ToastrService,
              private router: Router,
              private activateRoute: ActivatedRoute,
              private _http: HttpClient,
              public authenService: AuthenService) {
  }

  ngOnInit() {

    if (!this.params && !this.params.className) {
      throw new Error("params & className cannot be null.");
    }
    this.options?.map(async value => {
      if (!!value.showCondition) {
        value.hidden = await value.showCondition().toPromise();
      } else {
        value.hidden = false;
      }
    });
    this.srcUrl();
  }

  private srcUrl() {

    this.params.fileType = "pdf";
    this.params.attachment = false;
    this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(
      `<div style="min-height: 750px; width: 100%;">
          <div style="vertical-align: middle; margin-top: auto; margin-bottom: auto;">
            <span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true" style="vertical-align: middle;"></span>
            <span>กำลังสร้างเอกสารประกอบคำขอ...</span>
          </div>
        </div>`
    );
    this._http.post(`api/report/java-bean`, this.params, {responseType: "blob"})
      .subscribe(e => {

        this.objectURL = URL.createObjectURL(e);
        this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(
          `<iframe src="${this.objectURL}"
                        class='embed-responsive-item'
                        style="min-height: 750px; width: 100%;">
                    เกิดข้อผิดพลาดในการโหลดไฟล์
                    </iframe>`);
      }, error => {
        console.log('server error = ', error)
        this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(`<div style="min-height: 750px; width: 100%;">
          <div style="vertical-align: middle; margin-top: auto; margin-bottom: auto;">
            <strong>เกิดข้อผิดพลาดในการสร้างเอกสาร</strong>
          </div>
        </div>`);
      });
  }

  save(option: SaveFormDialogOption): void {

    const modalRef = this.modalService.open(ConfirmDialogComponent, {centered: true});
    modalRef.componentInstance.content = option.confirmDialogMessage || `ยืนยันการส่งเรื่อง?`;
    from(modalRef.result)
      .subscribe(e => {
        if (!!e) {
          option.callback()
            .subscribe(value => {
              this._toastrService.success(option.successAlertMessage || "ส่งเรื่องเรียบร้อยแล้ว");
              this.activeModal.close(true);
              if (option.redirectUrl) {
                this.router.navigate(option.redirectUrl, {relativeTo: this.activateRoute});
              }
            });
        } else {
          console.log('denied.');
        }
      }, error => error);
  }

  saveAndSign(option: SaveFormDialogOption): void {

    const modalRef = this.modalService.open(PasswordP12DialogComponent, {
      centered: true,
      keyboard: false,
      backdrop: 'static',
    });
    from(modalRef.result)
      .subscribe(p12password => {
        if (!!p12password) {
          option.callback(p12password)
            .subscribe(value => {
              this.certificateInvalidMessage = undefined;
              this._toastrService.success(option.successAlertMessage || "ส่งเรื่องเรียบร้อยแล้ว");
              this.activeModal.close(true);
              if (option.redirectUrl) {
                this.router.navigate(option.redirectUrl, {relativeTo: this.activateRoute});
              }
            }, error => {
              if (error instanceof HttpErrorResponse) {
                if (error.status == 400) {
                  this.certificateInvalidMessage = error?.error?.message;
                }
              }
            });
        } else {
          console.log('denied.');
        }
      }, error => error);
  }

  get options(): SaveFormDialogOption[] {
    return this._options;
  }

  @Input()
  set options(value: SaveFormDialogOption[]) {

    this._options = value;
    this._options.forEach(option => {
        option.showCondition ??= () => of(true);
        if (option.signDocumentRequire) {
          option['onClick'] = () => this.saveAndSign(option);
        } else {
          option['onClick'] = () => this.save(option);
        }
      }
    );
  }

  close() {

    this.clearObjectURL();
    this.activeModal.close(false);
  }

  clearObjectURL() {

    console.log('revoking object URL...');
    if (this.objectURL) {
      URL.revokeObjectURL(this.objectURL);
    }
  }

  ngOnDestroy(): void {

    this.clearObjectURL();
  }
}

@Component({
  template: `
    <div class="modal-header">
      <strong class="modal-title">ยืนยันรหัสผ่าน</strong>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <form>
            <div class="form-group">
              <label for="p12password">รหัสผ่าน KeyStore(.p12) <i class="fa fa-key ml-2"></i></label>
              <div class="input-group">
                <input [(ngModel)]="p12password"
                       [type]="showPassword ? 'text' : 'password'"
                       class="form-control"
                       id="p12password"
                       name="p12password"
                       (keydown.enter)="validatePassword()">
                <div class="input-group-append">
                  <span class="input-group-text bg-white " (click)="showPassword = !showPassword">
                    <i class="fa fa-fw " [ngClass]="showPassword?' fa-eye':' fa-eye-slash'"></i>
                  </span>
                </div>
              </div>
              <gt-error forId="p12password" field="password"></gt-error>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="">
      <div class="row p-3">
        <div class="col-12 col-xl-12">
          <button class="btn btn-outline-secondary btn-main" (click)="activeModal.close(false)">ปิด</button>
          <button class="btn btn-primary float-right btn-main" (click)="validatePassword()">ยืนยัน</button>
        </div>
      </div>
    </div>
  `
})
export class PasswordP12DialogComponent implements OnInit {

  p12password: string;
  showPassword: boolean = false;

  constructor(private _http: HttpClient,
              public activeModal: NgbActiveModal) {

  }

  ngOnInit(): void {
    this.p12password = undefined;
  }

  validatePassword() {

    this._http.post('api/keystore/validate', {password: this.p12password})
      .subscribe(() => {
        this.activeModal.close(this.p12password);
      });
  }
}
