import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {RequestRegisterFormService} from "./request-register-form.service";
import {map} from "rxjs/operators";

@Injectable()
export class AuthorizedResolverService implements Resolve<boolean> {

  constructor(private _service: RequestRegisterFormService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this._service.authorized().pipe(map(e => e === 'true'));
  }
}
