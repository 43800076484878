import {Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {
  AuthorityType,
  RequestAgent,
  RequestAgentDto,
  RequestAgentType,
  RequestUser
} from "../../../../../../../../generated-model/model";
import {NgForm} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {LoadingService} from "../../../../../../../shareds/loading.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {RequestAgentService} from "./services/request-agent.service";
import {AlertDialogComponent} from "../../../../../../../shareds/components/dialog/alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-add-request-agent-modal',
  templateUrl: './add-request-agent-modal.component.html',
  styleUrls: ['./add-request-agent-modal.component.css'],
  providers: [RequestAgentService]
})
export class AddRequestAgentModalComponent implements OnInit {

  showPassword: boolean = false;
  requestAgentDto: Partial<RequestAgentDto> = {};
  addonAuthorities: { [id: string]: { checked: boolean } };

  @ViewChild("form", {static: true})
  form: NgForm;

  @Input()
  requestUser: RequestUser;

  @Input()
  requestAgentType: RequestAgentType;

  onSave: EventEmitter<RequestAgent> = new EventEmitter<RequestAgent>();

  constructor(private _route: ActivatedRoute,
              private _service: RequestAgentService,
              private _modalService: NgbModal,
              public activeModal: NgbActiveModal,
              public loadingService: LoadingService) {
  }

  ngOnInit(): void {

    if (!this.requestUser) {
      throw new Error("requestUser cannot be null.");
    }
    if (!this.requestAgentType) {
      throw new Error("requestAgentType cannot be null.");
    }
    if (this.requestAgentType == "ENTREPRENEUR") {
      this.addonAuthorities = {
        'LICENSING': {checked: true},
        'VOUCHER_1': {checked: true},
        'VOUCHER_2': {checked: true},
        'VOUCHER_3': {checked: true},
        'CERTIFICATE': {checked: true},
        'NS4': {checked: true},
        'GMP_HACCP': {checked: true},
        'AUDIT': {checked: true},
        'REQUEST_INSPECTION': {checked: true}
      };
    } else {
      this.addonAuthorities = {
        'LICENSING': {checked: false},
        'VOUCHER_1': {checked: false},
        'VOUCHER_2': {checked: false},
        'VOUCHER_3': {checked: false},
        'CERTIFICATE': {checked: false},
        'NS4': {checked: false},
        'GMP_HACCP': {checked: false},
        'AUDIT': {checked: false},
        'REQUEST_INSPECTION': {checked: false}
      };
    }
    this.requestAgentDto.requestUser = this.requestUser;
    this.requestAgentDto.requestAgentType = this.requestAgentType;
  }

  sendRequestAgent() {

    this.requestAgentDto.authorityTypes = Object.entries(this.addonAuthorities)
      .filter(([k, v], index) => !!v.checked)
      .map(([k, v], index) => k as AuthorityType);

    this._service.request(this.requestAgentDto)
      .subscribe(requestAgentDto => {
        this.onSave.emit(requestAgentDto);
        const modalRef = this._modalService.open(AlertDialogComponent, {centered: true});
        modalRef.componentInstance.content = `ส่งลิงค์ลงทะเบียนไปยังอีเมลเรียบร้อยแล้ว`;
        this.activeModal.dismiss();
      });
  }
}
