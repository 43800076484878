import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'keyvaluestartswith'
})

export class KeyValueStartswithPipe implements PipeTransform {


  transform(value: any, args: string): {key:string,value:any}[] {
    let keys = Object.keys(value).filter(e=>e.startsWith(args));
    let formTypes:{key:string,value:any}[] = keys.map(e=>({key:e,value:value[e]}));
    return formTypes;
  }

}
