import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DomSanitizer} from "@angular/platform-browser";
import {LoadingService} from "../../../loading.service";
import {HttpClient} from "@angular/common/http";
import {FileUpload, SignatureInfoDto} from "../../../../../generated-model/model";
import {AuthenService} from "../../../../authen.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-document-viewer-dialog',
  templateUrl: './document-viewer-dialog.component.html',
  styleUrls: ['./document-viewer-dialog.component.css']
})
export class DocumentViewerDialogComponent<T> implements OnInit, OnDestroy {

  @Input()
  documentFile: FileUpload;

  @Input()
  title: string = 'พิมพ์แบบคำขอ';

  innerHTML: any;
  certificateInfo$: Observable<SignatureInfoDto[]>;
  objectURL: string;

  constructor(public activeModal: NgbActiveModal,
              public modalService: NgbModal,
              public loadingService: LoadingService,
              public authenService: AuthenService,
              private _domSanitizer: DomSanitizer,
              private _http: HttpClient) {
  }

  ngOnInit() {

    this.certificateInfo$ = this._http.get<SignatureInfoDto[]>(`public/api/file-upload/${this.documentFile?.ref}/certificate-info`);
    this.srcUrl();
  }

  private srcUrl() {

    this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(
      `<div style="min-height: 750px; width: 100%;">
          <div style="vertical-align: middle; margin-top: auto; margin-bottom: auto;">
            <span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true" style="vertical-align: middle;"></span>
            <span>กำลังโหลดเอกสาร...</span>
          </div>
        </div>`
    );
    this._http.get(`public/api/file-upload/${this.documentFile?.ref}/preview`, {responseType: "blob"})
      .subscribe(e => {

        this.objectURL = URL.createObjectURL(e);
        this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(
          `<iframe src="${this.objectURL}"
                        class='embed-responsive-item'
                        style="min-height: 750px; width: 100%;">
                    เกิดข้อผิดพลาดในการโหลดไฟล์
                    </iframe>`);
      }, error => {
        console.log('server error = ', error)
        this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(`<div style="min-height: 750px; width: 100%;">
          <div style="vertical-align: middle; margin-top: auto; margin-bottom: auto;">
            <strong>เกิดข้อผิดพลาดในการโหลดเอกสาร</strong>
          </div>
        </div>`);
      });
  }

  close() {

    this.clearObjectURL();
    this.activeModal.close(false);
  }

  clearObjectURL() {

    console.log('revoking object URL...');
    if (this.objectURL) {
      URL.revokeObjectURL(this.objectURL);
    }
  }

  ngOnDestroy(): void {

    this.clearObjectURL();
  }
}
