import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, concat, Observable, of} from "rxjs";
import {Tambon} from "../../../../generated-model/model";
import {NgSelectComponent} from "@ng-select/ng-select";
import {HttpClient} from "@angular/common/http";
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from "rxjs/operators";
import {IgnoreNullHttpParams} from "../../Ignore-null-http-params";
import {untilDestroyed} from "ngx-take-until-destroy";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'gt-tambon-autocomplete',
  templateUrl: './gt-tambon-autocomplete.component.html',
  styleUrls: ['./gt-tambon-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GtTambonAutocompleteComponent,
      multi: true
    }
  ],
})
export class GtTambonAutocompleteComponent implements ControlValueAccessor, OnInit {

  @Input()
  id: string;

  @Input()
  name: string;

  @Input()
  validateField: string;

  @Input()
  readonly: boolean = false;

  @Input()
  lang: 'TH' | 'EN' = 'TH';

  private _restrictedProvinceId: string;
  tambonList$: Observable<Tambon[]>;
  term$ = new BehaviorSubject<string>(undefined);
  typeaheadLoading = false;
  _value: Tambon = undefined;

  @ViewChild("tambonSelect", {static: true})
  tambonSelect: NgSelectComponent;

  onChangeCallBack: (_: any) => void = () => {
  };
  onTouchCallBack: () => void = () => {
  };

  constructor(private _http: HttpClient,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {

    this.tambonList$ = concat(
      of([]), // default items
      this.term$.pipe(
        debounceTime(300),
        // distinctUntilChanged(),
        tap(() => this.typeaheadLoading = true),
        switchMap(term => {
          return this.search(term)
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => this.typeaheadLoading = false),
              untilDestroyed(this)
            );
        })
      )
    );
  }

  registerOnChange(fn: any): void {
    this.onChangeCallBack = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchCallBack = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onModelChange(item: string) {

    this.value = item;
  }

  search(term: string): Observable<Tambon[]> {

    return this._http.get<Tambon[]>(`public/api/tambon/th/search`,
      {params: IgnoreNullHttpParams.fromObject({term: term, provinceId: this._restrictedProvinceId}).toHttpParam()}
    );
  }

  public clear() {

    this.tambonSelect.handleClearClick();
    this.tambonSelect.blur();
  }

  onSelectOpen() {

    this.term$.next('');
  }

  set value(val: any) {

    this._value = val;
    this.onChangeCallBack(val);
    this.onTouchCallBack();
    this._cdr.detectChanges();
  }

  get value(): any {

    return this._value;
  }

  get restrictedProvinceId(): string {
    return this._restrictedProvinceId;
  }

  @Input()
  set restrictedProvinceId(value: string) {

    this._restrictedProvinceId = value;
    this.term$.next('');
  }

  ngOnDestroy(): void {

  }
}
