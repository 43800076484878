import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import Quill from 'quill'
import Counter from './counter'

Quill.register('modules/counter', Counter);

const font = Quill.import('formats/font');
font.whitelist = ['IBMPlexSansThai']
Quill.register(font, true)

@Component({
  selector: 'app-input-editor-dialog',
  templateUrl: './input-editor-dialog.component.html',
  styleUrls: ['./input-editor-dialog.component.css']
})
export class InputEditorDialogComponent implements OnInit {

  quillConfiguration = {
    toolbar: [
      [{'script': 'super'}, {'script': 'sub'}],
    ],
  };

  private _value: string = '';

  @Input()
  private name: string = 'input-editor';

  @Output()
  onSave: EventEmitter<string> = new EventEmitter<string>();

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  get value(): string {
    return this._value;
  }

  @Input()
  set value(value: string) {
    this._value = value;
  }

  save() {

    this.onSave.emit(this.value);
    this.activeModal.close();
  }
}
