import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgbCalendar, NgbDate} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-gt-ng-datepicker-range',
  templateUrl: './gt-ng-datepicker-range.component.html',
  styleUrls: ['./gt-ng-datepicker-range.component.css']
})
export class GtNgDatepickerRangeComponent implements OnInit,OnChanges {

  @Input()
  value: any;

  @Input()
  fromDateName: string;

  @Input()
  toDateName: string;

  @Input()
  readonly: boolean = false;

  @Input()
  markDisabledSaturdaysAndSundays?: boolean = false;

  ngbFromDate: NgbDate;

  ngbToDate: NgbDate;

  hoveredDate: NgbDate | null = null;


  constructor(private calendar: NgbCalendar) {
  }

  markDisabled: (date: NgbDate, current?: {
    year: number;
    month: number;
  }) => boolean;

  ngOnInit(): void {
    this.markDisabled = (date, current) => {
      return this.readonly;
    };

    if (this.markDisabledSaturdaysAndSundays && !this.readonly){
      this.markDisabled = (date, current) => {
        return [6, 7].includes(this.calendar.getWeekday(new NgbDate(date.year, date.month, date.day)))
      };
    }

    if (this.value[this.fromDateName]) {
      let date = new Date(this.value[this.fromDateName]);
      this.ngbFromDate = NgbDate.from({year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()})
    }
    if (this.value[this.toDateName]) {
      let date = new Date(this.value[this.toDateName]);
      this.ngbToDate = NgbDate.from({year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()})
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.ngbFromDate && !this.ngbToDate) {
      this.ngbFromDate = date;
      this.ngbToDate = null ;
    } else if (this.ngbFromDate && !this.ngbToDate && date.after(this.ngbFromDate)) {
      this.ngbToDate = date;
    } else {
      this.ngbToDate = null;
      this.ngbFromDate = date;
    }
    this.convertNgbDateToDate();
  }


  convertNgbDateToDate() {
    if (this.ngbFromDate) {
      this.value[this.fromDateName] = new Date(this.ngbFromDate.year, this.ngbFromDate.month - 1, this.ngbFromDate.day)
      if (this.ngbToDate) {
        this.value[this.toDateName] = new Date(this.ngbToDate.year, this.ngbToDate.month - 1, this.ngbToDate.day)
      }else {
        this.value[this.toDateName] = null;
      }
    }

  }

  isHovered(date: NgbDate) {
    return this.ngbFromDate && !this.ngbToDate && this.hoveredDate && date.after(this.ngbFromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.ngbToDate && date.after(this.ngbFromDate) && date.before(this.ngbToDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.ngbFromDate) || (this.ngbToDate && date.equals(this.ngbToDate)) || this.isInside(date) || this.isHovered(date);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.markDisabled = (date, current) => {
      return this.readonly;
    };
    if (this.value[this.fromDateName]) {
      let date = new Date(this.value[this.fromDateName]);
      this.ngbFromDate = NgbDate.from({year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()})
    }
    if (this.value[this.toDateName]) {
      let date = new Date(this.value[this.toDateName]);
      this.ngbToDate = NgbDate.from({year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()})
    }
  }

}
