import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {RequestShipping, RequestUser} from "../../../../../../../../../generated-model/model";

@Injectable()
export class RequestShippingService {

  constructor(private _http: HttpClient) {

  }

  save(requestShipping: RequestShipping, requestUser: RequestUser): Observable<RequestShipping> {

    return this._http.post<RequestShipping>(`api/request-register-form/request-user/${requestUser.id}/shipping`, requestShipping);
  }

  delete(requestShipping: RequestShipping): Observable<void> {

    return this._http.delete<void>(`api/request-shipping/${requestShipping.id}/delete`);
  }

  getAll(requestUser: RequestUser): Observable<RequestShipping[]> {

    return this._http.get<RequestShipping[]>(`api/request-register-form/request-user/${requestUser.id}/shipping`);
  }
}
