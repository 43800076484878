import {Component, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-dummy-component',
  templateUrl: './dummy-component.component.html',
  styleUrls: ['./dummy-component.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DummyComponentComponent,
      multi: true
    }
  ]
})
export class DummyComponentComponent implements ControlValueAccessor {

  _value: string;
  @Input()
  name: string

  onChangeCallBack: (_: any) => void = () => {};
  onTouchCallBack: () => void = () => {};

  constructor() {
  }

  set value(val: string) {

    this._value = val;
    this.onChangeCallBack(val);
    this.onTouchCallBack();
  }


  get value(): string {
    return this._value;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallBack = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchCallBack = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
}
