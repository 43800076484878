import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {RequestEmployee, RequestUser} from "../../../../../../../../../generated-model/model";

@Injectable()
export class RequestEmployeeService {

  constructor(private _http: HttpClient) {

  }

  save(requestUser: RequestUser, requestEmployee: RequestEmployee): Observable<RequestEmployee> {

    return this._http.post<RequestEmployee>(`api/request-register-form/request-user/${requestUser.id}/manage/users/update/employee`, requestEmployee);
  }

}
