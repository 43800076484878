import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RequestShipping, RequestUser} from "../../../../../../../generated-model/model";
import {ActivatedRoute} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AddRequestShippingModalComponent} from "./add-shipping-modal/add-request-shipping-modal.component";
import {faShippingFast} from '@fortawesome/free-solid-svg-icons';
import {ConfirmDialogComponent} from "../../../../../../shareds/components/dialog/confirm-dialog/confirm-dialog.component";
import {from} from "rxjs";
import {tap} from "rxjs/operators";
import {RequestShippingService} from "./add-shipping-modal/services/request-shipping.service";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-manage-request-shipping',
    templateUrl: './manage-request-shipping.component.html',
    styleUrls: ['./manage-request-shipping.component.css'],
    providers: [RequestShippingService]
})
export class ManageRequestShippingComponent implements OnInit {

    @Input()
    requestUser: RequestUser = {
        personType: 'PERSONAL',
        identityCard: {cardType: 'ID_CARD'},
        contactAddress: {}
    };

    @Input()
    readonly: boolean = false;

    @Output()
    requestUserChange: EventEmitter<RequestUser> = new EventEmitter<RequestUser>();

    @Input()
    shippings: RequestShipping[] = [];

    @Output()
    shippingsChange: EventEmitter<RequestShipping[]> = new EventEmitter<RequestShipping[]>();

    faShippingFast = faShippingFast;

    constructor(private activatedRoute: ActivatedRoute,
                private _service: RequestShippingService,
                private _modalService: NgbModal,
                private _toastrService: ToastrService) {
    }

    ngOnInit(): void {
    }

    addShipping() {

        let modalRef = this._modalService.open(AddRequestShippingModalComponent, {
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.requestUser = this.requestUser;
        modalRef.componentInstance.onSave.subscribe(shipping => {
            this.shippings.push(shipping);
            this.shippingsChange.emit(this.shippings);
        });
    }

    shippingDetails(shipping: RequestShipping) {

        let modalRef = this._modalService.open(AddRequestShippingModalComponent, {
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.requestUser = this.requestUser;
        modalRef.componentInstance.requestShipping = shipping;
        modalRef.componentInstance.state = 'DETAILS';
    }

    deleteShipping(shipping: RequestShipping) {

        const modalRef = this._modalService.open(ConfirmDialogComponent, {centered: true});
        modalRef.componentInstance.content = `คุณต้องการลบตัวแทนออกของใช่หรือไม่?`;
        from(modalRef.result)
            .subscribe(e => {
                if (!!e) {
                    this._service.delete(shipping)
                        .pipe(
                            tap(x => {
                                this._service.getAll(this.requestUser)
                                    .subscribe(value => {
                                        this.shippings = value;
                                        this.shippingsChange.emit(this.shippings);
                                    })
                            })
                        )
                        .subscribe(() => {
                            this._toastrService.success("ลบข้อมูลตัวแทนออกของเรียบร้อยแล้ว");
                        });
                } else {
                    console.log('denied.');
                }
            }, error => error);
    }

    updateShipping(shipping: RequestShipping, index: number) {

        let modalRef = this._modalService.open(AddRequestShippingModalComponent, {
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.requestUser = this.requestUser;
        modalRef.componentInstance.requestShipping = JSON.parse(JSON.stringify(shipping));
        modalRef.componentInstance.state = 'UPDATE';
        modalRef.componentInstance.onSave.subscribe(result => {
            this.shippings[index] = {...result};
            this.shippings = [...this.shippings];
            this.shippingsChange.emit(this.shippings);
        });
    }
}
