import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-selectable-title-comment',
  templateUrl: './selectable-title-comment.component.html',
  styleUrls: ['./selectable-title-comment.component.css']
})
export class SelectableTitleCommentComponent implements OnInit {
  title: string = 'สั่งแก้ไข'
  headerText: string
  confirmButtonMsg = 'ยืนยัน';
  closeButtonMsg = 'ยกเลิก';
  comment

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  cancel() {
    this.activeModal.close()
  }

  confirm() {
    this.activeModal.close({comment: this.comment, headerText: this.headerText})
  }
}
