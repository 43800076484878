import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {InputEditorDialogComponent} from "./input-editor-dialog/input-editor-dialog.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-input-editor',
  templateUrl: './input-editor.component.html',
  styleUrls: ['./input-editor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputEditorComponent,
      multi: true
    },
  ],
})
export class InputEditorComponent implements ControlValueAccessor, OnInit {

  private _value: string;

  onChangeCallBack: (_: any) => void = () => {
  };
  onTouchCallBack: () => void = () => {
  };

  @Input()
  private name: string = 'input-editor';

  @Input()
  readonly: boolean = false;

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  openInputEditorDialog() {

    let modalRef = this._modalService.open(InputEditorDialogComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      centered: true
    });
    modalRef.componentInstance.name = this.name;
    modalRef.componentInstance.value = this.value;
    modalRef.componentInstance.onSave
      .subscribe(value => {
        console.log('value = ', value);
        this.value = value;
        this.valueChange.emit(value)
      });
  }

  get value(): string {
    return this._value;
  }

  set value(value: string) {

    this._value = value;
    this.onChangeCallBack(value);
    this.onTouchCallBack();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallBack = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchCallBack = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
}
