import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {Observable} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {tap} from "rxjs/operators";

@Injectable()
export class Http401Interceptor implements HttpInterceptor {
    private currentUrl: string;
    private active = true;

    constructor(private modalService: NgbModal,
                private router: Router,
                private route: ActivatedRoute,
                private ngbModal: NgbModal) {

        this.router.events.subscribe(e => {
            if (e instanceof NavigationStart) {
                this.currentUrl = e.url
            }
        })
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(event => {
        }, err => {
            this.hadleError(err);
        }));
    }

    private hadleError(err: HttpErrorResponse) {
        if (err.status == 401 && this.active && this.router.url !== '/login') {
          //dissmiss all activated modals
          this.ngbModal.dismissAll();
          this.router.navigate(['/login']);
        }
    }

}
