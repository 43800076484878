import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenService} from "./authen.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private _authenService: AuthenService,
              private _http: HttpClient,
              private _router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    console.log('do login guard');
    //todo
    // return this._authenService.auth$
    //   .pipe(
    //     map(auth => {
    //         let isOfficer = this._authenService.hasAnyAuthority(auth.user, OFFICER_AUTHORITIES);
    //         let isRequestNewUser = this._authenService.hasAnyAuthority(auth.user, REQUEST_NEW_AUTHORITIES);
    //         if (isRequestNewUser) {
    //           this._router.navigate(['/profile/request/list']);
    //           return false;
    //         } else if (isOfficer) {
    //           this._router.navigate(['/officer/portal']);
    //           return false;
    //         } else {
    //           this._router.navigate(['/portal']);
    //           return false;
    //         }
    //       }
    //     )
    //   );
    return true;
  }

}
