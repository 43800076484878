import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {
    RequestAgent,
    RequestAgentType,
    RequestAttorney,
    RequestEmployee,
    RequestEntrepreneur,
    RequestUser
} from "../../../../../../../generated-model/model";
import {faCrown, faSpinner, faUserCog, faUsers, faUserShield, faUserTie} from '@fortawesome/free-solid-svg-icons';
import {RequestEmployeeDetailsModalComponent} from "./employee-details-modal/request-employee-details-modal.component";
import {AddRequestAgentModalComponent} from "./add-agent-modal/add-request-agent-modal.component";
import {ManageRequestUsersService} from "./services/manage-request-users.service";
import {ConfirmDialogComponent} from "../../../../../../shareds/components/dialog/confirm-dialog/confirm-dialog.component";
import {from} from "rxjs";
import {tap} from "rxjs/operators";
import {AddRequestAttorneyModalComponent} from "./add-attorney-modal/add-request-attorney-modal.component";
import {AddRequestEntrepreneurModalComponent} from "./add-entrepreneur-modal/add-request-entrepreneur-modal.component";
import {RequestAgentDetailsModalComponent} from "./agent-details-modal/request-agent-details-modal.component";

@Component({
    selector: 'app-register-manage-request-users',
    templateUrl: './manage-request-users.component.html',
    styleUrls: ['./manage-request-users.component.css'],
    providers: [ManageRequestUsersService]
})
export class ManageRequestUsersComponent implements OnInit {

    faCrown = faCrown;
    faUserShield = faUserShield;
    faUserTie = faUserTie;
    faUserCog = faUserCog;
    faSpinner = faSpinner;
    faUsers = faUsers;

    @Input()
    manageUsers: { [id: string]: any[] } = {};

    @Output()
    manageUsersChange: EventEmitter<{ [id: string]: any[] }> = new EventEmitter<{ [id: string]: any[] }>();

    @Input()
    requestUser: RequestUser = {
        personType: 'PERSONAL',
        identityCard: {cardType: 'ID_CARD'},
        contactAddress: {}
    };

    @Input()
    readonly: boolean = false;

    @Output()
    requestUserChange: EventEmitter<RequestUser> = new EventEmitter<RequestUser>();

    constructor(private _modalService: NgbModal,
                private _service: ManageRequestUsersService,
                private _activatedRoute: ActivatedRoute,
                private _toastrService: ToastrService) {

    }

    ngOnInit(): void {

        if (!this.requestUser) {
            throw new Error("requestUser cannot be null.");
        }
    }

    addEntrepreneur() {

        let modalRef = this._modalService.open(AddRequestEntrepreneurModalComponent, {
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.requestUser = this.requestUser;
        modalRef.componentInstance.onSave.subscribe(entrepreneur => {
            this.manageUsers['ENTREPRENEUR'].push(entrepreneur);
            this.manageUsersChange.emit(this.manageUsers);
        });
    }

    entrepreneurDetails(entrepreneur: RequestEntrepreneur) {

        let modalRef = this._modalService.open(AddRequestEntrepreneurModalComponent, {
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.requestUser = this.requestUser;
        modalRef.componentInstance.entrepreneur = entrepreneur;
        modalRef.componentInstance.state = 'DETAILS';
    }

    updateEntrepreneur(entrepreneur: RequestEntrepreneur) {

        let modalRef = this._modalService.open(AddRequestEntrepreneurModalComponent, {
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.requestUser = this.requestUser;
        modalRef.componentInstance.entrepreneur = JSON.parse(JSON.stringify(entrepreneur));
        modalRef.componentInstance.state = 'UPDATE';
        modalRef.componentInstance.onSave.subscribe(entrepreneur => {
            this._service.manageUsers(this.requestUser)
                .subscribe(manageUsers => {
                    this.manageUsers = manageUsers;
                    this.manageUsersChange.emit(this.manageUsers);
                });
        });
    }

    attorneyDetails(attorney: RequestAttorney) {

        let modalRef = this._modalService.open(AddRequestAttorneyModalComponent, {
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.requestUser = this.requestUser;
        modalRef.componentInstance.attorney = attorney;
        modalRef.componentInstance.state = 'DETAILS';
    }

    addAgent(requestAgentType: RequestAgentType) {

        let modalRef = this._modalService.open(AddRequestAgentModalComponent, {
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.requestUser = this.requestUser;
        modalRef.componentInstance.requestAgentType = requestAgentType;
        modalRef.componentInstance.onSave.subscribe(agent => {
            this.manageUsers['AGENT'].push(agent);
            this.manageUsersChange.emit(this.manageUsers);
        });
    }

    agentDetails(agent: RequestUser) {

        let modalRef = this._modalService.open(RequestAgentDetailsModalComponent, {
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.requestUser = JSON.parse(JSON.stringify(agent));
        modalRef.componentInstance.state = 'DETAILS';
    }

    addEmployee(requestAgentType: RequestAgentType) {

        let modalRef = this._modalService.open(AddRequestAgentModalComponent, {
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.requestUser = this.requestUser;
        modalRef.componentInstance.requestAgentType = requestAgentType;
        modalRef.componentInstance.onSave.subscribe(agent => {
            this.manageUsers['EMPLOYEE'].push(agent);
            this.manageUsersChange.emit(this.manageUsers);
        });
    }

    updateEmployee(employee: RequestEmployee) {

        let modalRef = this._modalService.open(RequestEmployeeDetailsModalComponent, {
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.requestUser = this.requestUser;
        modalRef.componentInstance.employee = JSON.parse(JSON.stringify(employee));
        modalRef.componentInstance.state = 'UPDATE';
        modalRef.componentInstance.onSave.subscribe(employee => {
            this._service.manageUsers(this.requestUser)
                .subscribe(manageUsers => {
                    this.manageUsers = manageUsers;
                    this.manageUsersChange.emit(this.manageUsers);
                });
        });
    }

    deletePendingAgent(agent: RequestAgent) {

        const modalRef = this._modalService.open(ConfirmDialogComponent, {centered: true});
        modalRef.componentInstance.content = `คุณต้องการลบผู้ถือบัญชีใช่หรือไม่?`;
        from(modalRef.result)
            .subscribe(e => {
                if (!!e) {
                    this._service.deletePendingAgent(agent)
                        .pipe(
                            tap(x => {
                                this._service.manageUsers(this.requestUser)
                                    .subscribe(manageUsers => {
                                        this.manageUsers = manageUsers;
                                        this.manageUsersChange.emit(this.manageUsers);
                                    });
                            })
                        )
                        .subscribe(() => {
                            this._toastrService.success("ลบข้อมูลผู้ถือบัญชีเรียบร้อยแล้ว");
                        });
                } else {
                    console.log('denied.');
                }
            }, error => error);
    }

    deleteAgent(agent: RequestUser) {

        const modalRef = this._modalService.open(ConfirmDialogComponent, {centered: true});
        modalRef.componentInstance.content = `คุณต้องการลบผู้ถือบัญชีใช่หรือไม่?`;
        from(modalRef.result)
            .subscribe(e => {
                if (!!e) {
                    this._service.deleteAgent(agent)
                        .pipe(
                            tap(x => {
                                this._service.manageUsers(this.requestUser)
                                    .subscribe(manageUsers => {
                                        this.manageUsers = manageUsers;
                                        this.manageUsersChange.emit(this.manageUsers);
                                    })
                            })
                        )
                        .subscribe(() => {
                            this._toastrService.success("ลบข้อมูลผู้ถือบัญชีเรียบร้อยแล้ว");
                        });
                } else {
                    console.log('denied.');
                }
            }, error => error);
    }

    deleteEntrepreneur(entrepreneur: RequestEntrepreneur) {

        const modalRef = this._modalService.open(ConfirmDialogComponent, {centered: true});
        modalRef.componentInstance.content = `คุณต้องการลบผู้ดำเนินกิจการใช่หรือไม่?`;
        from(modalRef.result)
            .subscribe(e => {
                if (!!e) {
                    this._service.deleteEntrepreneur(entrepreneur)
                        .pipe(
                            tap(x => {
                                this._service.manageUsers(this.requestUser)
                                    .subscribe(manageUsers => {
                                        this.manageUsers = manageUsers;
                                        this.manageUsersChange.emit(this.manageUsers);
                                    })
                            })
                        )
                        .subscribe(() => {
                            this._toastrService.success("ลบข้อมูลผู้ดำเนินกิจการเรียบร้อยแล้ว");
                        });
                } else {
                    console.log('denied.');
                }
            }, error => error);
    }

    employeeDetails(employee: any) {

        let modalRef = this._modalService.open(RequestEmployeeDetailsModalComponent, {
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            centered: true
        });
        modalRef.componentInstance.requestUser = this.requestUser;
        modalRef.componentInstance.employee = employee;
        modalRef.componentInstance.state = 'DETAILS';
    }

    deleteEmployee(employee: any) {

        const modalRef = this._modalService.open(ConfirmDialogComponent, {centered: true});
        modalRef.componentInstance.content = `คุณต้องการลบพนักงานลูกจ้างใช่หรือไม่?`;
        from(modalRef.result)
            .subscribe(e => {
                if (!!e) {
                    this._service.deleteEmployee(employee)
                        .pipe(
                            tap(x => {
                                this._service.manageUsers(this.requestUser)
                                    .subscribe(manageUsers => {
                                        this.manageUsers = manageUsers;
                                        this.manageUsersChange.emit(this.manageUsers);
                                    })
                            })
                        )
                        .subscribe(() => {
                            this._toastrService.success("ลบข้อมูลพนักงานลูกจ้างเรียบร้อยแล้ว");
                        });
                } else {
                    console.log('denied.');
                }
            }, error => error);
    }

    deletePendingEmployee(agent: RequestAgent) {

        const modalRef = this._modalService.open(ConfirmDialogComponent, {centered: true});
        modalRef.componentInstance.content = `คุณต้องการลบพนักงานลูกจ้างใช่หรือไม่?`;
        from(modalRef.result)
            .subscribe(e => {
                if (!!e) {
                    this._service.deletePendingAgent(agent)
                        .pipe(
                            tap(x => {
                                this._service.manageUsers(this.requestUser)
                                    .subscribe(manageUsers => {
                                        this.manageUsers = manageUsers;
                                        this.manageUsersChange.emit(this.manageUsers);
                                    })
                            })
                        )
                        .subscribe(() => {
                            this._toastrService.success("ลบข้อมูลพนักงานลูกจ้างเรียบร้อยแล้ว");
                        });
                } else {
                    console.log('denied.');
                }
            }, error => error);
    }

    canManage(compareId: any) {
        return this.requestUser.id != compareId;
    }
}
