import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DomSanitizer} from "@angular/platform-browser";
import {LoadingService} from "../../../loading.service";
import {HttpClient} from "@angular/common/http";
import {ReportCondition} from "../../../../../generated-model/model";
import {isSafari} from "../../../../_app_constant";

@Component({
  selector: 'app-pdf-preview-dialog',
  templateUrl: './pdf-preview-dialog.component.html',
  styleUrls: ['./pdf-preview-dialog.component.css']
})
export class PdfPreviewDialogComponent<T> implements OnInit {

  @Input()
  params: ReportCondition = {};

  @Input()
  title: string = 'Preview';

  @Input()
  ref: string

  innerHTML: any;

  constructor(public activeModal: NgbActiveModal,
              public loadingService: LoadingService,
              private _domSanitizer: DomSanitizer,
              private _http: HttpClient) {
  }

  ngOnInit() {
    console.log('eieieieeieieieieie')
    if (!this.params && !this.params.className) {
      throw new Error("params & className cannot be null.");
    }
    this.srcUrl();
  }

  private srcUrl() {
    this.params.fileType = "pdf";
    this.params.attachment = false;
    console.log('isSafari? ', isSafari);

    this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(
      `<div style="min-height: 750px; width: 100%;">
          <div style="vertical-align: middle; margin-top: auto; margin-bottom: auto;">
            <span class="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true" style="vertical-align: middle;"></span>
            <span>กำลังสร้างเอกสารประกอบคำขอ...</span>
          </div>
        </div>`
    );

    if (this.ref) {
      this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(
        `<iframe src="public/api/file-upload/${this.ref}/preview"
                class='embed-responsive-item'
                style="min-height: 750px; width: 100%;">
            เกิดข้อผิดพลาดในการโหลดไฟล์
            </iframe>`
      );
    } else {
      console.log('load bean')
      this._http.post(`api/report/java-bean`, this.params, {responseType: "blob"})
        .subscribe(e => {
          let reader = new FileReader();
          reader.readAsDataURL(e);
          reader.onloadend = () => {
            let base64data = reader.result;
            this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(
              `<iframe src="${base64data}"
                  class='embed-responsive-item'
                  style="min-height: 750px; width: 100%;">
              เกิดข้อผิดพลาดในการโหลดไฟล์
              </iframe>`
            );

            reader.onerror = (error) => {

              console.log('reader error = ', error);
              this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(
                `<div style="min-height: 750px; width: 100%;">
              <div style="vertical-align: middle; margin-top: auto; margin-bottom: auto;">
                <strong>เกิดข้อผิดพลาดในการสร้างเอกสาร</strong>
              </div>
            </div>`);
            }
          }
        }, error => {
          console.log('server error = ', error)
          this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(`<div style="min-height: 750px; width: 100%;">
          <div style="vertical-align: middle; margin-top: auto; margin-bottom: auto;">
            <strong>เกิดข้อผิดพลาดในการสร้างเอกสาร</strong>
          </div>
        </div>`);
        });
    }
  }
}
