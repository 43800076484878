import {AfterViewInit, Directive, Input, OnInit} from '@angular/core';
import {NgModel} from "@angular/forms";

@Directive({
  selector: '[appAppFormCorrection]'
})
export class AppFormCorrectionDirective implements OnInit, AfterViewInit {

  private valueBeforeChanges: any;
  private _condition: boolean;

  @Input("appAppFormCorrectionContainer")
  container: any;

  constructor(private _ngModel: NgModel) {
  }

  ngOnInit(): void {

  }

  get condition(): boolean {
    return this._condition;
  }

  @Input("appAppFormCorrection")
  set condition(value: boolean) {

    if (value) {
      setTimeout(() => {
        if (!this.container) {
          if (this._ngModel.model) {
            this.valueBeforeChanges = JSON.parse(JSON.stringify(this._ngModel.model));
          }
        }
        if (!this._ngModel.isDisabled) {
          this._ngModel.control.enable()
        }
      });
    } else {
      setTimeout(() => {
        if (!this.container) {
          if (this.valueBeforeChanges) {
            this._ngModel.viewToModelUpdate(this.valueBeforeChanges);
            this._ngModel.valueAccessor.writeValue(this.valueBeforeChanges);
          }
        }
        this._ngModel.control.disable();
      });
    }
    this._condition = value;
  }

  ngAfterViewInit(): void {
  }
}
