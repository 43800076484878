import {Injectable} from '@angular/core';
import {Observable, of, ReplaySubject, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {Applicant, User} from "../generated-model/model";
import {OFFICER_AUTHORITIES, REQUEST_NEW_AUTHORITIES} from "./_app_constant";
import {catchError, finalize, last, map, shareReplay} from "rxjs/operators";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoginAttemptsDialogComponent} from "./shareds/components/dialog/login-attempts-dialog/login-attempts-dialog.component";

export type Auth = { user: User, state: 'LOGIN' | 'CURRENT' };

@Injectable({
  providedIn: 'root'
})
export class AuthenService {

  private _auth$: Observable<Auth>;
  errorChannel = new ReplaySubject<string>(1);
  private _loginFailedMessage: string;
  private _authenStatus: number;

  constructor(private http: HttpClient,
              private router: Router,
              private modalService: NgbModal) {

    this.listener();
  }

  public listener() {

    this._auth$ = this.http.get<User>('api/user/current')
      .pipe(
        shareReplay(1),
        map(user => {
          return {user: user, state: 'CURRENT'};
        }),
        catchError(err => {
          console.warn('exception when loading current user (expected) ', err);
          this.clearAuthen();
          return of(undefined);
        })
      );

    this._auth$
      .subscribe(auth => {
          if (!auth) {
            this.clearAuthen();
          } else {
            this._loginFailedMessage = undefined;
            this._authenStatus = undefined;
          }
        }, error => {
          console.log('error = ', error);
        }
      );
  }

  private forwardErrors(error: any): Observable<never | undefined> {

    if (error.status === 401) {
      this._loginFailedMessage = error.error;
      this._authenStatus = error.status;
      return of(undefined)
    } else if (error.status === 403) {
      this._loginFailedMessage = error.error;
      this._authenStatus = error.status;
      return of(undefined)
    } else if (error.status === 410) {
      this._loginFailedMessage = error.error;
      this._authenStatus = error.status;
      this.modalService.open(LoginAttemptsDialogComponent, {centered: true});
      return of(undefined)
    } else if (error.status !== 400) {
      this.errorChannel.next(error);
      return of(undefined)
    } else {
      return throwError(error);
    }
  }

  login(username: string, password: string): void {

    this._auth$ = this.http.post<User>("login", {username: username, password: password})
      .pipe(
        shareReplay(1),
        map<User, Auth>(user => {
          return {user: user, state: 'LOGIN'};
        }),
        catchError(err => {
          return this.forwardErrors(err);
        })
      );

    this._auth$.subscribe(auth => {
        if (!auth) {
          this.clearAuthen();
        } else {
          this._loginFailedMessage = undefined;
          this._authenStatus = undefined;
          let isOfficer = this.hasAnyAuthority(auth.user, OFFICER_AUTHORITIES);
          let isRequestNewUser = this.hasAnyAuthority(auth.user, REQUEST_NEW_AUTHORITIES);
          this.errorChannel.next(null);
          if (isRequestNewUser) {
            this.router.navigate(['/profile/request/list']);
          } else if (isOfficer) {

            if (auth.user.authorities.some(e => e.authority == 'CHECKPOINT_OFFICER') && auth.user.authorities.some(e => e.authority == 'NS4')) {
              // สิทธิ์เจ้าหน้าที่ด่าน ns4
              this.router.navigate(['/officer/ns4/product-inspection']);
            } else if (auth.user.authorities.some(e => e.authority == 'CHECKPOINT_CHIEF')&& auth.user.authorities.some(e => e.authority == 'NS4')) {
              // หัวหน้าด่านตรวจสัตว์น้ำ ns4
              this.router.navigate(['/officer/ns4/consider-product-release-inspection']);

            } else {
              this.router.navigate(['/officer/portal']);
            }

          } else {
            this.router.navigate(['/portal']);
          }
        }
      }, error => {
        console.log('error = ', error);
      }
    );
  }

  logout(): void {

    this._loginFailedMessage = undefined;
    this._authenStatus = undefined;
    this.http.post("logout", {})
      .pipe(
        finalize(() => {
          localStorage.removeItem("rawpwd");
          this.router.navigate(['/login']);
        })
      )
      .subscribe(e => {
        console.log('logout success');
      }, error => {
        this.router.navigate(['/login']);
      });
  }

  public clearAuthen(): void {

  }

  public hasAnyAuthority(user: User, authorities: readonly string[]): boolean {
    if (user) {
      return user.authorities.some(e => authorities.some(ex => e.authority === ex));
    }
    return false;
  }

  public hasNotAnyAuthority(user: User, authorities: readonly string[]): boolean {
    if (user) {
      return !user.authorities.some(e => authorities.some(ex => e.authority === ex));
    }
    return false;
  }

  get loginFailedMessage(): string {
    return this._loginFailedMessage;
  }

  get authenStatus(): number {
    return this._authenStatus;
  }

  get auth$(): Observable<Auth> {
    return this._auth$.pipe(last());
  }

  getCurrentUserAsApplicant(): Observable<Applicant> {
    return this.http.get('api/user/applicant');
  }

  ngOnDestroy(): void {
  }
}
