import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {first, shareReplay} from "rxjs/operators";
import {Observable} from "rxjs/internal/Observable";
import {BuildProperties} from '../../generated-model/model';

export type BuildDate = { build: { datetime: string } }
export type BuildWithDate = { build: BuildProperties | BuildDate }

@Injectable({
  providedIn: "root"
})
export class AppInfoService {
  appInfo: Observable<BuildWithDate>;

  constructor(private http: HttpClient) {
    this.appInfo = this.http.get<BuildWithDate>(`actuator/info`)
      .pipe(shareReplay(1), first())
  }
}
