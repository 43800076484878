import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {LoadingService} from "./loading.service";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

    constructor(private loadingService: LoadingService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        setTimeout(() => this.loadingService.show())
        return next.handle(req)
            .pipe(tap
            (event => {
                if (event instanceof HttpResponse) {
                    setTimeout(() => this.loadingService.hide())
                }
            }, (err: HttpErrorResponse) => {
                setTimeout(() => this.loadingService.hide())
            }));
    }

}
