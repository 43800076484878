import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AquaService {

  private _currentSystemActive = new BehaviorSubject<string>(undefined);

  constructor(private router: Router) {

    this.router.events
      .subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        } else {
          const url = evt.url;
          if (url.startsWith("/portal")) {
            this._currentSystemActive.next('portal');
          } else if (url.startsWith("/profile")) {
            this._currentSystemActive.next('profile');
          } else if (url.startsWith("/licensing")) {
            this._currentSystemActive.next('licensing');
          } else if (url.startsWith("/voucher")) {
            this._currentSystemActive.next('voucher');
          } else if (url.startsWith("/certificate")) {
            this._currentSystemActive.next('certificate');
          } else if (url.startsWith("/ns4")) {
            this._currentSystemActive.next('ns4');
          } else if (url.startsWith("/gmp-haccp")) {
            this._currentSystemActive.next('gmp-haccp');
          } else if (url.startsWith("/audit")) {
            this._currentSystemActive.next('audit');
          } else if (url.startsWith("/request-inspection")) {
            this._currentSystemActive.next('request-inspection');
          } else if (url.startsWith("/searching")) {
            this._currentSystemActive.next('searching');
          } else if (url.startsWith("/payment")) {
            this._currentSystemActive.next('payment');
          } else if (url.startsWith("/user-management")) {
            this._currentSystemActive.next('user-management');
            /////////////////////officer start//////////////////////////
          } else if (url.startsWith("/officer/portal")) {
            this._currentSystemActive.next('portal');
          } else if (url.startsWith("/officer/licensing")) {
            this._currentSystemActive.next('officer-licensing');
          } else if (url.startsWith("/officer/voucher")) {
            this._currentSystemActive.next('officer-voucher');
          } else if (url.startsWith("/officer/certificate")) {
            this._currentSystemActive.next('officer-certificate');
          } else if (url.startsWith("/officer/ns4")) {
            this._currentSystemActive.next('officer-ns4');
          } else if (url.startsWith("/officer/gmp-haccp")) {
            this._currentSystemActive.next('officer-gmp-haccp');
          } else if (url.startsWith("/officer/audit")) {
            this._currentSystemActive.next('officer-audit');
          } else if (url.startsWith("/officer/request-inspection")) {
            this._currentSystemActive.next('officer-request-inspection');
          } else if (url.startsWith("/officer/searching")) {
            this._currentSystemActive.next('officer-searching');
          } else if (url.startsWith("/officer/payment")) {
            this._currentSystemActive.next('officer-payment');
          } else if (url.startsWith("/officer/user-management")) {
            this._currentSystemActive.next('officer-user-management');
          } else if (url.startsWith("/officer/director-report")) {
            this._currentSystemActive.next('officer-director-report');
          } else {

            this._currentSystemActive.next(undefined);
          }
        }
        window.scrollTo(0, 0);
      });
  }

  get currentSystemActive(): BehaviorSubject<string> {
    return this._currentSystemActive;
  }
}
