import {Pipe, PipeTransform} from "@angular/core";
import {AuthenService} from "../authen.service";
import {User} from "../../generated-model/model";
import {filter} from "rxjs/operators";


@Pipe({
  name: 'authority'
})

export class AuthorityPipe implements PipeTransform {

  user: User;

  constructor(public authenService: AuthenService) {
    this.authenService.auth$
      .pipe(filter(auth => !!auth))
      .subscribe(e => this.user = e.user);
  }

  transform(value: any[], ...args: any[]): any {
    return [...value].filter(e => {
      if (!e['authorities']) {
        return true;
      } else {
        return this.hasAnyRole(e['authorities']);
      }
    }).map(e => {
      if (!!e.children) {
        e.children = [...e.children].filter(child => {
          if (!child['authorities']) {
            return true;
          } else {
            return this.hasAnyRole(child['authorities']);
          }
        })
        return e;
      } else {
        return e;
      }
    });
  }

  public hasAnyRole(rolesAllow: string[]): boolean {
    if (this.user) {
      return this.user.authorities.some(e => rolesAllow.some(ex => e.authority === ex));
    }
    return true;
  }

}

