import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Officer} from "../../../../generated-model/model";
import {NgSelectComponent} from "@ng-select/ng-select";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'gt-officer-autocomplete',
  templateUrl: './gt-officer-autocomplete.component.html',
  styleUrls: ['./gt-officer-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GtOfficerAutocompleteComponent,
      multi: true
    }
  ],
})
export class GtOfficerAutocompleteComponent implements ControlValueAccessor, OnInit {

  @Input()
  id: string;
  @Input()
  name: string;
  @Input()
  validateField: string;
  @Input()
  readonly: boolean = false;
  @Input()
  formState: string;
  @ViewChild("officerSelect", {static: true})
  _value = [];

  officerList: Officer[] = [];
  countrySelect: NgSelectComponent;
  typeaheadLoading = false;

  onChangeCallBack: (_: any) => void = () => {
  };
  onTouchCallBack: () => void = () => {
  };

  constructor(private _http: HttpClient) {
  }


  ngOnInit(): void {

    this._http.get<Officer[]>(`api/officer`)
      .subscribe(officers => this.officerList = officers);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallBack = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchCallBack = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onModelChange(item: string) {
    this.value = item;
  }

  public clear() {

    this.countrySelect.handleClearClick();
    this.countrySelect.blur();
  }

  onSelectOpen() {
  }

  set value(val: any) {
    this._value = val;
    this.onChangeCallBack(val);
    this.onTouchCallBack();
  }

  get value(): any {
    return this._value;
  }

  ngOnDestroy(): void {

  }
}
