import {AfterViewInit, Directive, ElementRef} from "@angular/core";
import Inputmask from "inputmask";


@Directive({
  selector: '[checkList]'
})
export class CheckListDirective implements AfterViewInit {

  constructor(private _elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      let im = new Inputmask("1.2 เครื่องจักรรับวัตถุดิบ : ขนาดแรงม้า 99{0,}  จำนวนสถานที่เท 99{0,}  ติดตั้งเหมาะสม",{greedy:false});
      im.mask(this._elementRef.nativeElement);
      this._elementRef.nativeElement.value =  this._elementRef.nativeElement.value.replaceAll(" 1 ","_");
    });
  }

}
