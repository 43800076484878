import { ControlValueAccessor } from '@angular/forms';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Country } from '../../../../generated-model/model';
import {NgSelectComponent} from "@ng-select/ng-select";
import {MasterService} from "../../service/master.service";

@Component({
  selector: 'app-gt-country',
  templateUrl: './gt-country.component.html',
  styleUrls: ['./gt-country.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GtCountryComponent,
      multi: true
    }
  ],
})
export class GtCountryComponent implements ControlValueAccessor, OnInit {

  @Input()
  validateField: string;
  @Input()
  readonly: boolean = false;

  @Input()
  exclude: string[];

  @Output("clear")
  private clearEvent = new EventEmitter<any>();

  @ViewChild("countrySelect", {static: true})
  countrySelect: NgSelectComponent;

  countrys: Country[] = [];
  _value = [];

  onChangeCallBack: (_: any) => void = () => {
  };
  onTouchCallBack: () => void = () => {
  };
  constructor(private master: MasterService) { }

  ngOnInit(): void {
    this.master.countries().subscribe(countries => {
      if (this.exclude) {
        countries = countries.filter(f => !this.exclude.some(s => s === f.commonName))
      }
      this.countrys = countries;
    });
  }

  registerOnChange(fn: any): void {
    this.onChangeCallBack = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchCallBack = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  set value(val: any) {

    this._value = val;
    this.onChangeCallBack(val);
    this.onTouchCallBack();
  }

  get value(): any {

    return this._value;
  }

  ngOnDestroy(): void {

  }

  public clear() {

    this.countrySelect.handleClearClick();
    this.countrySelect.blur();
  }

  onModelChange(item: string) {

    this.value = item;
  }
}
