import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {untilDestroyed} from "ngx-take-until-destroy";
import {share} from "rxjs/operators";
import {CompanyBranch, CompanyType, Nation, Title, User} from "../../../../../generated-model/model";
import {getCurrentDateStruct} from "../../../../_app_constant";
import {MasterService} from "../../../../shareds/service/master.service";
import {compareWithId} from '../../../../comparable';

@Component({
  selector: 'app-applicant-details-modal',
  templateUrl: './applicant-details-modal.component.html',
  styleUrls: ['./applicant-details-modal.component.css'],
})
export class ApplicantDetailsModalComponent implements OnInit, OnDestroy {

  titles$: Observable<Title[]>
  nations$: Observable<Nation[]>;
  companyTypes$: Observable<CompanyType[]>;
  companyBranches$: Observable<CompanyBranch[]>;
  private _user: User = {
    personType: 'PERSONAL',
    identityCard: {cardType: 'ID_CARD'}
  };
  compareWithId = compareWithId;
  companyCorporateNumber = new Subject();
  currentDateStruct = getCurrentDateStruct();

  constructor(private _masterService: MasterService,
              public activeModal: NgbActiveModal) {

  }

  ngOnInit(): void {

    this.titles$ = this._masterService.titleNames().pipe(untilDestroyed(this), share());
    this.nations$ = this._masterService.nations().pipe(untilDestroyed(this), share());
    this.companyTypes$ = this._masterService.companyTypes().pipe(untilDestroyed(this), share());
    this.companyBranches$ = this._masterService.companyBranches().pipe(untilDestroyed(this), share());
    if (!this._user) {
      throw new Error("user cannot be null.");
    }
  }

  get user(): User {
    return this._user;
  }

  @Input()
  set user(value: User) {
    this._user = value;
  }

  ngOnDestroy(): void {
  }
}
