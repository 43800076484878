import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {IgnoreNullHttpParams} from "../../shareds/Ignore-null-http-params";
import {FileUpload, FileUploadPatcher} from "../../../generated-model/model";
import {ConfirmDialogComponent} from "../../shareds/components/dialog/confirm-dialog/confirm-dialog.component";
import {from} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-file-upload-patcher',
  templateUrl: './file-upload-patcher.component.html',
  styleUrls: ['./file-upload-patcher.component.css']
})
export class FileUploadPatcherComponent implements OnInit {

  fileUpload: FileUpload;
  patcher: FileUploadPatcher = {};
  uuid: string;
  newFile: FileUpload;

  constructor(private http: HttpClient,
              private modalService: NgbModal,
              private toastrService: ToastrService) {
  }

  ngOnInit(): void {
  }

  search() {
    if (!this.uuid) {
      alert("ไม่ได้ระบุ uuid");
    }
    this.http.get('api/file-upload-patcher', {params: IgnoreNullHttpParams.fromObject({uuid: this.uuid}).toHttpParam()})
      .subscribe(value => {
        this.fileUpload = value;
        this.newFile = undefined;
      });
  }

  patch() {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {centered: true});
    modalRef.componentInstance.content = `ต้องการซ่อมไฟล์นี้ใช่หรือไม่? ไฟล์ใหม่จะถูกแทนอย่างถาวรที่และไม่สามารถนำกลับมาได้`;
    from(modalRef.result)
      .subscribe(e => {
        if (!!e) {
          this.patcher = {};
          this.patcher.id = this.fileUpload.id;
          this.patcher.ref = this.fileUpload.ref;
          this.patcher.newFile = this.newFile;
          this.http.post('api/file-upload-patcher', this.patcher)
            .subscribe(value => {
              this.toastrService.success("ซ่อมไฟล์เรียบร้อยแล้ว");
              this.fileUpload = value;
              this.newFile = undefined;
            });
        } else {
          console.log('denied.');
        }
      });
  }
}
