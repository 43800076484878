import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {IgnoreNullHttpParams} from "../Ignore-null-http-params";
import {saveAs} from "file-saver";

@Injectable()
export class DownloadTemplateService {

  constructor(private _http: HttpClient) {
  }

  downloadTemplate(fileName: string) {

    this._http.get('api/download/template/excels', {
      params: new IgnoreNullHttpParams().set('fileName', fileName).toHttpParam(),
      responseType: "blob"
    }).subscribe(data => {
      saveAs(new Blob([data]), fileName)
    });
  }
}
