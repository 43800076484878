import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {RequestShipping} from "../../../../../../generated-model/model";

@Injectable()
export class RequestShippingResolverService {

  constructor(private http: HttpClient, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RequestShipping> {

    let id = Number(route.paramMap.get('requestId'));
    return this.http.get(`api/request-register-form/request-user/${id}/shipping`);
  }
}
