import {INavAttributes, INavBadge, INavLabel, INavLinkProps, INavWrapper} from "@coreui/angular/lib/sidebar/app-sidebar-nav";
import {
  DIRECTOR_REPORT_AUTHORITIES,
  GMP_DIRECTOR,
  GMP_OFFICER,
  OFFICER_AUTHORITIES,
  OFFICER_FINANCE_ACCOUNTING_AUTHORITIES,
  REGISTERED_USER_AUTHORITIES,
  USER_MANAGEMENT_AUTHORITIES
} from "./_app_constant";


export interface INavData2 {
  name?: string;
  url?: string | any[];
  href?: string;
  icon?: string;
  badge?: INavBadge;
  title?: boolean;
  children?: INavData2 [];
  variant?: string;
  attributes?: INavAttributes;
  divider?: boolean;
  class?: string;
  label?: INavLabel;
  wrapper?: INavWrapper;
  linkProps?: INavLinkProps;
  authorities?: readonly string[];
}

export type DOFNav = INavData2;

export const navItems: { [id: string]: DOFNav[] } =
  {
    'portal': [],
    'profile': [
      {
        name: 'ข้อมูลส่วนตัว',
        url: '/profile/info',
        icon: 'fa fa-address-card',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'ข้อมูลส่วนตัว',
        url: '/profile/info',
        icon: 'fa fa-address-card',
        authorities: ['OFFICER']
      },
      {
        name: 'ติดตามสถานะคำขอทะเบียนผู้ใช้งานระบบ',
        url: '/profile/request/list',
        icon: 'fa fa-list-alt',
        authorities: ['REQUEST_AUTHORIZED', 'MAIN_AGENT'],
      },
      {
        name: 'เพิ่มคำขอเปลี่ยนแปลง',
        url: '/profile/correction/form/0/request/0/details',
        icon: 'fa fa-edit',
        authorities: ['MAIN_AGENT'],
      },
      {
        name: 'เพิ่มคำขอต่ออายุทะเบียนผู้ใช้งาน',
        url: '/profile/renewal/form/0/request/0/details',
        icon: 'fa fa-hourglass-half',
        authorities: ['MAIN_AGENT'],
      },
      {
        name: 'ระงับ/คืนสภาพการใช้งาน',
        url: '/profile/users-permission',
        icon: 'fa fa-user-times',
        authorities: ['MAIN_AGENT', 'AGENT'],
      },
      {
        name: 'ต่ออายุตัวแทนออกของ',
        url: '/profile/shipping',
        icon: 'fa fa-truck',
        authorities: ['MAIN_AGENT', 'AGENT'],
      },
      {
        name: 'เปลี่ยนรหัสผ่าน',
        url: '/profile/password',
        icon: 'fa fa-key',
        authorities: REGISTERED_USER_AUTHORITIES.concat(['OFFICER'])
      },
      {
        name: 'เปลี่ยนอีเมล',
        url: '/profile/email',
        icon: 'fa fa-envelope-o',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'ตรวจสอบการลงนามเอกสาร',
        url: '/profile/check-doc-signing',
        icon: 'fa fa-file-pdf-o',
        authorities: REGISTERED_USER_AUTHORITIES.concat(['OFFICER'])
      },
      {
        name: 'มอบสิทธิ์',
        url: '/profile/delegate',
        icon: 'fa fa-user-plus',
        authorities: REGISTERED_USER_AUTHORITIES
      },
    ],
    'licensing': [
      {
        name: 'แบบฟอร์มทั้งหมด',
        url: '/licensing/forms',
        icon: 'fa fa-list',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่บันทึกร่างไว้',
        url: '/licensing/draft',
        icon: 'fa fa-edit',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่ส่งเรื่องแล้ว',
        url: '/licensing/filing',
        icon: 'fa fa-send',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่ต้องชำระค่าธรรมเนียม',
        url: '/licensing/payment',
        icon: 'fa fa-bitcoin',
        authorities: REGISTERED_USER_AUTHORITIES
      },
    ],
    'voucher': [
      // {
      //   name: 'ติดตามสถานะทั้งหมด',
      //   url: '/voucher/follow-status',
      //   icon: 'fa fa-history',
      //   class: 'process-tracking'
      // },
      // {
      //   title: true,
      //   name: 'ขอใบสำคัญการขึ้นทะเบียนอาหาร สัตว์ควบคุมเฉพาะ'
      // },
      {
        name: 'แบบฟอร์มทั้งหมด',
        url: '/voucher/forms',
        icon: 'fa fa-list',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่บันทึกร่างไว้',
        url: '/voucher/draft',
        icon: 'fa fa-edit',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่ส่งเรื่องแล้ว',
        url: '/voucher/filing',
        icon: 'fa fa-send',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่ต้องชำระค่าธรรมเนียม',
        url: '/voucher/payment',
        icon: 'fa fa-bitcoin',
        authorities: REGISTERED_USER_AUTHORITIES
      },
    ],
    'certificate': [
      // {
      //   name: 'ติดตามสถานะทั้งหมด',
      //   url: '/certificate/follow-status',
      //   icon: 'fa fa-history',
      //   class: 'process-tracking'
      // },
      // {
      //   title: true,
      //   name: 'ขอหนังสือรับรองฯ Certificate of Free Sale/Health'
      // },
      {
        name: 'แบบฟอร์มทั้งหมด',
        url: '/certificate/forms',
        icon: 'fa fa-list',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่บันทึกร่างไว้',
        url: '/certificate/draft',
        icon: 'fa fa-edit',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่ส่งเรื่องแล้ว',
        url: '/certificate/filing',
        icon: 'fa fa-send',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่ต้องชำระค่าธรรมเนียม',
        url: '/certificate/payment',
        icon: 'fa fa-bitcoin',
        authorities: REGISTERED_USER_AUTHORITIES
      },
    ],
    'ns4': [
      {
        name: 'แบบฟอร์มทั้งหมด',
        url: '/ns4/forms',
        icon: 'fa fa-list',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่บันทึกร่างไว้',
        url: '/ns4/draft',
        icon: 'fa fa-edit',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่ส่งเรื่องแล้ว',
        url: '/ns4/filing',
        icon: 'fa fa-send',
        authorities: REGISTERED_USER_AUTHORITIES
      }
    ],
    'gmp-haccp': [
      {
        name: 'แบบฟอร์มทั้งหมด',
        url: '/gmp-haccp/forms',
        icon: 'fa fa-list',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่บันทึกร่างไว้',
        url: '/gmp-haccp/draft',
        icon: 'fa fa-edit',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่ส่งเรื่องแล้ว',
        url: '/gmp-haccp/filing',
        icon: 'fa fa-send',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่ต้องชำระค่าธรรมเนียม',
        url: '/gmp-haccp/payment',
        icon: 'fa fa-bitcoin',
        authorities: REGISTERED_USER_AUTHORITIES
      },
    ],
    'audit': [
      {
        name: 'แบบฟอร์มทั้งหมด',
        url: '/audit/forms',
        icon: 'fa fa-list',
        authorities: REGISTERED_USER_AUTHORITIES

      },
      {
        name: 'แบบฟอร์มที่บันทึกร่างไว้',
        url: '/audit/draft',
        icon: 'fa fa-edit',
        authorities: REGISTERED_USER_AUTHORITIES

      },
      {
        name: 'แบบฟอร์มที่ส่งเรื่องแล้ว',
        url: '/audit/filing',
        icon: 'fa fa-send',
        authorities: REGISTERED_USER_AUTHORITIES
      }
    ],
    'request-inspection': [
      // {
      //   name: 'ติดตามสถานะทั้งหมด',
      //   url: '/request-inspection/follow-status',
      //   icon: 'fa fa-history',
      //   class: 'process-tracking'
      // },
      // {
      //   title: true,
      //   name: 'ขอหนังสือแจ้งผลการตรวจสอบ โรงงานผลิตอาหารสัตว์เพื่อการส่งออก'
      // },
      {
        name: 'แบบฟอร์มทั้งหมด',
        url: '/request-inspection/forms',
        icon: 'fa fa-list',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่บันทึกร่างไว้',
        url: '/request-inspection/draft',
        icon: 'fa fa-edit',
        authorities: REGISTERED_USER_AUTHORITIES
      },
      {
        name: 'แบบฟอร์มที่ส่งเรื่องแล้ว',
        url: '/request-inspection/filing',
        icon: 'fa fa-send',
        authorities: REGISTERED_USER_AUTHORITIES
      },
    ],
    'searching': [
      {
        name: 'สืบค้นข้อมูลทะเบียนอาหารสัตว์น้ำควบคุมเฉพาะ'/*'ติดตามสถานะทั้งหมด'*/,
        url: '/searching/public/search',
        icon: 'fa fa-history',
        class: 'process-tracking',
      },
      {
        title: true,
        name: 'สืบค้นข้อมูลทะเบียนอาหารสัตว์น้ำควบคุมเฉพาะ'
      },
      {
        name: 'สืบค้นข้อมูลทะเบียน อาหารสัตว์น้ำควบคุมเฉพาะ',
        url: 'todo',
        icon: 'fa fa-search'
      },
    ],
    ///////officer start/////////
    'officer-licensing': [
      {
        name: 'พิจารณาคำขอ',
        url: '/officer/licensing/consider',
        icon: 'fa fa-address-book-o',
        authorities: [
          'ADMIN',
          'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF',
          'PROVINCE_ACADEMIC_GOV', 'PROVINCE_OFFICER', 'PROVINCE_ACADEMIC', 'PROVINCE_CHIEF'
        ]
      },
      {
        name: 'นัดตรวจสถานที่',
        url: '/officer/licensing/appointment',
        icon: 'fa fa-building',
        authorities: [
          'ADMIN',
          'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF',
          'PROVINCE_ACADEMIC_GOV', 'PROVINCE_OFFICER', 'PROVINCE_ACADEMIC', 'PROVINCE_CHIEF'
        ]
      },
      {
        name: 'ตรวจสอบสถานที่',
        url: '/officer/licensing/site-inspection',
        icon: 'fa fa-industry',
        authorities: [
          'ADMIN',
          'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF',
          'PROVINCE_ACADEMIC_GOV', 'PROVINCE_OFFICER', 'PROVINCE_ACADEMIC', 'PROVINCE_CHIEF'
        ]
      },
      {
        name: 'จัดทำใบอนุญาต',
        url: '/officer/licensing/create-license',
        icon: 'fa fa-address-card',
        authorities: [
          'ADMIN',
          'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF',
          'PROVINCE_ACADEMIC_GOV', 'PROVINCE_OFFICER', 'PROVINCE_ACADEMIC', 'PROVINCE_CHIEF'
        ]
      },
      {
        name: 'เสนอลงนาม',
        url: '/officer/licensing/sign-proposal',
        icon: 'fa fa-pencil',
        authorities: [
          'ADMIN',
          'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF', 'ACTING_CHIEF_QCG',
          'PROVINCE_ACADEMIC_GOV', 'PROVINCE_OFFICER', 'PROVINCE_ACADEMIC', 'PROVINCE_CHIEF'
        ]
      },
      {
        name: 'พิจารณาออกคำสั่ง',
        url: '/officer/licensing/order',
        icon: 'fa fa-gavel',
        authorities: [
          'ADMIN',
          'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO',
          'PROVINCE_ACADEMIC_GOV', 'PROVINCE_OFFICER', 'PROVINCE_ACADEMIC', 'PROVINCE_CHIEF', 'PROVINCE_DIRECTOR'
        ]
      },
      {
        name: 'บันทึกข้อมูลใบอนุญาต (Manual)',
        url: '/officer/licensing/manual',
        icon: 'fa fa-check-square',
        authorities: ['ADMIN', 'QCG_ADMIN']
      },
      {
        name: 'นำเข้า/แก้ไขข้อมูลใบอนุญาต',
        url: '/officer/licensing/import',
        icon: 'fa fa-file-excel-o',
        authorities: ['ADMIN', 'QCG_ADMIN']
      },
      {
        name: 'สถิติ',
        url: '/officer/licensing/statistic',
        icon: 'fa fa-line-chart',
        authorities: [
          'ADMIN',
          'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO',
        ]
      },
      {
        name: 'รายงาน',
        url: '/officer/licensing/report',
        icon: 'fa fa-bar-chart',
        authorities: [
          'ADMIN',
          'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO',
          'PROVINCE_ACADEMIC_GOV', 'PROVINCE_OFFICER', 'PROVINCE_ACADEMIC', 'PROVINCE_CHIEF', 'PROVINCE_DIRECTOR'
        ]
      },
    ],
    'officer-voucher': [
      {
        name: 'พิจารณาคำขอเบื้องต้น',
        url: '/officer/voucher/preliminary-consider',
        icon: 'fa fa-address-book-o',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_CHIEF', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC']
      },
      {
        name: 'พิจารณาคำขอทางวิชาการ',
        url: '/officer/voucher/academic-consider',
        icon: 'fa fa-edit',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_CHIEF', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC']
      },
      {
        name: 'กำหนดการประชุม',
        url: '/officer/voucher/meeting-schedule',
        icon: 'fa fa-calendar-check-o',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_CHIEF', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC']
      },
      {
        name: 'แจ้งผลการประชุม',
        url: '/officer/voucher/meeting-results',
        icon: 'fa fa-bullhorn',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_CHIEF', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC']
      },
      {
        name: 'จัดทำใบสำคัญการขึ้นทะเบียน',
        url: '/officer/voucher/registration-voucher',
        icon: 'fa fa-list-alt',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_CHIEF', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC']
      },
      {
        name: 'เสนอลงนาม',
        url: '/officer/voucher/offer-sign',
        icon: 'fa fa-pencil',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_CHIEF', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC']
      },
      {
        name: 'พิจารณาออกคำสั่ง',
        url: '/officer/voucher/consider-ordering',
        icon: 'fa fa-gavel',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'มอบหมายงาน',
        url: '/officer/voucher/assign-work',
        icon: 'fa fa-address-card-o',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'บันทึกข้อมูลใบสำคัญ (Manual)',
        url: '/officer/voucher/manual',
        icon: 'fa fa-check-square',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC']
      },
      {
        name: 'สถิติ',
        url: '/officer/voucher/statistic',
        icon: 'fa fa-line-chart',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'รายงาน',
        url: '/officer/voucher/report',
        icon: 'fa fa-bar-chart',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
    ],
    'officer-certificate': [
      {
        name: 'พิจารณาคำขอเบื้องต้น',
        url: '/officer/certificate/preliminary-consider',
        icon: 'fa fa-address-book-o',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF']
      },
      {
        name: 'พิจารณาคำขอทางวิชาการ',
        url: '/officer/certificate/academic-consider',
        icon: 'fa fa-address-book-o',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF']
      },
      {
        name: 'จัดทำหนังสือรับรอง',
        url: '/officer/certificate/create-certificate',
        icon: 'fa fa-address-book-o',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF']
      },
      {
        name: 'เสนอลงนาม',
        url: '/officer/certificate/offer-sign',
        icon: 'fa fa-pencil',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF']
      },
      {
        name: 'พิจารณาออกคำสั่ง',
        url: '/officer/certificate/consider-ordering',
        icon: 'fa fa-gavel',
        authorities: ['ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ADMIN', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'มอบหมายงาน',
        url: '/officer/certificate/assign-work',
        icon: 'fa fa-pencil-square-o ',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'บันทึกข้อมูลหนังสือรับรอง(Manual)',
        url: '/officer/certificate/manual',
        icon: 'fa fa-pencil-square-o ',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'สถิติ',
        url: '/officer/certificate/statistic',
        icon: 'fa fa-line-chart',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'รายงาน',
        url: 'officer/certificate/report',
        icon: 'fa fa-bar-chart',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
    ],
    'officer-ns4': [
      {
        name: 'พิจารณาคำขอเบื้องต้น',
        url: '/officer/ns4/basic-consider',
        icon: 'fa fa-address-book-o',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF']
      },
      {
        name: 'พิจารณาคำขอทางวิชาการ',
        url: '/officer/ns4/academic-consider',
        icon: 'fa fa-building',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF']
      },
      {
        name: 'จัดทำเอกสารแจ้งการนำเข้า',
        url: '/officer/ns4/make-certificate',
        icon: 'fa fa-industry',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF']
      },
      {
        name: 'เสนอลงนาม',
        url: '/officer/ns4/offer-sign',
        icon: 'fa fa-pencil-square-o ',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF']
      },
      {
        name: 'พิจารณาออกคำสั่ง',
        url: '/officer/ns4/consider-ordering',
        icon: 'fa fa-address-card',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      }, {
        name: 'บันทึกการตรวจสินค้า',
        url: '/officer/ns4/product-inspection',
        icon: 'fa fa-address-card',
        authorities: ['ADMIN', 'SCG_ADMIN', 'CHECKPOINT_OFFICER', 'CHECKPOINT_CHIEF']
      },
      {
        name: ' พิจารณาการตรวจปล่อยสินค้า',
        url: '/officer/ns4/consider-product-release-inspection',
        icon: 'fa fa-pencil',
        authorities: ['ADMIN', 'SCG_ADMIN', 'CHECKPOINT_CHIEF']
      },
      {
        name: 'บันทึกผลการเก็บตัวอย่าง',
        url: '/officer/ns4/product-release-result',
        icon: 'fa fa-check-circle',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_ACADEMIC', 'SCG_CHIEF']
      },
      {
        name: 'มอบหมายงาน',
        url: '/officer/ns4/assign-work',
        icon: 'fa fa-id-card-o',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'บันทึกข้อมูลเอกสารแจ้งการนำเข้า(Manual)',
        url: '/officer/ns4/manual',
        icon: 'fa fa-check-square',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC']
      },
      {
        name: 'สถิติ',
        url: '/officer/ns4/statistic',
        icon: 'fa fa-line-chart',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'CHECKPOINT_OFFICER'
          , 'CHECKPOINT_CHIEF', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'รายงาน',
        url: '/officer/ns4/report',
        icon: 'fa fa-bar-chart',
        authorities: ['ADMIN', 'SCG_ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },

    ],
    'officer-gmp-haccp': [
      {
        name: 'พิจารณาคำขอ',
        url: '/officer/gmp-haccp/consider',
        icon: 'fa fa-address-book-o',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF']
      },
      {
        name: 'นัดตรวจสถานที่',
        url: '/officer/gmp-haccp/appointment',
        icon: 'fa fa-building',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF']
      },
      {
        name: 'บันทึกรายการตรวจประเมิน',
        url: '/officer/gmp-haccp/site-inspection',
        icon: 'fa fa-industry',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF']
      },
      {
        name: 'บันทึกการประชุม',
        url: '/officer/gmp-haccp/meeting-schedule',
        icon: 'fa fa-pencil-square-o',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC', 'QCG_CHIEF']
      },
      {
        name: 'จัดทำใบรับรอง',
        url: '/officer/gmp-haccp/produce-certificate',
        icon: 'fa fa-address-card',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF']
      },
      {
        name: 'เสนอลงนาม',
        url: '/officer/gmp-haccp/offer-sign',
        icon: 'fa fa-pencil',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF']
      },
      {
        name: 'พิจารณาออกคำสั่ง',
        url: '/officer/gmp-haccp/order',
        icon: 'fa fa-gavel',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'บันทึกตรวจติดตามระบบ',
        url: '/officer/gmp-haccp/follow',
        icon: 'fa fa-calendar-check-o',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF', 'ACTING_CHIEF_QCG']
      },
      {
        name: 'บันทึกข้อมูลใบรับรอง (Manual)',
        url: '/officer/gmp-haccp/manual',
        icon: 'fa fa-check-square',
        authorities: GMP_OFFICER
      },
      {
        name: 'สถิติ',
        url: '/officer/gmp-haccp/statistic',
        icon: 'fa fa-line-chart',
        authorities: GMP_OFFICER.concat(GMP_DIRECTOR)
      },
      {
        name: 'รายงาน',
        url: 'officer/gmp-haccp/report',
        icon: 'fa fa-bar-chart',
        authorities: GMP_OFFICER.concat(GMP_DIRECTOR)
      }
    ],
    'officer-audit': [
      {
        name: 'พิจารณาคำขอเบื้องต้น',
        url: '/officer/audit/preliminary-consider',
        icon: 'fa fa-address-book-o',
        authorities: ['ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF']
      },
      {
        name: 'พิจารณาคำขอทางวิชาการ',
        url: '/officer/audit/academic-consider',
        icon: 'fa fa-building',
        authorities: ['ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF']
      },
      {
        name: 'จัดทำหนังสือแจ้งผล',
        url: '/officer/audit/notification-book',
        icon: 'fa fa-industry',
        authorities: ['ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF']
      },
      {
        name: 'เสนอลงนาม',
        url: '/officer/audit/offer-sign',
        icon: 'fa fa-pencil-square-o ',
        authorities: ['ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF']
      },
      {
        name: 'พิจารณาออกคำสั่ง',
        url: '/officer/audit/consider-ordering',
        icon: 'fa fa-address-card',
        authorities: ['ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'มอบหมายงาน',
        url: '/officer/audit/assign-work',
        icon: 'fa fa-pencil',
        authorities: ['ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'บันทึกข้อมูลหนังสือแจ้งผลสอบถาม (Manual)',
        url: '/officer/audit/manual',
        icon: 'fa fa-gavel',
        authorities: ['ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC']
      },
      {
        name: 'สถิติ',
        url: '/officer/audit/statistic',
        icon: 'fa fa-line-chart',
        authorities: ['ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'รายงาน',
        url: '/officer/audit/report',
        icon: 'fa fa-bar-chart',
        authorities: ['ADMIN', 'SCG_BASIC_CONSIDERATION', 'SCG_ACADEMIC', 'SCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      }
    ],
    'officer-request-inspection': [
      {
        name: 'พิจารณาคำขอ',
        url: '/officer/request-inspection/consider',
        icon: 'fa fa-address-book-o',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF']
      },
      {
        name: 'นัดตรวจสถานที่',
        url: '/officer/request-inspection/appointment',
        icon: 'fa fa-building',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF']
      },
      {
        name: 'ตรวจสอบสถานที่',
        url: '/officer/request-inspection/site-inspection',
        icon: 'fa fa-industry',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF']
      },
      {
        name: 'จัดทำหนังสือ',
        url: '/officer/request-inspection/create-license',
        icon: 'fa fa-address-card',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF']
      },
      {
        name: 'เสนอลงนาม',
        url: '/officer/request-inspection/sign-proposal',
        icon: 'fa fa-pencil',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF', 'ACTING_CHIEF_QCG']
      },
      {
        name: 'พิจารณาลงนามหนังสือ',
        url: '/officer/request-inspection/order',
        icon: 'fa fa-gavel',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'บันทึกข้อมูลหนังสือ (Manual)',
        url: '/officer/request-inspection/manual',
        icon: 'fa fa-check-square',
        authorities: ['ADMIN', 'QCG_ADMIN']
      },
      {
        name: 'สถิติ',
        url: '/officer/request-inspection/statistic',
        icon: 'fa fa-line-chart',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
      {
        name: 'รายงาน',
        url: 'officer/request-inspection/report',
        icon: 'fa fa-bar-chart',
        authorities: ['ADMIN', 'QCG_ADMIN', 'QCG_ACADEMIC_GOV', 'QCG_OFFICER', 'QCG_ACADEMIC', 'QCG_CHIEF', 'DIRECTOR', 'ACTING_DIRECTOR_KPO']
      },
    ],
    'officer-payment': [
      {
        name: 'นำเข้าข้อมูลการชำระเงิน',
        icon: 'fa fa-upload',
        authorities: OFFICER_FINANCE_ACCOUNTING_AUTHORITIES,
        children: [
          {
            name: 'นำเข้าข้อมูล',
            url: '/officer/payment/import-file',
            icon: 'fa icon-blank',
            authorities: OFFICER_FINANCE_ACCOUNTING_AUTHORITIES,
          },
          {
            name: 'ประวัติการนำเข้าข้อมูล',
            url: '/officer/payment/import-file-history',
            icon: 'fa icon-blank',
            authorities: OFFICER_FINANCE_ACCOUNTING_AUTHORITIES,
          }
        ]
      },
      {
        name: 'ตรวจสอบสถานะการชำระเงิน',
        url: '/officer/payment/check-status-e-payment',
        icon: 'fa fa-edit',
        authorities: OFFICER_AUTHORITIES
      },
      {
        name: 'จัดทำใบเสร็จรับเงิน',
        url: '/officer/payment/create-receipt-e-payment',
        icon: 'fa fa-clipboard',
        authorities: OFFICER_AUTHORITIES
      },
      {
        name: 'รายงานสรุปการชำระเงิน',
        url: '/officer/payment/report-summary-e-payment',
        icon: 'fa fa-line-chart',
        authorities: OFFICER_FINANCE_ACCOUNTING_AUTHORITIES
      },
      {
        name: 'รายงานสรุปประจำวัน',
        url: '/officer/payment/report-daily-e-payment',
        icon: 'fa fa-line-chart',
        authorities: OFFICER_FINANCE_ACCOUNTING_AUTHORITIES
      }
    ],
    'officer-user-management': [
      {
        name: 'ติดตามสถานะคำขอทะเบียนผู้ใช้งานระบบ',
        url: '/officer/user-management/request/list',
        icon: 'fa fa-list-alt',
        authorities: USER_MANAGEMENT_AUTHORITIES
      },
      {
        name: 'จัดการข้อมูลผู้ใช้งานทั่วไป',
        icon: 'fa fa-users',
        authorities: USER_MANAGEMENT_AUTHORITIES,
        children: [
          {
            name: 'ข้อมูลผู้ใช้งานระบบทั่วไป',
            url: '/officer/user-management/search-and-edit-user',
            icon: 'fa icon-blank',
            authorities: USER_MANAGEMENT_AUTHORITIES,
          },
        ]
      },
      {
        name: 'จัดการข้อมูลเจ้าหน้าที่',
        icon: 'fa fa-cog',
        authorities: USER_MANAGEMENT_AUTHORITIES,
        children: [
          {
            name: 'ลงทะเบียนเจ้าหน้าที่',
            url: '/officer/user-management/add-user/',
            icon: 'fa icon-blank',
            authorities: USER_MANAGEMENT_AUTHORITIES,
          },
          {
            name: 'ข้อมูลเจ้าหน้าที่ผู้ใช้งานระบบ',
            icon: 'fa icon-blank',
            authorities: USER_MANAGEMENT_AUTHORITIES,
            url: '/officer/user-management/search-and-edit-officer',
          }
        ]
      },
      {
        name: 'สถิติ',
        url: '/officer/user-management/statistic',
        icon: 'fa fa-line-chart',
        authorities: USER_MANAGEMENT_AUTHORITIES
      },
      {
        name: 'รายงาน',
        url: '/officer/user-management/report',
        icon: 'fa fa-bar-chart',
        authorities: USER_MANAGEMENT_AUTHORITIES,
      },
    ],
    'officer-searching': [
      {
        name: 'สืบค้นข้อมูลทะเบียนอาหารสัตว์น้ำ',
        url: '/officer/searching/search',
        icon: 'fa fa-file-text-o',
        authorities: USER_MANAGEMENT_AUTHORITIES,
      },
    ],
    'officer-director-report': [
      {
        name: 'รายงานสำหรับผู้บริหาร',
        url: '/officer/director-report/list',
        icon: 'fa fa-bar-chart',
        authorities: DIRECTOR_REPORT_AUTHORITIES
      }
    ]
  };
