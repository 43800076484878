import {Component} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  templateUrl: '403.component.html'
})
export class P403Component {

  constructor(private _router: Router) {
  }

  redirect() {

    this._router.navigate(['login']);
  }
}
