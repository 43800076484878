import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-register-expired-dialog',
    templateUrl: './register-expired-dialog.component.html',
    styleUrls: ['./register-expired-dialog.component.css']
})
export class RegisterExpiredDialogComponent implements OnInit, OnDestroy {

    constructor(public activeModal: NgbActiveModal,
                public modalService: NgbModal) {
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {
    }
}
