import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class Http5xxInterceptor implements HttpInterceptor {

  constructor(private _toastrService: ToastrService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
      .pipe(tap
      (event => {
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          this._toastrService.error('เกิดข้อผิดพลาด');
        } else if (err.status >= 500 && err.status <= 505) {
          this._toastrService.error('เกิดข้อผิดพลาด');
        } else if (err.status == 0) {
          this._toastrService.error('เกิดข้อผิดพลาด');
        }
      }));
  }

}
