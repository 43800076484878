import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({providedIn: "root"})
export class LoadingService {

  private _loading: boolean = false;
  private _hide: boolean = false;
  private _abortable: boolean = false;
  private _loadingState = new BehaviorSubject(undefined);

  constructor() {
  }

  show(abortable?: boolean): void {

    if (abortable == true) {
      this._abortable = abortable;
    }
    this._loading = !this._hide;
    this._loadingState.next(this._loading);
  }

  hide(): void {

    this._abortable = false;
    this._loading = false;
    this._loadingState.next(this._loading);
  }

  isLoading(): boolean {
    return this._loading;
  }

  get loadingState(): BehaviorSubject<undefined> {
    return this._loadingState;
  }
}
