import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputEditorComponent} from "./input-editor.component";
import {QuillModule} from "ngx-quill";
import {InputEditorDialogComponent} from './input-editor-dialog/input-editor-dialog.component';
import {FormsModule} from "@angular/forms";


@NgModule({
  declarations: [
    InputEditorComponent,
    InputEditorDialogComponent,
  ],
  exports: [
    InputEditorComponent,
    InputEditorDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    QuillModule
  ]
})
export class InputEditorModule { }
