import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbTimeAdapter} from "@ng-bootstrap/ng-bootstrap";
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {AppComponent} from './app.component';
// Import containers
import {DefaultLayoutComponent} from './containers';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {LoginComponent} from './views/login/login.component';
import {AppAsideModule, AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule,} from '@coreui/angular';
// Import routing module
import {AppRoutingModule} from './app.routing';
// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {CustomDatepickerI18n, I18n} from "./shareds/custom-datepicker";
import {CustomNgbDateNativeAdapterToString} from "./shareds/date-native-adaptor";
import {NgbDateParser} from "./shareds/custom-ngb-date-parser";
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from "@angular/common/http";
import {ToastrModule} from "ngx-toastr";
import {Http400Interceptor} from "./shareds/http400-interceptor";
import {Http401Interceptor} from "./shareds/http401-interceptor";
import {Http403Interceptor} from "./shareds/http403-interceptor";
import {Http5xxInterceptor} from "./shareds/http5xx-interceptor";
import {FormsModule} from "@angular/forms";
import {SharedsModule} from "./shareds/shareds.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {AngularFireModule} from "@angular/fire";
import {environment} from "../environments/environment";
import {AngularFireAnalyticsModule} from "@angular/fire/analytics";
import {LoadingInterceptor} from "./shareds/loading-interceptor";
import {Http404Interceptor} from "./shareds/http404-interceptor";
import {CustomNgbTimeAdapter} from "./shareds/custom-time-native-adaptor";
import {RequestSharedModule} from "./views/profile/request/shared/request-shared.module";
import {P403Component} from "./views/error/403.component";
import {HoverPreloadModule} from "ngx-hover-preload";
import {QuillModule} from "ngx-quill";
import {FileUploadPatcherComponent} from "./views/file-upload-patcher/file-upload-patcher.component";

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

@NgModule({
  imports: [
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      progressBar: true,
      preventDuplicates: true,
      timeOut: 2000,
      positionClass: 'toast-top-right'
    }),
    NgSelectModule,
    FormsModule,
    SharedsModule,
    RequestSharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    HoverPreloadModule,
    QuillModule.forRoot()
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P403Component,
    P404Component,
    P500Component,
    LoginComponent,
    FileUploadPatcherComponent
  ],
  providers: [
    I18n,
    {provide: NgbDateAdapter, useClass: CustomNgbDateNativeAdapterToString},
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateParserFormatter, useClass: NgbDateParser},
    {provide: NgbTimeAdapter, useClass: CustomNgbTimeAdapter},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Http400Interceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Http401Interceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Http403Interceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Http404Interceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Http5xxInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {provide: 'localStorage', useValue: window.localStorage},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
