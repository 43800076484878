import {AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {SelectableTitleService} from "./selectable-title.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SelectableTitleCommentComponent} from "./selectable-title-comment/selectable-title-comment.component";
import {CorrectionItem, User} from "../../../../generated-model/model";
import {AuthenService} from "../../../authen.service";
import firebase from "firebase";


type CommentItem = { alertElement: HTMLElement, data: CorrectionItem }

@Directive({
  selector: '[appSelectableTitle]'
})
export class SelectableTitleDirective implements AfterViewInit, OnInit {

  @Input()
  appSelectableTitleEnable = true

  @Input("appSelectableTitle")
  selectTitles: CorrectionItem[];

  alertElementMap = new Map<string, CommentItem>()
  user : User;

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private toastrService: ToastrService,
              private selectableElementService: SelectableTitleService,
              private _modalService: NgbModal,
              private _authen:AuthenService) {


  }

  ngOnInit() {
    if (!this.selectTitles) {
      this.selectTitles = this.selectableElementService.selectTitles;
    }
    this._authen.auth$.subscribe(e=>this.user = e.user);
  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      if (this.appSelectableTitleEnable) {
        let elements = this.el.nativeElement.getElementsByClassName('aqua-form-title');
        this.listenerCorrectChange(elements);
        this.showCorrectMessages(elements);
      } else {
        let elements = this.el.nativeElement.getElementsByClassName('aqua-form-title');
        this.showCorrectMessages(elements);
      }
    }, 2000)
  }

  private showCorrectMessages(elements) {

    for (let e of elements) {
      if (this.selectTitles?.map(e => e?.headerText)?.includes(e?.firstChild?.nodeValue?.trim())) {
        let targetItem = this.selectTitles?.filter(item => item?.headerText == e?.firstChild?.nodeValue.trim())?.[0];
        let alertDiv = this.showAlertDiv(targetItem, e);
        this.alertElementMap.set(targetItem?.headerText, {alertElement: alertDiv, data: targetItem});
      }
    }
  }

  private listenerCorrectChange(elements) {

    for (let e of elements) {
      const iconElem = this.renderer.createElement("i");
      this.renderer.addClass(iconElem, 'fa');
      this.renderer.addClass(iconElem, 'fa-pencil');
      this.renderer.addClass(iconElem, 'mr-1');
      const clickableElem = this.renderer.createElement('span');
      this.renderer.addClass(clickableElem, 'btn');
      this.renderer.addClass(clickableElem, 'btn-outline-warning');
      this.renderer.addClass(clickableElem, 'btn-sm');
      this.renderer.addClass(clickableElem, 'ml-1');
      this.renderer.setStyle(clickableElem, 'cursor', 'pointer');
      this.renderer.appendChild(clickableElem, iconElem);
      this.renderer.listen(clickableElem, 'click', event => {
        const modalRef = this._modalService.open(SelectableTitleCommentComponent, {centered: true, keyboard: false, backdrop: 'static'})
        modalRef.componentInstance.classes = `aqua-form-danger`;
        modalRef.componentInstance.headerText = e.firstChild.nodeValue.trim()
        modalRef.componentInstance.content = `คุณต้องการลบแบบฟอร์มที่เลือก ใช่หรือไม่?`
        modalRef.result.then(titleHeaderText => {
          if (!!titleHeaderText) {
            if (!Array.from(this.alertElementMap.keys()).includes(titleHeaderText.headerText)) {
              let alertDiv = this.createAlertDiv(titleHeaderText, e);
              this.alertElementMap.set(titleHeaderText.headerText, {alertElement: alertDiv, data: titleHeaderText});
            } else {
              const commentItem = this.alertElementMap.get(titleHeaderText.headerText);
              commentItem.data.comment = titleHeaderText.comment || '';
              let elementsByClassName = commentItem.alertElement.querySelector('.data-comment-detail');
              elementsByClassName.innerHTML = commentItem.data.comment;
            }
            this.selectableElementService.selectTitlesEmit.next(Array.from(this.alertElementMap.values()).map(e => e.data));
          }
        });
      });
      const text = this.renderer.createText('สั่งแก้ไข');
      this.renderer.appendChild(clickableElem, text)
      this.renderer.appendChild(e, clickableElem)
    }
  }

  private createAlertDiv(titleHeaderText, e) {

    const divComment = this.renderer.createElement('div')
    this.renderer.addClass(divComment, 'alert')
    this.renderer.addClass(divComment, 'alert-warning')
    this.renderer.addClass(divComment, 'alert-dismissible')
    this.renderer.addClass(divComment, 'my-2')
    const commentHeader = this.renderer.createElement('span')
    commentHeader.innerHTML = 'คำสั่งแก้ไข: ';
    this.renderer.appendChild(divComment, commentHeader)
    const commentDetail = this.renderer.createElement('span')
    this.renderer.addClass(commentDetail, 'data-comment-detail')
    commentDetail.innerHTML = titleHeaderText.comment || '';
    this.renderer.appendChild(divComment, commentDetail)
    this.renderer.appendChild(e, divComment)
    this.renderer.setStyle(divComment, 'font-size', '1rem')
    const closeIcon = this.renderer.createElement('span')
    closeIcon.innerHTML = '&times;'
    //button can't click if fieldset[disabled]. So we use span[role=button] instead
    const closeButton = this.renderer.createElement('span')
    this.renderer.setAttribute(closeButton, 'role', 'button')
    this.renderer.setStyle(closeButton, 'cursor', 'pointer')
    this.renderer.addClass(closeButton, 'close')
    this.renderer.appendChild(closeButton, closeIcon)
    this.renderer.appendChild(divComment, closeButton)
    this.renderer.listen(closeButton, 'click', event => {
      for (let [key, value] of this.alertElementMap) {
        if (value.alertElement === divComment) {
          this.renderer.removeChild(e, divComment);
          this.alertElementMap.delete(key);

          //แก้ bug จาก https://git.geniustree.io/fisheries/e-aquafeed/-/issues/1894
          let deleteIndex = this.selectTitles.indexOf(this.selectTitles.filter(e => e.headerText == key )[0]);
          this.selectTitles.splice(deleteIndex, 1);

          this.selectableElementService.selectTitlesEmit.next(Array.from(this.alertElementMap.values()).map(e => e.data));
          break;
        }
      }
    })
    this.toastrService.info(e.firstChild.nodeValue, 'สั่งแก้ไข', {timeOut: 2500})
    return divComment;
  }

  private showAlertDiv(titleHeaderText, e) {

    const divComment = this.renderer.createElement('div');
    this.renderer.addClass(divComment, 'alert');
    this.renderer.addClass(divComment, 'alert-warning');
    this.renderer.addClass(divComment, 'alert-dismissible');
    this.renderer.addClass(divComment, 'my-2');
    const commentHeader = this.renderer.createElement('span');
    commentHeader.innerHTML = 'คำสั่งแก้ไข: ';
    this.renderer.appendChild(divComment, commentHeader);
    const commentDetail = this.renderer.createElement('span');
    this.renderer.addClass(commentDetail, 'data-comment-detail');
    commentDetail.innerHTML = titleHeaderText.comment || '';
    this.renderer.appendChild(divComment, commentDetail);
    this.renderer.appendChild(e, divComment);
    this.renderer.setStyle(divComment, 'font-size', '1rem');
    if (this.appSelectableTitleEnable) {
      const closeIcon = this.renderer.createElement('span');
      closeIcon.innerHTML = '&times;'
      //button can't click if fieldset[disabled]. So we use span[role=button] instead
      const closeButton = this.renderer.createElement('span')
      this.renderer.setAttribute(closeButton, 'role', 'button')
      this.renderer.setStyle(closeButton, 'cursor', 'pointer')
      this.renderer.addClass(closeButton, 'close')
      this.renderer.appendChild(closeButton, closeIcon)
      this.renderer.appendChild(divComment, closeButton)
      this.renderer.listen(closeButton, 'click', event => {
        for (let [key, value] of this.alertElementMap) {
          if (value.alertElement === divComment) {
            this.renderer.removeChild(e, divComment)
            this.alertElementMap.delete(key)
            if(this._authen.hasAnyAuthority(this.user,['OFFICER'])){
              let deleteIndex = this.selectTitles.indexOf(this.selectTitles.filter(e => e.headerText == key )[0]);
              this.selectTitles.splice(deleteIndex, 1);
              this.selectableElementService.selectTitlesEmit.next(Array.from(this.alertElementMap.values()).map(e => e.data));
            }
            break;
          }
        }
        console.log('correction title:', this.alertElementMap)
      });
    }
    return divComment;
  }
}
