import {Directive, ElementRef, Input, OnDestroy, Renderer2} from '@angular/core';
import {User} from "../../../../generated-model/model";
import {Subscription} from "rxjs";
import {AuthenService} from "../../../authen.service";
import {filter} from "rxjs/operators";

@Directive({
    selector: '[hasAnyAuthority]'
})
export class HasAnyAuthorityDirective implements OnDestroy {

    public user: User;
    private subscription: Subscription;

    constructor(private el: ElementRef,
                private authenService: AuthenService,
                private renderer2: Renderer2) {

    }

    @Input('hasAnyAuthority')
    set hasAnyRole(roles: string[]) {
        this.el.nativeElement.style.display = 'none';
        if (!roles) {
            this.el.nativeElement.style.display = 'inline';
            return;
        }
        this.subscription = this.authenService.auth$
            .pipe(filter(auth => !!auth))
            .subscribe(e => {
                this.user = e.user;
                if (!!roles) {
                    if (this.checkHasAnyRole(roles)) {
                      this.renderer2.removeStyle(this.el.nativeElement, 'display')
                    }
                }
            });
    }

    public checkHasAnyRole(rolesAllow: string[]): boolean {
        if (this.user && this.user.authorities) {
            return this.user.authorities.some(e => rolesAllow.some(ex => e.authority == ex));
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }
}
