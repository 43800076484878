/**
 * Created by neng on 23/10/2559.
 */
import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'thaitime'
})

export class ThaiTimePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (value && args?.twoDigit) {
     try {
       return value.split(':').splice(0, 2).join(':');
     } catch (e) {
       console.error('ไม่สามารถแปลงเวลา ' + value + ' ได้');
       return undefined;
     }
    }
    return value;
  }
}
