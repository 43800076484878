import {AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';

@Component({
  selector: 'gt-diff',
  templateUrl: './gt-diff.component.html',
  styleUrls: ['./gt-diff.component.css'],
})
export class GtDiffComponent implements OnInit, AfterViewInit, AfterViewChecked, AfterContentChecked {

  public class: string = 'none';
  public value: any;

  constructor(private _ef: ElementRef, private _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  setDiff(diff: any) {

    if (Object.keys(diff).length) {
      if (Object.keys(diff['added']).length) {
        this.class = 'added';
        this.value = diff['added'].value;
      }
      if (Object.keys(diff['deleted']).length) {
        this.class = 'deleted';
        this.value = diff['deleted'].value;
      }
      if (Object.keys(diff['updated']).length) {
        this.class = 'updated';
        this.value = diff['updated'].value;
      }
      this._cdr.detectChanges();
    }
  }

  ngAfterViewChecked(): void {

  }

  ngAfterContentChecked(): void {

  }
}
