import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import {detailedDiff} from "deep-object-diff";
import {GtDiffComponent} from "./gt-diff.component";

@Directive({
  selector: '[gtDiff]'
})
export class GtDiffDirective implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  @Input('gtDiff') //rhs
  public gtDiff;

  @Input('gtDiff-lhs')
  public value;

  constructor(private _ef: ElementRef,
              private _cdf: ChangeDetectorRef,
              private _vcr: ViewContainerRef,
              private _componentFactoryResolver: ComponentFactoryResolver,
              private _renderer: Renderer2) {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      if (this.value) {
        this.diff(this.value);
      } else {
        this.diff(this._ef.nativeElement.value);
      }
    })
  }

  ngAfterViewChecked(): void {
  }

  private diff(value: any) {

    if (typeof value == 'string') {
      const factory = this._componentFactoryResolver.resolveComponentFactory(GtDiffComponent);
      let gtDiffComponentComponentRef = this._vcr.createComponent(factory);
      let diff;
      //added
      if (!this.gtDiff && !!value) {
        diff = detailedDiff({}, {value: value});
        this._renderer.setStyle(this._ef.nativeElement, 'background-color', '#d4edda');
        this._renderer.setStyle(this._ef.nativeElement, 'border-color', '#c3e6cb');
        // this._renderer.setStyle(this._ef.nativeElement, 'color', '#c3e6cb');
      }
      //updated
      if (!!this.gtDiff && !!value) {
        diff = detailedDiff({value: value}, {value: this.gtDiff});
        this._renderer.setStyle(this._ef.nativeElement, 'background-color', '#fef3cd');
        this._renderer.setStyle(this._ef.nativeElement, 'border-color', '#fdeeba');
        // this._renderer.setStyle(this._ef.nativeElement, 'color', '#c1aa68');
      }
      //deleted
      if (!!this.gtDiff && !value) {
        // diff = detailedDiff({value: this.gtDiff}, {});
        diff = {
          'added': {},
          'updated': {},
          'deleted': {value: this.gtDiff}
        };
        this._renderer.setStyle(this._ef.nativeElement, 'background-color', '#f6d7da');
        this._renderer.setStyle(this._ef.nativeElement, 'border-color', '#f2c6cb');
        // this._renderer.setStyle(this._ef.nativeElement, 'color', '#c1aa68');
      }
      gtDiffComponentComponentRef.instance.setDiff(diff);
    } else {
      console.log(detailedDiff(value, this.gtDiff));
    }
  }

  ngOnDestroy(): void {

  }
}
