import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {navItems} from '../../_nav';
import {AquaService} from "../../shareds/service/aqua.service";
import {filter, tap} from "rxjs/operators";
import {AuthenService} from "../../authen.service";
import {User} from "../../../generated-model/model";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {
  ICONS,
  OFFICER_AUTHORITIES,
  OFFICER_SYSTEMS,
  REGISTERED_USER_AUTHORITIES,
  USER_SYSTEMS
} from "../../_app_constant";
import {untilDestroyed} from 'ngx-take-until-destroy';
import {AppBreadcrumbService} from "../../services/app-breadcrumb.service";
import {AppTocService} from "../../shareds/components/app-toc/app-toc.service";
import {from} from "rxjs";
import {ConfirmDialogComponent} from "../../shareds/components/dialog/confirm-dialog/confirm-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  public sidebarMinimized = false;
  public navItems = [];
  htmlLogo = '<div class="app-logo"><img class="navbar-brand-full mr-2" src="assets/img/dof_logo.png" width="50" height="50" alt="e-Aquafeed Logo"></div>'
  htmlLogoMini = '<img class="navbar-brand-minimized" src="assets/img/dof_logo.png" width="30" height="30" alt="e-Aquafeed Logo">'
  currentSystem;
  user: User;
  appHeaderResponsiveHeight: number = 0;
  breadcrumbs;
  toggleToc: boolean;
  isViewChecked: boolean = false;

  @ViewChild("appHeader", {static: false, read: ElementRef})
  private appHeader: ElementRef;

  constructor(private _aquaService: AquaService,
              private _http: HttpClient,
              private _activatedRoute: ActivatedRoute,
              private _appBreadcrumbService: AppBreadcrumbService,
              private _router: Router,
              private _appTocService: AppTocService,
              private _modalService: NgbModal,
              public authenService: AuthenService) {

    this.authenService.listener();

  }

  ngAfterViewChecked(): void {

    let e = document.querySelector('app-sidebar-nav-link.logout');
    if (!!e && !this.isViewChecked) {
      e.addEventListener('click', () => {
        const modalRef = this._modalService.open(ConfirmDialogComponent, {centered: true});
        modalRef.componentInstance.content = `คุณต้องการออกจากระบบใช่หรือไม่?`;
        from(modalRef.result)
          .subscribe(e => {
            if (!!e) {
              this.logout();
            } else {
              console.log('denied.');
            }
          });
      });
      this.isViewChecked = true;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.calcAppHeaderHeight());
  }

  ngOnInit(): void {

    this._appTocService.toggler
      .pipe(untilDestroyed(this))
      .subscribe(value => this.toggleToc = value);
    this.breadcrumbs = this._appBreadcrumbService.breadcrumbs;
    this._aquaService
      .currentSystemActive
      .pipe(
        filter(e => !!e),
        tap(x => {
          // console.log('current system active = ', x);
        }),
        untilDestroyed(this)
      )
      .subscribe(system => {
          this.currentSystem = system;
          this.authenService.auth$
            .pipe(filter(auth => !!auth))
            .subscribe(auth => {
              this.user = auth.user;
              let userAuthorities = this.user.authorities.map(value => value.authority);
              this.navItems = [...navItems[system]].filter(navItem => navItem['authorities'].filter(value => userAuthorities.indexOf(value) != -1).length);
            });
        }
      );
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  systemName(key: string): string {

    return Object.assign({}, USER_SYSTEMS, OFFICER_SYSTEMS)[key];
  }

  systemIcon(key: string): string {

    return ICONS[key];
  }

  logout() {
    this.authenService.logout();
  }

  navigateToFormStatus() {
    this._router.navigate([this.currentSystem, 'follow-status']);
  }

  calcAppHeaderHeight() {
    // this.appHeaderResponsiveHeight = this.appHeader.nativeElement.offsetHeight - 55;
  }

  ngOnDestroy(): void {

  }
}
