import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {concat, Observable, of, Subject} from "rxjs";
import {NgSelectComponent} from "@ng-select/ng-select";
import {HttpClient} from "@angular/common/http";
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from "rxjs/operators";
import {untilDestroyed} from "ngx-take-until-destroy";
import {IgnoreNullHttpParams} from "../../Ignore-null-http-params";
import {PackageUnit} from "../../../../generated-model/model";

@Component({
  selector: 'gt-package-unit-autocomplete',
  templateUrl: './gt-package-unit-autocomplete.component.html',
  styleUrls: ['./gt-package-unit-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GtPackageUnitAutocompleteComponent,
      multi: true
    }
  ]
})
export class GtPackageUnitAutocompleteComponent implements ControlValueAccessor, OnInit {

  @Input()
  id: string;
  @Input()
  name: string;
  @Input()
  validateField: string;
  @Input()
  readonly: boolean = false;
  @Input()
  formState: string;
  @ViewChild("packageUnitSelect", {static: true})
  _value = [];
  packageUnitList$: Observable<PackageUnit[]>;
  term$ = new Subject<string>();
  packageUnitSelect: NgSelectComponent;
  typeaheadLoading = false;

  onChangeCallBack: (_: any) => void = () => {
  };
  onTouchCallBack: () => void = () => {
  };

  constructor(private _http: HttpClient) { }

  ngOnInit(): void {
    this.packageUnitList$ = concat(
      of([]), // default items
      this.term$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.typeaheadLoading = true),
        switchMap(term => {
          return this.search(term)
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => this.typeaheadLoading = false),
              untilDestroyed(this)
            );
        })
      )
    );
  }

  registerOnChange(fn: any): void {
    this.onChangeCallBack = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchCallBack = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onModelChange(item: string) {
    this.value = item;
  }

  public clear() {
    this.packageUnitSelect.handleClearClick();
    this.packageUnitSelect.blur();
  }

  onSelectOpen() {
    this.term$.next('');
  }

  set value(val: any) {
    this._value = val;
    this.onChangeCallBack(val);
    this.onTouchCallBack();
  }

  get value(): any {
    return this._value;
  }

  ngOnDestroy(): void {

  }

  search(term: string): Observable<PackageUnit[]> {
    return this._http.get<PackageUnit[]>(`api/package-unit/th/search`,
      {params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()}
    );
  }

}
