import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {untilDestroyed} from "ngx-take-until-destroy";
import {Nation, RequestAttorney, RequestUser, Title} from "../../../../../../../../generated-model/model";
import {LoadingService} from "../../../../../../../shareds/loading.service";
import {MasterService} from "../../../../../../../shareds/service/master.service";
import {share} from "rxjs/operators";
import {compareWithId} from '../../../../../../../comparable';
import {getCurrentDateStruct} from "../../../../../../../_app_constant";

@Component({
  selector: 'app-add-request-attorney-modal',
  templateUrl: './add-request-attorney-modal.component.html',
  styleUrls: ['./add-request-attorney-modal.component.css']
})
export class AddRequestAttorneyModalComponent implements OnInit, OnDestroy {

  titles$: Observable<Title[]>;
  nations$: Observable<Nation[]>;
  compareWithId = compareWithId;
  currentDateStruct = getCurrentDateStruct();

  @Input()
  state: 'ADD' | 'DETAILS' = 'ADD';

  @Input()
  attorney: Partial<RequestAttorney> = {
    identityCard: {cardType: "ID_CARD"}
  };
  onSave: EventEmitter<RequestAttorney> = new EventEmitter<RequestAttorney>();

  @Input()
  requestUser: RequestUser;

  constructor(private _masterService: MasterService,
              public loadingService: LoadingService,
              public activeModal: NgbActiveModal) {

  }

  ngOnInit(): void {

    this.titles$ = this._masterService.titleNames().pipe(untilDestroyed(this), share());
    this.nations$ = this._masterService.nations().pipe(untilDestroyed(this), share());
    if (!this.requestUser) {
      throw new Error("requestUser cannot be null.");
    }
  }

  save() {

    //todo
  }

  nationChanges(nationId: any) {

    //nation_id (1) == ไทย
    if (!!nationId && !(nationId == 1)) {
      this.attorney.identityCard.cardType = "PASSPORT";
    }
  }

  identityCardTypeChange() {

    this.attorney.identityCard.noneExpire = undefined;
    this.attorney.identityCard.issueDate = undefined;
    this.attorney.identityCard.cardAddress = undefined;
    this.attorney.identityCard.cardMoo = undefined;
    this.attorney.identityCard.cardSoi = undefined;
    this.attorney.identityCard.cardStreet = undefined;
    this.attorney.identityCard.cardTambon = undefined;
  }

  identityCardExpireChange() {

    if (this.attorney.identityCard.noneExpire === true) {
      this.attorney.identityCard.expireDate = undefined;
    }
  }

  identityCard_cardTambonChange() {

    if (this.attorney.identityCard.cardTambon) {
      this.attorney.identityCard.cardPostalCode = this.attorney.identityCard.cardTambon.postalCode;
    } else {
      this.attorney.identityCard.cardPostalCode = undefined;
    }
  }

  contactAddress_contactTambonChange() {

    if (this.attorney.contactAddress.contactTambon) {
      this.attorney.contactAddress.contactPostalCode = this.attorney.contactAddress.contactTambon.postalCode;
    } else {
      this.attorney.contactAddress.contactPostalCode = undefined;
    }
  }

  copyCardAddressToContactAddress() {

    if (!this.attorney.contactAddress) {
      this.attorney.contactAddress = {};
    }
    this.attorney.contactAddress.contactAddress = this.attorney.identityCard?.cardAddress;
    this.attorney.contactAddress.contactMoo = this.attorney.identityCard?.cardMoo;
    this.attorney.contactAddress.contactSoi = this.attorney.identityCard?.cardSoi;
    this.attorney.contactAddress.contactStreet = this.attorney.identityCard?.cardStreet;
    this.attorney.contactAddress.contactTambonId = this.attorney.identityCard?.cardTambon?.id;
    this.attorney.contactAddress.contactTambon = this.attorney.identityCard?.cardTambon;
    this.attorney.contactAddress.contactPostalCode = this.attorney.identityCard?.cardPostalCode;
  }

  ngOnDestroy(): void {
  }
}
