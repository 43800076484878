import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {RequestAgent, RequestAgentDto} from "../../../../../../../../../generated-model/model";

@Injectable()
export class RequestAgentService {

  constructor(private _http: HttpClient) {

  }

  request(requestAgentDto: RequestAgentDto): Observable<RequestAgent> {

    return this._http.post<RequestAgent>(`api/request-register-form/request-user/${requestAgentDto.requestUser.id}/manage/users/add/agent`, requestAgentDto);
  }
}
