import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ConcreteFeedType} from "../../../../generated-model/model";
import {MasterService} from "../../service/master.service";
import {NgSelectComponent} from "@ng-select/ng-select";

@Component({
  selector: 'app-gt-type-howto',
  templateUrl: './gt-type-howto.component.html',
  styleUrls: ['./gt-type-howto.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GtTypeHowtoComponent,
      multi: true
    }
  ]
})
export class GtTypeHowtoComponent implements ControlValueAccessor, OnInit {

  @ViewChild("typeHowToSelect", {static: true})
  typeHowToSelect: NgSelectComponent;

  @Input()
  readonly: boolean = false;

  concreteFeedTypes: ConcreteFeedType[] = [];
  _value = [];

  onChangeCallBack: (_: any) => void = () => {
  };
  onTouchCallBack: () => void = () => {
  };

  constructor(private master: MasterService) {
  }

  ngOnInit(): void {
    this.master.concreteFeedTypes().subscribe(e => this.concreteFeedTypes = e)
  }

  registerOnChange(fn: any): void {
    this.onChangeCallBack = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchCallBack = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  set value(val: any) {

    this._value = val;
    this.onChangeCallBack(val);
    this.onTouchCallBack();
  }

  get value(): any {

    return this._value;
  }

  ngOnDestroy(): void {

  }

  onModelChange(item: string) {

    this.value = item;
  }

  public clear() {

    this.typeHowToSelect.handleClearClick();
    this.typeHowToSelect.blur();
  }

}
