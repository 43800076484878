import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {concat, Observable, of, Subject} from "rxjs";
import {DofOrg} from "../../../../generated-model/model";
import {NgSelectComponent} from "@ng-select/ng-select";
import {HttpClient} from "@angular/common/http";
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from "rxjs/operators";
import {untilDestroyed} from "ngx-take-until-destroy";
import {IgnoreNullHttpParams} from "../../Ignore-null-http-params";

@Component({
  selector: 'gt-dof-org-autocomplete',
  templateUrl: './gt-dof-org-autocomplete.component.html',
  styleUrls: ['./gt-dof-org-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GtDofOrgAutocompleteComponent,
      multi: true
    }
  ],
})
export class GtDofOrgAutocompleteComponent implements ControlValueAccessor, OnInit {
  @Input()
  id: string;
  @Input()
  name: string;
  @Input()
  validateField: string;
  @Input()
  readonly: boolean = false;
  @Input()
  formState: string;
  @ViewChild("dofOrgSelect", {static: true})
  _value = [];
  dofOrgList$: Observable<DofOrg[]>;
  term$ = new Subject<string>();
  countrySelect: NgSelectComponent;
  typeaheadLoading = false;

  onChangeCallBack: (_: any) => void = () => {
  };
  onTouchCallBack: () => void = () => {
  };

  constructor(private _http: HttpClient) {
  }


  ngOnInit(): void {
    this.dofOrgList$ = concat(
      of([]), // default items
      this.term$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.typeaheadLoading = true),
        switchMap(term => {
          return this.search(term)
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => this.typeaheadLoading = false),
              untilDestroyed(this)
            );
        })
      )
    );
  }

  registerOnChange(fn: any): void {
    this.onChangeCallBack = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchCallBack = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onModelChange(item: string) {
    this.value = item;
  }

  public clear() {

    this.countrySelect.handleClearClick();
    this.countrySelect.blur();
  }

  onSelectOpen() {

    this.term$.next('');
  }

  set value(val: any) {
    this._value = val;
    this.onChangeCallBack(val);
    this.onTouchCallBack();
  }

  get value(): any {
    return this._value;
  }

  ngOnDestroy(): void {

  }

  search(term: string): Observable<DofOrg[]> {
    return this._http.get<DofOrg[]>(`public/api/doforg/search`,
      {params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()}
    );
  }
}
