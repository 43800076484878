import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {RequestEntrepreneur, RequestUser} from "../../../../../../../../../generated-model/model";

@Injectable()
export class RequestEntrepreneurService {

  constructor(private _http: HttpClient) {

  }

  save(requestUser: RequestUser, requestEntrepreneur: RequestEntrepreneur): Observable<RequestEntrepreneur> {

    return this._http.post<RequestEntrepreneur>(`api/request-register-form/request-user/${requestUser.id}/manage/users/add/entrepreneur`, requestEntrepreneur);
  }

  update(requestUser: RequestUser, requestEntrepreneur: RequestEntrepreneur): Observable<RequestEntrepreneur> {

    return this._http.post<RequestEntrepreneur>(`api/request-register-form/request-user/${requestUser.id}/manage/users/update/entrepreneur`, requestEntrepreneur);
  }
}
