import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

export const ISODATE_FORMAT = new RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}');
export const ACCEPT_DATE_FORMAT = new RegExp('([0-9]){2}\\/([0-9]){2}\\/([0-9]){4}');
export const INPUT_MASK_DATE_FORMAT = new RegExp('([0-9]|[\\W_]){2}\\/([0-9]|[\\W_]){2}\\/([0-9]|[\\W_]){4}');

@Component({
  selector: 'gt-ng-datepicker',
  templateUrl: './gt-ng-datepicker.component.html',
  styleUrls: ['./gt-ng-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GtNgDatepickerComponent,
      multi: true
    }
  ],
})
export class GtNgDatepickerComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  private _value: string = undefined;
  correctFormat: boolean = true;

  hasError = false;
  @Input()
  hasTime: boolean = false;
  @Input()
  id: string;
  @Input()
  name: string;
  @Input()
  minDateConfig: NgbDateStruct;
  @Input()
  maxDateConfig: NgbDateStruct;
  @Input()
  readonly: boolean = false;
  @Input()
  disabled: boolean = false;
  @Input()
  markDisabled = false

  // TO DO FIX CHANGE EVT DOESN"T FIRE
  // change event doesnt fire, so use this for quick fix
  @Output()
  valueOnchange = new EventEmitter();
  @ViewChild("datepicker", {static: true})
  datepicker: ElementRef;

  onChange: (_: any) => void = () => {
  };
  onTouched: () => void = () => {
  };

  constructor(private cdr: ChangeDetectorRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  valueChange(value: any) {
    this.onChange(value);
    this.onTouched();
  }

  writeValue(value: any) {
    this._value = value;
  }

  error() {
    console.log('this.datepicker = ', this.datepicker)
    this.hasError = true;
    this.renderer.addClass(this.datepicker.nativeElement, 'ng-invalid');
  }

  clearError() {
    this.hasError = false;
    this.renderer.removeClass(this.datepicker.nativeElement, 'ng-invalid');
  }

  widthAuto() {
    // this.elementRef.nativeElement.querySelector(".dropdown-menu").style.width = "auto";
  }

  set value(val: any) {
    this._value = val;
    if (!!this._value && ACCEPT_DATE_FORMAT.test(this._value)) {
      let value: string[] = this._value.split('/');
      this._value = `${Number(value[2])}-${value[1]}-${value[0]}`;
      this.writeValue(this._value);
      this.valueChange(this._value);
    } else if (!!this._value && ISODATE_FORMAT.test(this._value)) {
      let T = this._value.indexOf('T');
      if (T != -1) {
        this.writeValue(this._value);
        this.valueChange(this._value);
      } else {
        this._value = this._value.split('T')[0];
        this.writeValue(this._value);
        this.valueChange(this._value);
      }
    } else {
      this._value = undefined;
      this.writeValue(this._value);
      this.valueChange(this._value);
    }
    this.correctFormat = !!this._value;
  }

  get value(): any {
    return this._value;
  }

  private errorWithTimeout() {
    this.error()
    setTimeout(() => this.clearError(), 2000)
  }

}
