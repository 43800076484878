import {AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {FileItem, FileUploader, ParsedResponseHeaders} from "ng2-file-upload";
import {FileUpload} from "../../../../generated-model/model";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {BankTransferFileUpload, UPLOAD_URL} from "./bank-transfer-file-upload.service";
import {IgnoreNullHttpParams} from "../../Ignore-null-http-params";
import {finalize} from "rxjs/operators";
import {HttpValidationService} from "../../../services/http-validation.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../dialog/alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-bank-transfer-file-upload',
  templateUrl: './bank-transfer-file-upload.component.html',
  styleUrls: ['./bank-transfer-file-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BankTransferFileUploadComponent,
      multi: true
    },
    BankTransferFileUpload
  ],
})
export class BankTransferFileUploadComponent implements ControlValueAccessor, OnInit, AfterViewInit, AfterViewChecked {

  uploader: FileUploader = new FileUploader({url: UPLOAD_URL});
  uploading: boolean = false;
  hasError = false;

  @Input()
  buttonAttachName = 'แนบไฟล์'

  @Input()
  id: string = this.randomIdentifier();

  @Input()
  name: string = this.randomIdentifier();

  @Input()
  validateFields: string[] = [];

  @Input()
  readonly: boolean = false;

  @Input()
  fileType: string;

  @Input()
  requireFileType?: boolean = true;

  @Input()
  size: 'md' | 'sm' = 'md';

  @Output()
  uploaded: EventEmitter<FileUpload> = new EventEmitter<FileUpload>();

  @Output()
  removed: EventEmitter<FileUpload> = new EventEmitter<FileUpload>();

  preview: boolean = false;

  onChangeCallBack: (_: any) => void = () => {
  };
  onTouchCallBack: () => void = () => {
  };
  _value: any | FileUpload = undefined;

  constructor(private _renderer: Renderer2,
              private _service: BankTransferFileUpload,
              private _validatorService: HttpValidationService,
              private _modalService: NgbModal,
              private _ef: ElementRef) {

  }

  ngOnInit(): void {

    if (!this.validateFields && this.fileType) {
      this.validateFields = [`files[${this.fileType}]`, `files[${this.fileType}].contentType`, `files[${this.fileType}].size`];
    }
  }

  ngAfterViewInit(): void {

  }

  ngAfterViewChecked(): void {

  }

  set value(val: any) {

    this._value = val;
    this.preview = this._value?.upload || false;
    this.onChangeCallBack(val);
    this.onTouchCallBack();
  }

  get value(): any {

    return this._value;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallBack = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchCallBack = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  isUploading() {

    return !!this.uploading;
  }

  upload(event) {

    event.target.value = '';

    this.uploader.onProgressAll = (progress: any) => {
      this.uploading = true;
    };

    this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.uploading = false;
      this.preview = true;
      let result = {...this._value, ...JSON.parse(response || "{}")};
      result.fileType = this.fileType;
      result.event = 'NEW';
      this.value = result;
      this.uploaded.emit(result);
    };

    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      if (status == 400) {
        let errorResponse = JSON.parse(response);
        let modalRef = this._modalService.open(AlertDialogComponent);
        modalRef.componentInstance.content = errorResponse?.errors[0]?.defaultMessage;
      }
      this.uploading = false;
    };

    this.uploader.onCompleteAll = () => {
      this.uploader.clearQueue();
      this.uploading = false;
    };

    this.uploader.uploadAll();

  }

  clearError() {
    this.hasError = false
  }

  reset() {
    this.uploader.clearQueue();
  }

  randomIdentifier(): string {

    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  }

  deleteFile() {

    if (this.value.state == 'TEMP') {
      this.uploading = true;
      this._service.delete(this.value)
        .pipe(finalize(() => this.uploading = false))
        .subscribe(() => {
          this.removed.emit(this.value)
          if (!!this.fileType) {
            this.value = {fileType: this.fileType};
          } else {
            this.value = undefined;
          }
        });
    } else {
      this.removed.emit(this.value)
      if (!!this.fileType) {
        this.value = {fileType: this.fileType};
      } else {
        this.value = undefined;
      }
    }
  }

  showPreview() {

    if (!(this.value.state == 'TEMP')) {
      window.open(`${UPLOAD_URL}/${this.value.ref}/preview`, '_blank');
    } else {
      let params = new IgnoreNullHttpParams()
        .set("filePath", this.value.filePath)
        .set("fileName", this.value.fileName)
        .set("contentType", this.value.contentType);
      window.open(`${UPLOAD_URL}/preview?${params.toHttpParam()}`, '_blank');
    }
  }

  edit() {
    this.value.upload = false;
  }
}
