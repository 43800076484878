export class StringUtils {

  public static paddingZeroLeft(numb: string | number, size: number) {

    if (numb.toString().length < size) {
      while (numb.toString().length < size) {
        numb = '0' + numb;
      }
      return numb;
    }

    return numb;
  }

  public static paddingZeroRight(numb: string | number, size: number) {

    if (numb.toString().length < size) {
      while (numb.toString().length < size) {
        numb = numb + '0';
      }
      return numb;
    }
    return numb;
  }

  public static titleCase(str: string): string {

    if(str){
      return str[0].toUpperCase() + str.slice(1).toLowerCase();
    }
    return str
  }
}
