import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";

@Injectable()
export class AppService {

  constructor(private _http: HttpClient) {

  }

  generateDraftId(id?: string): Observable<string> {

    if (!id || id == '1') {
      return this._http.get('api/uuid/generate', {responseType: "text"});
    } else {
      return of(id);
    }
  }

  getFormTypeValue(formTypeName): Observable<string> {

    return this._http.get(`api/form-type/${formTypeName}/value`, {responseType:"text"})
  }

}
