import {Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DraftLoaderService} from "./draft-loader.service";

@Component({
  selector: 'app-draft-loader',
  templateUrl: './draft-loader.component.html',
  styleUrls: ['./draft-loader.component.css']
})
export class DraftLoaderComponent implements OnInit {

  @ViewChild("loader")
  private _loaderElemRef: ElementRef;

  constructor(public loaderService: DraftLoaderService,
              private _renderer: Renderer2) {
  }

  ngOnInit(): void {
  }

  @HostListener('window:scroll')
  public windowOnScroll() {

    let offsetTop = this._loaderElemRef.nativeElement.offsetTop;
    offsetTop = offsetTop - 75;
    if(window.pageYOffset > offsetTop) {
      this._renderer.addClass(this._loaderElemRef.nativeElement, 'sticky');
    } else {
      this._renderer.removeClass(this._loaderElemRef.nativeElement, 'sticky');
    }
  }

}
