import {Injectable} from '@angular/core';

@Injectable({providedIn: "root"})
export class DraftLoaderService {

  private _loading: boolean;

  constructor() {
  }

  show() {

    this._loading = true;
  }

  hide() {

    this._loading = false;
  }

  isLoading(): boolean {

    return !!this._loading;
  }
}
