import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {
    RequestAgent,
    RequestAttorney,
    RequestEmployee,
    RequestEntrepreneur,
    RequestUser
} from "../../../../../../../../generated-model/model";
import {Observable} from "rxjs";

@Injectable()
export class ManageRequestUsersService {

    constructor(private _http: HttpClient) {

    }

    manageUsers(requestUser: RequestUser): Observable<any> {

        return this._http.get(`api/request-register-form/request-user/${requestUser.id}/manage/users`);
    }

    deleteEntrepreneur(entrepreneur: RequestEntrepreneur): Observable<void> {

        return this._http.delete<void>(`api/request-entrepreneur/${entrepreneur.id}/delete`);
    }

    deleteEmployee(employee: RequestEmployee): Observable<void> {

        return this._http.delete<void>(`api/request-employee/${employee.id}/delete`);
    }

    deletePendingAgent(agent: RequestAgent): Observable<void> {

        return this._http.delete<void>(`api/request-agent/${agent.uuid}/delete`);
    }

    deleteAgent(requestUser: RequestUser): Observable<void> {

        return this._http.delete<void>(`api/request-agent/${requestUser.id}/agent/delete`);
    }

    deleteAttorney(requestAttorney: RequestAttorney): Observable<void> {

        return this._http.delete<void>(`api/request-attorney/${requestAttorney.id}/delete`);
    }
}
